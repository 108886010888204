import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpClientModule, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { ConfigurationService } from '../config/configuration.service';
import { CommonService } from '../common/common.service'
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccessibilityService {
  private actionUrl: string;
  // private aiorUrl: string;
  constructor(
    private http: HttpClient, 
	  private _configuration: ConfigurationService, 
    private router: Router,
    private common: CommonService
  ) { 
    this.actionUrl = _configuration.ServerWithApiUrl;
	  // this.aiorUrl = _configuration.AIORAnchorUrl;
  }


  /**
	 * Return all host group list
	 *
	 * @param -- None
	 *
	 * @return Observable
	 */

	getAllHostGroupList(): Observable<any> {
    let authHeader = this.common.getHttpOptionWithLogin();
    return this.http.get(this.actionUrl + 'user/user-host-group/', authHeader);
    }
    
  /**
	 * Save host group
	 *
	 * @param -- Form data in JSON format
	 *
	 * @return Observable
	 */

	addHostGroup(reqData): Observable<any> {
    let authHeader = this.common.getHttpOptionWithLogin();
    return this.http.post(this.actionUrl + 'user/user-host-group/', reqData, authHeader);
    }
    
  /**
	 * Update host group
	 *
	 * @param -- Form data in JSON format
	 *
	 * @return Observable
	 */

	updateHostGroup(reqData): Observable<any> {
    let authHeader = this.common.getHttpOptionWithLogin();
    return this.http.put(this.actionUrl + 'user/user-host-group/', reqData, authHeader);
    }
    
  /**
	 *  Get host group details by id
	 *
	 * @param -- Form data in JSON format
	 *
	 * @return Observable
	 */

	getHostGroupDetails(reqData): Observable<any> {
    let authHeader = this.common.getHttpOptionWithLogin();
    return this.http.post(this.actionUrl + 'user/user-host-group-details/', reqData, authHeader);
	  }
	  


	/** Zone Service */

	/**
	 * Return all zone list
	 *
	 * @param -- None
	 *
	 * @return Observable
	 */

	getAllZoneList(): Observable<any> {
		let authHeader = this.common.getHttpOptionWithLogin();
		return this.http.get(this.actionUrl + 'user/user-zone/', authHeader);
		}
		
	  /**
		 * Save zone
		 *
		 * @param -- Form data in JSON format
		 *
		 * @return Observable
		 */
	
		addZone(reqData): Observable<any> {
		let authHeader = this.common.getHttpOptionWithLogin();
		return this.http.post(this.actionUrl + 'user/user-zone/', reqData, authHeader);
		}
		
	  /**
		 * Update zone
		 *
		 * @param -- Form data in JSON format
		 *
		 * @return Observable
		 */
	
		updateZone(reqData): Observable<any> {
		let authHeader = this.common.getHttpOptionWithLogin();
		return this.http.put(this.actionUrl + 'user/user-zone/', reqData, authHeader);
		}
		
	  /**
		 *  Get zone details by id
		 *
		 * @param -- Form data in JSON format
		 *
		 * @return Observable
		 */
	
		getZoneDetails(reqData): Observable<any> {
		let authHeader = this.common.getHttpOptionWithLogin();
		return this.http.post(this.actionUrl + 'user/user-zone-details/', reqData, authHeader);
		  }
}
