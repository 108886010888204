import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { ConfigurationService } from '../services/config/configuration.service';
import { CommandHistoryRouting } from './command-history-routing.module';
import { HttpModule } from '@angular/http';
import { HttpClient, HttpHeaders, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { AgmCoreModule } from '@agm/core';
import { ExecutedCommendHistoryComponent } from './executed-commend-history.component';
import { AnalyticsComponent } from '../anchor/analytics/analytics.component';
import { TraceroutevisualiserComponent } from '../anchor/traceroutevisualiser/traceroutevisualiser.component';

@NgModule({
  declarations: [ExecutedCommendHistoryComponent, AnalyticsComponent, TraceroutevisualiserComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    HttpModule,
    HttpClientModule,
    CommandHistoryRouting,
    AgmCoreModule.forRoot({
			apiKey: 'AIzaSyAXxByh8R5Ouwe7ouvoqcgdlMXSytHdX6I',
			libraries: ['places', 'drawing', 'geometry']
		  })
  ],
  entryComponents: [AnalyticsComponent, TraceroutevisualiserComponent],
	providers: [
		ConfigurationService,
		// DatePipe,
		CookieService,
	]
})
export class CommandHistoryModule { }
