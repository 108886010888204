import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { ConfigurationService } from '../services/config/configuration.service';
import { PointsRouting } from './points-routing.module';
import { HttpModule } from '@angular/http';
import { HttpClient, HttpHeaders, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { SubscriptionDetailsComponent } from './subscription-details.component'
import { SubscriptionPlansComponent } from './subscription-plans.component';

@NgModule({
  declarations: [SubscriptionDetailsComponent, SubscriptionPlansComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    HttpModule,
    HttpClientModule,
    PointsRouting
  ],
  entryComponents: [],
	providers: [
		ConfigurationService,
		// DatePipe,
		CookieService,
	]
})
export class PointsModule { }
