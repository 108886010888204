import { NgModule } from '@angular/core';
import { ModuleWithProviders} from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SubscriptionDetailsComponent } from './subscription-details.component';
import { SubscriptionPlansComponent } from './subscription-plans.component';

const POINTS_ROUTER: Routes = [
  {
    path: '', 
    component: SubscriptionDetailsComponent,
    children: [
      { path:'subscription-details', component: SubscriptionDetailsComponent },
    ]
  },
];

export const PointsRouting: ModuleWithProviders = RouterModule.forChild(POINTS_ROUTER);