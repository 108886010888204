import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpClientModule, HttpRequest } from '@angular/common/http';
import { ConfigurationService } from '../config/configuration.service';
import { CommonService } from '../common/common.service'

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  private actionUrl: string;
  constructor(
    private http: HttpClient, 
    private _configuration: ConfigurationService,
    private common: CommonService
  ) { 
    this.actionUrl = _configuration.ServerWithApiUrl;
  }

   /**
	 * All Subscription list
	 *
	 * @param - None
	 *
	 * @return Observable
	 */
	getAllSubscriptionList() {
		let authHeader = this.common.getHttpOptionWithLogin();
		return this.http.get(this.actionUrl + "subscription/plans/", authHeader);
  }

  /**
	 * Subscription Request
	 *
	 * @param - subscription id in JSON format 
	 *
	 * @return Observable
	 */
	subscriptionRequest(reqData) {
		let authHeader = this.common.getHttpOptionWithLogin();
		return this.http.post(this.actionUrl + "subscription/plans/", reqData, authHeader);
  }

  	/**
	 * user Subscription Details
	 *
	 * @param - 
	 *
	 * @return Observable
	 */
	userSubscriptionList() {
		let authHeader = this.common.getHttpOptionWithLogin();
		return this.http.get(this.actionUrl + "subscription/user-subscription/", authHeader);
	  }
	  
	/**
	 * user Subscription Details
	 *
	 * @param - 
	 *
	 * @return Observable
	 */
	userUtilizationList() {
		let authHeader = this.common.getHttpOptionWithLogin();
		return this.http.get(this.actionUrl + "subscription/point-utilization/", authHeader);
	  }
	  
	/**
	 * All User Points List
	 *
	 * @param - 
	 *
	 * @return Observable
	 */
	userPointList() {
		let authHeader = this.common.getadminHttpOptionWithLogin();
		return this.http.get(this.actionUrl + "subscription/user-point/", authHeader);
	  }
	  
	/**
	 * Add User Point
	 *
	 * @param - 
	 *
	 * @return Observable
	 */
	addPoint(reqData) {
		let authHeader = this.common.getadminHttpOptionWithLogin();
		return this.http.post(this.actionUrl + "subscription/user-point/", reqData, authHeader);
  	}

}
