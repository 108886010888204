import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { merge, Observable, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Subject, interval, Subscription } from 'rxjs';
import { AnchorService } from '../services/anchor/anchor.service';
import { CommonService } from '../services/common/common.service';
import { UserService } from '../services/user/user.service';
import { AccessibilityService } from '../services/accessibility/accessibility.service';
import { AnalyticsComponent } from '../anchor/analytics/analytics.component';
import { TraceroutevisualiserComponent } from '../anchor/traceroutevisualiser/traceroutevisualiser.component';
import { RegularDomainZoneAnalyticsComponent } from '../periodic/regular-domain-zone-analytics/regular-domain-zone-analytics.component';
export interface RegularElement {
  position: number;
  command: string;
  region: number;
  host: string;
  zone: string;
  status: string;
  date: string;
  view_status: boolean;
  action: string;
}
export interface PeriodicElement {
  position: number;
  command: string;
  anchor: string;
  service: string;
  host: string;
  zone: string;
  status: string;
  fromdate: string;
  todate: string;
  view_status: boolean;
  // graph_view: boolean;
  interval_unit: string;
  interval_time: number;
  action: string;
}
export interface TracerouteElement {
  position: number;
  command: string;
  anchor: string;
  status: string;
  fromdate: string;
  todate: string;
  view_status: boolean;
  host_name: string;
  action: string;
}
export interface DnsqueryElement {
  position: number;
  command: string;
  anchor: string;
  status: string;
  fromdate: string;
  todate: string;
  view_status: boolean;
  record_type: string;
  host_name: string;
  action: string;
}

@Component({
  selector: 'app-executed-commend-history',
  templateUrl: './executed-commend-history.component.html',
  styleUrls: ['./executed-commend-history.component.css']
})
export class ExecutedCommendHistoryComponent implements OnInit, AfterViewInit {
  subscription: Subscription;
  activeParams: any;
  showTable: boolean = false;
  selectedParentTabIndex: number = 0;
  selectedTabIndex: number = 0;
  activatedrouteIndex: string = 'regular';
  userdetails: any;
  historyList: any = [];
  regularHistoryList: any = [];
  periodicHistoryList: any = [];
  tracerouteHistoryList: any = [];
  dnsqueryHistoryList: any = [];
  serviceList: any = [];
  zoneList: any = [];
  domainList: any = [];
  static: boolean = true
  regularDisplayedColumns: string[] = ['position', 'command', 'anchor', 'zone', 'region', 'host', 'status', 'date', 'view_status', 'action'];
  periodicDisplayedColumns: string[] = ['position', 'command', 'anchor', 'zone', 'host', 'status', 'fromdate', 'todate', 'view_status', 'interval_unit',
  'interval_time', 'action'];
  tracerouteDisplayedColumns: string[] = ['position', 'command', 'host_name', 'anchor', 'status', 'fromdate', 'view_status', 'action'];
  dnsqueryDisplayedColumns: string[] = ['position', 'command', 'host_name', 'anchor', 'record_type', 'status', 'fromdate', 'view_status', 'action'];
  // dataSource: any;
  regularDataSource: any;
  periodicDataSource: any;
  tracerouteDataSource: any;
  dnsqueryDataSource: any;
  // @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('paginatorregular') paginator: MatPaginator;
  @ViewChild('paginatorperiodic') paginatorperiodic: MatPaginator;
  @ViewChild('paginatortraceroute') paginatortraceroute: MatPaginator;
  @ViewChild('paginatordnsquery') paginatordnsquery: MatPaginator;


  @ViewChild('t1Sort') t1Sort: MatSort;
  @ViewChild('t2Sort') t2Sort: MatSort;
  @ViewChild('t3Sort') t3Sort: MatSort;
  @ViewChild('t4Sort') t4Sort: MatSort;
  constructor(
    private common: CommonService,
    private anchor: AnchorService,
    private userservice: UserService,
    private accessibilityservice: AccessibilityService,
    private router: Router,
    private activatedroute: ActivatedRoute,
    private dialog: MatDialog
  ) {
    let userData = JSON.parse(localStorage.getItem('userLoginData'));
    if (userData != '' || userData != undefined) {
      this.userdetails = userData;
      this.getDomainList();
      // this.getServiceList();
      // this.GetZoneList();
    }

    this.activeParams = this.activatedroute.snapshot.params;
    // console.log('activatedroute', this.activeParams);
    // if(this.activatedroute.snapshot.params['historytype'] == 'regular'){
    //   this.selectedTabIndex = 0
    // }else{
    //   this.selectedTabIndex = 1
    // }
    if (this.activeParams.historytype === 'regular') {
      this.selectedParentTabIndex = 0;
      this.selectedTabIndex = 0
    }
    if (this.activeParams.historytype === 'periodic') {
      this.selectedParentTabIndex = 0;
      this.selectedTabIndex = 1
    }
    if (this.activeParams.historytype === 'traceroute') {
      this.selectedParentTabIndex = 1;
      //this.selectedTabIndex = 2
    }
    if (this.activeParams.historytype === 'dnsquery') {
      this.selectedParentTabIndex = 2;
      //this.selectedTabIndex = 2
    }

  }

  ngOnInit() {
    this.allCommendHistory();
  }

  ngAfterViewInit() {
    // const source = interval(20000);
    // this.subscription = source.subscribe(val => this.enqueueStatusChange())
  }

  // enqueueStatusChange(){
  //   let that = this;
  //   if(this.periodicHistoryList.length > 0){
  //     that.periodicHistoryList.forEach(element => {
  //       if(element.graph_view === false){
  //         let view_graph: boolean = that.graphViewStatus(element.fromdate);
  //         console.log('view_graph', view_graph);
  //         element.graph_view = view_graph
  //       }
  //     });
  //   }
  // }

  allCommendHistory() {
    setTimeout(() => {
      this.common.setLoader(true);
    });

    this.historyList = [];
    this.regularHistoryList = [];
    this.periodicHistoryList = [];
    this.tracerouteHistoryList = [];
    this.dnsqueryHistoryList = [];
    this.regularDataSource = new MatTableDataSource(this.regularHistoryList);
    this.periodicDataSource = new MatTableDataSource(this.periodicHistoryList);
    this.tracerouteDataSource = new MatTableDataSource(this.tracerouteHistoryList);
    this.dnsqueryDataSource = new MatTableDataSource(this.dnsqueryHistoryList)
    this.anchor.getCommendHistory().subscribe(
      res => {
        console.log('History List', res);
        if (res['status'] == 1) {
          let that = this;
          if (res['history'].length > 0) {
            let i = 1;
            let regularCount = 1;
            let periodicCount = 1;
            let traceRouteCount = 1;
            let dnsQueryCount = 1;
            /// New Code Write on 26/08/2021
            res['history'].forEach(element => {
              // console.log('Element', element);
              if (element.query_type == 'regular' || element.query_type == 'domain_zone_regular') {
                that.regularHistoryList.push(
                  {
                    id: element.id,
                    position: regularCount,
                    command: element.query,
                    anchor: element.anchor_names ? element.anchor_names : '',
                    region: element.region,
                    type: element.query_type,
                    query_execution_status: element.query_status,
                    server_hit_status: element.server_hit_status,
                    host: element.host_name,
                    zone: element.zone_area_name ? element.zone_area_name : 'N.A.',
                    status: element.query_status,
                    view_status: element.has_been_seen,
                    date: element.created_date,
                    action: element.has_been_seen
                  }
                );
                regularCount++
              }
              if (element.query_type == 'periodic' || element.query_type == 'domain_anchor_periodic' || element.query_type == 'domain_zone_periodic') {
                that.periodicHistoryList.push(
                  {
                    id: element.id,
                    type: element.query_type,
                    query_execution_status: element.query_status,
                    server_hit_status: element.server_hit_status,
                    position: periodicCount,
                    command: element.query,
                    anchor: element.anchor_names ? element.anchor_names : '',
                    host: element.host_name ? element.host_name : '',
                    //service: element.service_name ? element.service_name : '',
                    zone: element.zone_area_name ? element.zone_area_name : 'N.A.',
                    status: element.query_status,
                    fromdate: element.created_date,
                    todate: element.query_execution_end_date,
                    interval_unit: element.query_execution_interval_unit, 
                    interval_time: element.query_execution_interval_time,
                    view_status: element.has_been_seen,
                    // graph_view: view_graph,
                    action: element.has_been_seen
                  }
                );
                periodicCount++
              }
              if (element.query_type == 'traceroute' || element.query_type == 'domain_anchor_traceroute' || element.query_type == 'domain_zone_traceroute') {
                that.tracerouteHistoryList.push(
                  {
                    id: element.id,
                    query_id: element.commend_query_id,
                    type: element.query_type,
                    query_execution_status: element.query_status,
                    position: traceRouteCount,
                    command: element.query,
                    anchor: element.anchor_names ? element.anchor_names : '',
                    status: element.query_status,
                    host_name: element.host_name,
                    fromdate: element.created_date,
                    view_status: element.has_been_seen,
                    action: element.has_been_seen
                  }
                );
                traceRouteCount++
              }
              if (element.query_type == 'domain_anchor_dns_query') {
                that.dnsqueryHistoryList.push(
                  {
                    id: element.id,
                    query_id: element.commend_query_id,
                    type: element.query_type,
                    query_execution_status: element.query_status,
                    position: dnsQueryCount,
                    command: element.query,
                    anchor: element.anchor_names ? element.anchor_names : '',
                    status: element.query_status,
                    record_type: element.dnsquery_type,
                    host_name: element.host_name,
                    fromdate: element.created_date,
                    view_status: element.has_been_seen,
                    action: element.has_been_seen
                  }
                );
                dnsQueryCount++
              }
              i++;
            });
            this.regularDataSource = new MatTableDataSource(this.regularHistoryList);
            this.regularDataSource.paginator = this.paginator;
            this.regularDataSource.sort = this.t1Sort;
            this.periodicDataSource = new MatTableDataSource(this.periodicHistoryList)
            this.periodicDataSource.paginator = this.paginatorperiodic;
            this.periodicDataSource.sort = this.t2Sort;
            this.tracerouteDataSource = new MatTableDataSource(this.tracerouteHistoryList)
            this.tracerouteDataSource.paginator = this.paginatortraceroute;
            this.tracerouteDataSource.sort = this.t3Sort;

            this.dnsqueryDataSource = new MatTableDataSource(this.dnsqueryHistoryList)
            this.dnsqueryDataSource.paginator = this.paginatordnsquery;
            this.dnsqueryDataSource.sort = this.t4Sort;
            setTimeout(() => {
              this.showTable = true;
              this.common.setLoader(false);
            }, 1000);
          }
          else {
            this.historyList = [];
            this.regularDataSource = new MatTableDataSource(this.regularHistoryList);
            this.regularDataSource.paginator = this.paginator;
            this.regularDataSource.sort = this.t1Sort;
            this.periodicDataSource = new MatTableDataSource(this.periodicHistoryList)
            this.periodicDataSource.paginator = this.paginatorperiodic;
            this.periodicDataSource.sort = this.t2Sort;
            this.tracerouteDataSource = new MatTableDataSource(this.tracerouteHistoryList)
            this.tracerouteDataSource.paginator = this.paginatortraceroute;
            this.tracerouteDataSource.sort = this.t3Sort;
            setTimeout(() => {
              this.showTable = true;
              this.common.setLoader(false);
            }, 300);
          }
        }
        if (res['status'] == 0) {
          this.historyList = [];
          this.regularDataSource = new MatTableDataSource(this.regularHistoryList);
          this.regularDataSource.paginator = this.paginator;
          this.regularDataSource.sort = this.t1Sort;
          this.periodicDataSource = new MatTableDataSource(this.periodicHistoryList)
          this.periodicDataSource.paginator = this.paginatorperiodic;
          this.periodicDataSource.sort = this.t2Sort;
          this.tracerouteDataSource = new MatTableDataSource(this.tracerouteHistoryList)
          this.tracerouteDataSource.paginator = this.paginatortraceroute;
          this.tracerouteDataSource.sort = this.t3Sort;
          setTimeout(() => {
            this.showTable = true;
            this.common.setLoader(false);
          }, 1000);
        }
      },
      err => {
        setTimeout(() => {
          this.common.setLoader(false);
        });
        console.log("ERROR");
        console.log(err);
        this.common.openSnackBar('Please try later', '');
      }
    );
  }

  // graphViewStatus(created_date){
  //   let added_time = 3 * 60 * 1000; /* ms */
  //   let queryStartDate = new Date(created_date);
  //   let nowDate = new Date();
  //   if(new Date(queryStartDate.getTime() + added_time) <= nowDate){
  //     return true;
  //   }
  //   else{
  //     return false;
  //   }
  // }

  onParentTabChanged(event) {
    console.log('onParentTabChanged', event);
    if (event.index === 0) {
      window.history.replaceState({}, '', 'query/executed-history/regular');
    }
    if (event.index === 1) {
      window.history.replaceState({}, '', 'query/executed-history/traceroute');
    }
    if (event.index === 2) {
      window.history.replaceState({}, '', 'query/executed-history/dnsquery');
    }
  }

  onTabChanged(event) {
    console.log('onTabChanged', event);
    if (event.index === 0) {
      window.history.replaceState({}, '', 'query/executed-history/regular');
    }
    if (event.index === 1) {
      window.history.replaceState({}, '', 'query/executed-history/periodic');
    }
    if (event.index === 2) {
      window.history.replaceState({}, '', 'query/executed-history/traceroute');
    }
  }

  getDomainList() {
    this.userservice.domainList().subscribe(
      res => {
        if (res['status'] == 1) {
          this.domainList = res['domain'];
        }
        if (res['status'] == 0) {
          this.domainList = [];
        }
      },
      err => {
        console.log("ERROR");
        console.log(err);
        this.common.openSnackBar('Please try later', '');
      }
    );
  }

  filterDomainName(domain_ip) {
    let domain_name = '';
    if (this.domainList.length > 0) {
      this.domainList.forEach(element => {
        if (domain_ip == element.domain_ip) {
          domain_name = element.domain_name;
          // console.log('Domain Name', domain_name);
        }
      });
    }
    return domain_name;
  }

  // getServiceList(){
  //   this.serviceList = [];
  //   this.accessibilityservice.getAllHostGroupList().subscribe(
  //     res => {
  //       if(res['status'] == 1){
  //         this.serviceList = res['hostgroup'];
  //         // console.log('this.serviceList', this.serviceList);
  //       }
  //       if(res['status'] == 0){
  //         this.serviceList = [];
  //       }
  //     },
  //     err => {
  //       console.log("ERROR");
  //       console.log(err);
  //       this.common.openSnackBar('Please try later', '');
  //     }
  //   );
  // }

  // filterServiceName(service_id){
  //   let service_name = '';
  //   if(this.serviceList.length > 0){
  //     this.serviceList.forEach(ser => {
  //       if(service_id == ser.id){
  //         console.log('service_id', service_id);
  //         console.log('element.id', ser.id);
  //         service_name = ser.host_group_name;
  //       }
  //     });
  //   }
  //   return service_name;
  // }

  // GetZoneList(){
  //   this.zoneList = [];
  //   this.accessibilityservice.getAllZoneList().subscribe(
  //     res => {
  //       if(res['status'] == 1){
  //         this.zoneList = res['zone'];
  //       }
  //       if(res['status'] == 0){
  //         this.zoneList = [];
  //       }
  //     },
  //     err => {
  //       console.log("ERROR");
  //       console.log(err);
  //       this.common.openSnackBar('Please try later', '');
  //     }
  //   );
  // }

  // filterZoneName(zone_id){
  //   let zone_name = '';
  //   if(this.zoneList.length > 0){
  //     this.zoneList.forEach(element => {
  //       if(zone_id == element.id){
  //         zone_name = element.user_zone_name;
  //       }
  //     });
  //   }
  //   return zone_name;
  // }

  dialogRefTags: MatDialogRef<AnalyticsComponent> | null;
  dialogRefTags1: MatDialogRef<TraceroutevisualiserComponent> | null;
  dialogRefTags2: MatDialogRef<RegularDomainZoneAnalyticsComponent> | null;

  openGraph(history_id, command_type, command_query_id, execution_status): void {
    console.log('#######', command_query_id)
    if (execution_status == 'enqueue') {
      alert('Your query is still enqueue up Please wait a moment.');
    } else {
      const fromData = {
        id: history_id,
        is_view: true
      }

      //Update history view status
      this.anchor.putCommendHistory(fromData).subscribe(
        res => {
          if (res['status'] == 1) {
            if (command_type == 'regular') {
              // Open Graph Pop Up
              this.dialogRefTags = this.dialog.open(AnalyticsComponent, {
                data: {
                  id: history_id
                },
                disableClose: false,
                height: "590px",
                width: "900px"
              });

              this.dialogRefTags.afterClosed().subscribe(result => {
                console.log('afterClosed', result);
                this.allCommendHistory();
              });
            }
            if (command_type == 'domain_zone_regular') {
              console.log('domain_zone_regular');
              // Open Graph Pop Up
              this.dialogRefTags2 = this.dialog.open(RegularDomainZoneAnalyticsComponent, {
                data: {
                  id: history_id
                },
                disableClose: false,
                height: "590px",
                width: "900px"
              });

              this.dialogRefTags2.afterClosed().subscribe(result => {
                console.log('afterClosed', result);
                this.allCommendHistory();
              });
            }
            if (command_type == 'periodic') {
              let encript_id = btoa(history_id)
              this.router.navigate(['/query/periodic-analytics/' + encript_id]);
            }
            if (command_type == 'domain_anchor_periodic') {
              let encript_id = btoa(history_id)
              this.router.navigate(['/query/periodic-domain-analytics/' + encript_id]);
            }
            if (command_type == 'domain_zone_periodic') {
              let encript_id = btoa(history_id)
              this.router.navigate(['/query/periodic-domain-zone-analytics/' + encript_id]);
            }
            if (command_type == 'traceroute') {
              console.log('traceroute')
              let encript_id = btoa(history_id)
              this.router.navigate(['/visualizer/treceroute/' + encript_id]);
            }
            if (command_type == 'domain_anchor_traceroute') {
              console.log('domain_anchor_traceroute')
              let encript_id = btoa(history_id)
              this.router.navigate(['/visualizer/treceroute/' + encript_id]);
            }
            if (command_type == 'domain_zone_traceroute') {
              console.log('domain_zone_traceroute')
              let encript_id = btoa(history_id)
              this.router.navigate(['/visualizer/treceroute/' + encript_id]);
            }
            if (command_type == 'domain_anchor_dns_query') {
              console.log('domain_zone_traceroute')
              let encript_id = btoa(history_id)
              this.router.navigate(['/dns_query/dnsquery_visualiser/' + encript_id]);
            }
          }
        },
        err => {
          console.log("ERROR");
          console.log(err);
          this.common.openSnackBar('Please try later', '');
        }
      );
    }
  }


  ngOnDestroy() {
    this.subscription && this.subscription.unsubscribe();
  }

}