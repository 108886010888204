import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SubscriptionService } from '../services/subscription/subscription.service';
import { element } from 'protractor';

@Component({
  selector: 'app-subscription-details',
  templateUrl: './subscription-details.component.html',
  styleUrls: ['./subscription-details.component.css']
})
export class SubscriptionDetailsComponent implements OnInit {
  userdetails: any;
  subscriptionList: any = [];
  constructor(
    private subscription_service: SubscriptionService,
    private _router: Router
  ) { 
    let userData = JSON.parse(localStorage.getItem('userLoginData'));
    if(userData != '' || userData !=undefined){
      this.userdetails = userData;
      console.log(this.userdetails);
    }
    console.log('This is points Details Page');
  }

  ngOnInit() {
    this.subscription_service.userSubscriptionList().subscribe(
			res => {
				console.log(res);
				if (res['status'] == 1) {
          this.subscriptionList = [];
          let that = this;
          if(res['plans'].length > 0){
            res['plans'].forEach(element => {
              that.subscriptionList.push(
                {
                  id:element.id,
                  total_points: parseInt(element.total_points),
                  used_points: parseInt(element.used_points),
                  remaining_points: parseInt(element.remaining_points),
                  package_activation_date: element.package_activation_date,
                  package_deactivation_date: element.package_deactivation_date
                }
              );
            });
          }
				}
			},
			err => {
				console.log("ERROR");
				console.log(err);
			}
		);
  }

}
