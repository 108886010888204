import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AnchorService } from '../../services/anchor/anchor.service';
import { CommonService } from '../../services/common/common.service';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';

@Component({
  selector: 'app-regular-domain-zone-analytics',
  templateUrl: './regular-domain-zone-analytics.component.html',
  styleUrls: ['./regular-domain-zone-analytics.component.css']
})
export class RegularDomainZoneAnalyticsComponent implements OnInit {
  lineChartData: any = [];
  lineChartLabels: any = [];
  Label: any = [];
  lineChartOptions = {
    responsive: true,
  };
  lineChartColors: Color[] = [
    {
      borderColor: 'green',
      // backgroundColor: 'rgba(255,255,255)',
    },
  ];
  lineChartLegend = true;
  lineChartPlugins = [];
  lineChartType = 'bar';
  rtt_max: any = [];
  rtt_med: any = [];
  rtt_min: any = [];
  setdate = new Date();

  userdetails: any;
  history_id: number;
  constructor(
    public dialogRef: MatDialogRef<RegularDomainZoneAnalyticsComponent>, @Inject(MAT_DIALOG_DATA)
    public histort_data: any,
    private dialog: MatDialog,
    private anchor: AnchorService,
    private common: CommonService,
    // private ar: ActivatedRoute, 
    // private router: Router
  ) {
    let userData = JSON.parse(localStorage.getItem('userLoginData'));
    if (userData != '' || userData != undefined) {
      this.userdetails = userData;
    }
    this.history_id = + histort_data.id;
  }

  ngOnInit() {
    console.log('this.history_id', this.history_id);
    this.generateRegularZoneGeaphData();
  }

  generateRegularZoneGeaphData() {
    const formData: any = {
      id: this.history_id
    }
    this.rtt_max = [];
    this.rtt_med = [];
    this.rtt_min = [];
    this.lineChartLabels = [];
    let that = this;
    this.lineChartData.push(
      { "data": this.rtt_max, "label": 'RTT-MAX' },
      { "data": this.rtt_med, "label": 'RTT-MED' },
      { "data": this.rtt_min, "label": 'RTT-MIN' }
    );
    this.anchor.getRegularZoneAnalyticalData(formData).subscribe(
      res => {
        if (res['status'] == 1) {
          console.log('Graph Data', res);
          if (res['data'].length > 0) {
            this.lineChartData = [];
            res['data'].forEach(element => {
              that.rtt_max.push(element.rtt_max);
              that.rtt_med.push(element.rtt_avg);
              that.rtt_min.push(element.rtt_min);
              that.lineChartLabels.push(element.anchor_name);
              // Create Labels
              let unix_timestamp = element.time
              // Create a new JavaScript Date object based on the timestamp
              // multiplied by 1000 so that the argument is in milliseconds, not seconds.
              var date = new Date(unix_timestamp);
              // Hours part from the timestamp
              var hours = date.getHours();
              // Minutes part from the timestamp
              var minutes = "0" + date.getMinutes();
              // Seconds part from the timestamp
              var seconds = "0" + date.getSeconds();

              // Will display time in 10:30:23 format
              var formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);

              // console.log('Time',formattedTime);
              // that.lineChartLabels.push(formattedTime);
            });
            this.lineChartData.push(
              { "data": this.rtt_max, "label": 'RTT-MAX' },
              { "data": this.rtt_med, "label": 'RTT-MED' },
              { "data": this.rtt_min, "label": 'RTT-MIN' }
            );
          }
        }
        if (res['status'] == 0) {
          this.lineChartData.push(
            { "data": this.rtt_max, "label": 'RTT-MAX' },
            { "data": this.rtt_med, "label": 'RTT-MED' },
            { "data": this.rtt_min, "label": 'RTT-MIN' }
          );
        }
      },
      err => {
        console.log("ERROR");
        console.log(err);
        this.common.openSnackBar('Please try later', '');
      }
    );
  }

  closePopup() {
    this.dialogRef.close();
  }

}
