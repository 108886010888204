import { Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ConfigurationService {
	// public Server = 'http://localhost:8087/';
	public Server = 'https://apivizv0.aior.in/';
	public ApiUrl = 'api/';
	public ApiUrlForImg = 'api';
	public ServerWithApiUrl = this.Server + this.ApiUrl;
	public ImageUrl = this.Server + this.ApiUrlForImg;
	public AIORAnchorUrl = 'https://apird3v0.aior.in/apisrv/api/v1/';
	public domain_name;
	public user_details: any;
	constructor(private _route: Router,
		private _location: Location) {
		this.domain_name = window.location.host;
		this.user_details = JSON.parse(localStorage.getItem('userLoginData'));
		this.canActivate();
	}

	canActivate(): Observable<boolean> | Promise<boolean> | boolean {
		console.log('IMAGE URL', this.ImageUrl);
		   	if (this.user_details != null || this.user_details != undefined){
				var current_date = new Date();
				var current_timestamp = current_date.getTime();
				// console.log('Current Timestamp', current_timestamp);
				// console.log('Login Timestamp', this.user_details.token_access_time)
				let login_remain_time = current_timestamp - this.user_details.token_access_time;
				// console.log('Login Timestamp Remain', login_remain_time)
			 	// Token from the LogIn is avaiable, so the user can pass to the route
			 	return true
		   	} else  {
				// Check the user is in landing page or not
				console.log('Check Router Path', this._location.path());
				console.log('Check Angular Router Path', this._route.url);
				if(this._location.path() === '' || this._location.path() === '/login' || this._location.path() === '/forgot'){
					this._route.navigate( ["/login"] );
				}
				else if(this._location.path() === '/admin' || this._location.path() === '/admin/login'){
					this._route.navigate( ["/admin/login"] );
				}
				else if(this._location.path() == '/thirdparty/login/:uname/:pword'){
					this._route.navigate( ["/thirdparty/login/:uname/:pword"] );
				}
				else{
					// Token from the LogIn is not avaible because something went wrong or the user wants to go over the url to the site
					// Hands the user to the LogIn page
					// this._route.navigate( ["/login"] );
					this._route.navigate( ["/thirdparty/login/:uname/:pword"] );
				}
				return false
	 
		   }
	  }
}