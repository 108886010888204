import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpClientModule, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, Subject} from 'rxjs';
import { ConfigurationService } from '../config/configuration.service';
// import { CommonService } from '../common/common.service'


@Injectable({
  providedIn: 'root'
})
export class LoginsignupService {
  private actionUrl: string;
  public tokenPairOverwriteObservable = new Subject<any>();
  public tokenOverwriteCallBackResult$ = this.tokenPairOverwriteObservable.asObservable();
  constructor(
    private http: HttpClient, 
    private _configuration: ConfigurationService, 
	// private common: CommonService
	) {
		this.actionUrl = _configuration.ServerWithApiUrl;
		
  }
  
  /**
	 * User Login
	 *
	 * @param - username, password in json format
	 *
	 * @return Observable
	 */
	authLogin(reqData) {
		// let authHeader = this.common.getHttpOptionNotLogin();
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		let options = { headers: headers };
		return this.http.post(this.actionUrl + "user/user-login/", reqData, options);
  }
  
	/**
	 * User Registration
	 *
	 * @param -- Required data in json format
	 *
	 * @return Observable
	 */
	userSignup(reqData) {
		console.log('Service', reqData)
		// let authHeader = this.common.getHttpOptionNotLogin();
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		let options = { headers: headers };
		return this.http.post(this.actionUrl + "user/registration/", reqData, options);
	}

	/**
	 * User Reset Password
	 *
	 * @param -- Required data in json format
	 *
	 * @return Observable
	 */
	 userForgotPassword(reqData) {
		console.log('Service', reqData)
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		let options = { headers: headers };
		return this.http.post(this.actionUrl + "user/forgot-password/", reqData, options);
	}

	/**
	 * Email Existence Check
	 *
	 * @param -- email id in json format
	 *
	 * @return Observable
	 */
	checkEmailExistence(reqData){
		// let authHeader = this.common.getHttpOptionNotLogin();
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		let options = { headers: headers };
		return this.http.post(this.actionUrl + "user/check-email/", reqData, options);
	}

	/**
	 * Mobile Number existence check
	 *
	 * @param -- email id in json format
	 *
	 * @return Observable
	 */
	checkMobileNumberExistence(reqData){
		// let authHeader = this.common.getHttpOptionNotLogin();
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		let options = { headers: headers };
		return this.http.post(this.actionUrl + "user/check-phone/", reqData, options);
	}

	/**
	 * Send User Registration Login Details Through Mail
	 *
	 * @param -- Required data user_id in json format
	 *
	 * @return Observable
	 */
	sendLoginDetailsMail(reqData) {
		// let authHeader = this.common.getHttpOptionNotLogin();
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		let options = { headers: headers };
		return this.http.post(this.actionUrl + "user/send-registration-email/", reqData, options);
	}

	/**
	 * Send Varification Code Through Mail
	 *
	 * @param -- Required data user_id in json format
	 *
	 * @return Observable
	 */
	sendVarificationMail(reqData) {
		// let authHeader = this.common.getHttpOptionNotLogin();
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		let options = { headers: headers };
		return this.http.post(this.actionUrl + "user/send-email/", reqData, options);
	}

	/**
	 * Check OTP
	 *
	 * @param -- Required data user_id, OTP in json format
	 *
	 * @return Observable
	 */
	checkOTP(reqData) {
		// let authHeader = this.common.getHttpOptionNotLogin();
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		let options = { headers: headers };
		return this.http.post(this.actionUrl + "user/check-otp/", reqData, options);
	}

	/**
	 * JWT Token Obtain Pair
	/**
	 * User Login
	 *
	 * @param - username, password in json format
	 *
	 * @return Observable
	 */
	getTokenObtainPair(reqData) {
		return this.http.post(this.actionUrl + "token/", reqData);
	}

	tokenPairOverwriteInLocalstorage(reqData){
		console.log('tokenPairOverwriteInLocalstorage', reqData);
		let current_date = new Date(); /// Get Current Date
		let current_timestamp = current_date.getTime(); /// Convert current date to timestamp
		let userData = JSON.parse(localStorage.getItem('userLoginData'));
		if (userData != null || userData != undefined){
			// overwrite user access token
			this.getTokenObtainPair(reqData).subscribe(
				res => {
					userData.token = res['access'];
					userData.refresh = res['refresh'];
					userData['token_access_time'] = current_timestamp;
					localStorage.setItem('userLoginData', JSON.stringify(userData));
					this.tokenPairOverwriteObservable.next('success');
					console.log('Local Storage Data', JSON.parse(localStorage.getItem('userLoginData')));
				},
				err => {
					console.log(err);
				},
			);
		}else{
			// this.openSnackBar('Localstorage is not set, please it set first', '');
		}
	}


	//admin pair
	admintokenPairOverwriteInLocalstorage(reqData){
		console.log('tokenPairOverwriteInLocalstorage', reqData);
		let current_date = new Date(); /// Get Current Date
		let current_timestamp = current_date.getTime(); /// Convert current date to timestamp
		let userData = JSON.parse(localStorage.getItem('adminLoginData'));
		if (userData != null || userData != undefined){
			// overwrite user access token
			this.getTokenObtainPair(reqData).subscribe(
				res => {
					userData.token = res['access'];
					userData.refresh = res['refresh'];
					userData['token_access_time'] = current_timestamp;
					localStorage.setItem('adminLoginData', JSON.stringify(userData));
					this.tokenPairOverwriteObservable.next('success');
					console.log('Local Storage Data', JSON.parse(localStorage.getItem('adminLoginData')));
				},
				err => {
					console.log(err);
				},
			);
		}else{
			// this.openSnackBar('Localstorage is not set, please it set first', '');
		}

	}
}
