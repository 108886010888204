import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpClientModule, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, NavigationEnd } from '@angular/router';
import { Observable, Subject, interval, Subscription, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material';
import { ConfigurationService } from '../config/configuration.service';
import { FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MassageBoxComponent } from '../../global/massage-box/massage-box.component';

@Injectable({
	providedIn: 'root'
})
export class CommonService {
	private actionUrl: string;
	imageUrl; //rename imageFileBinary to imageUrl
	profileImage: string;
	themeBgColor: string = 'bg-light';
	public isLoader: boolean = false;
	private previousUrl: string;
	private currentUrl: string;
	// public tokenPairOverwriteObservable = new Subject<any>();
	// public tokenOverwriteCallBackResult$ = this.tokenPairOverwriteObservable.asObservable();
	public profileImageObservable = new Subject<any>();
	public profileImageCallBackResult$ = this.profileImageObservable.asObservable();

	constructor(private http: HttpClient,
		private _configuration: ConfigurationService,
		private snackBar: MatSnackBar,
		private router: Router,
		private sanitizer: DomSanitizer,
		private dialog: MatDialog) {
		this.actionUrl = _configuration.ServerWithApiUrl;
		this.currentUrl = this.router.url;
		router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				this.previousUrl = this.currentUrl;
				this.currentUrl = event.url;
			};
		});
	}

	dialogRefTags: MatDialogRef<MassageBoxComponent> | null;

	getHttpOptionNotLogin() {
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		// headers = headers.append('Access-Control-Allow-Origin', '*');
		// headers = headers.append('Access-Control-Allow-Methods', 'GET,POST,OPTIONS,DELETE,PUT');
		let options = { headers: headers };
		return options;
	}

	getHttpOptionWithLogin() {
		let userData = JSON.parse(localStorage.getItem('userLoginData'));
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		// headers = headers.append('Access-Control-Allow-Origin', '*');
		// headers = headers.append('Access-Control-Allow-Methods', 'GET,POST,OPTIONS,DELETE,PUT');
		headers = headers.append('Authorization', 'Bearer ' + userData.token);
		let options = { headers: headers };
		return options;
	}

	getadminHttpOptionWithLogin() {
		let userData = JSON.parse(localStorage.getItem('adminLoginData'));
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		// headers = headers.append('Access-Control-Allow-Origin', '*');
		// headers = headers.append('Access-Control-Allow-Methods', 'GET,POST,OPTIONS,DELETE,PUT');
		headers = headers.append('Authorization', 'Bearer ' + userData.token);
		let options = { headers: headers };
		return options;
	}

	getAioriMnHttpOptionHeader() {
		let headers = new HttpHeaders();
		headers = headers.append('Content-Type', 'application/json');
		headers = headers.append('accept', 'application/json');
		// headers = headers.append('Access-Control-Allow-Origin', 'http://localhost:4200');
		// headers = headers.append('Access-Control-Allow-Credentials', 'true');
		headers = headers.append('X-IIFON-API-KEY', '87b068d2-0437-4d0b-9b41-8d09796db75e');
		// headers = headers.append('apikey', '996b6ecf6cdd0df6aa5ffeffaca6983b');
		// headers = headers.append('Authorization', 'Basic ' + btoa('rd3rn:!1Fon@API'));
		let options = { headers: headers };
		return options;
	}

	// headerApiKeyConfig() {
	// 	const httpOptions = {
	// 		headers: new HttpHeaders({
	// 			'Content-Type': 'application/json',
	// 			'X-IIFON-API-KEY': '87b068d2-0437-4d0b-9b41-8d09796db75e'
	// 		})
	// 	}
	// 	return httpOptions
	// }

	headerApiKeyConfig() {
		const httpOptions = {
			headers: new HttpHeaders({
				"Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": "true",
				"apikey": "996b6ecf6cdd0df6aa5ffeffaca6983b"
			})
		}
		return httpOptions
	}

	public getPreviousUrl() {
		return this.previousUrl;
	}

	openSnackBar(message: string, action: string) {
		this.snackBar.open(message, action, {
			duration: 4000,
			verticalPosition: 'top',
			horizontalPosition: 'center'
		});
	}

	setLoader(status) {
		this.isLoader = status;
	}

	themeChange(theme) {
		this.themeBgColor = theme;
	}

	// tokenPairOverwriteInLocalstorage(reqData){
	// 	console.log('tokenPairOverwriteInLocalstorage', reqData);
	// 	let current_date = new Date(); /// Get Current Date
	// 	let current_timestamp = current_date.getTime(); /// Convert current date to timestamp
	// 	let userData = JSON.parse(localStorage.getItem('userLoginData'));
	// 	if (userData != null || userData != undefined){
	// 		// overwrite user access token
	// 		this.getTokenObtainPair(reqData).subscribe(
	// 			res => {
	// 				userData.token = res['access'];
	// 				userData.refresh = res['refresh'];
	// 				userData['token_access_time'] = current_timestamp;
	// 				localStorage.setItem('userLoginData', JSON.stringify(userData));
	// 				this.tokenPairOverwriteObservable.next('success');
	// 				console.log('Local Storage Data', JSON.parse(localStorage.getItem('userLoginData')));
	// 			},
	// 			err => {
	// 				console.log(err);
	// 			},
	// 		);
	// 	}else{
	// 		this.openSnackBar('Localstorage is not set, please it set first', '');
	// 	}
	// }

	getNewToken() {
		let userData = JSON.parse(localStorage.getItem('userLoginData'));
		let reqData = {
			refresh: userData.refresh
		}
		this.getAccessToken(reqData).subscribe(
			res => {
				// userData.token = res['access'];
				// userData.refresh = res['refresh']
				// this.loginData = JSON.stringify(res);
				// localStorage.setItem('userLoginData', JSON.stringify(userData));
			},
			err => {
				console.log(err);
			},
		);
	}

	userLogout() {
		//localStorage.clear();
		localStorage.removeItem('userLoginData');
	}

	// /**
	//  * JWT Token Obtain Pair
	// /**
	//  * User Login
	//  *
	//  * @param - username, password in json format
	//  *
	//  * @return Observable
	//  */
	// getTokenObtainPair(reqData) {
	// 	return this.http.post(this.actionUrl + "token/", reqData);
	// }

	/**
	 * JWT Access Token
	 *
	 * @param - refress token
	 *
	 * @return Observable
	 */
	getAccessToken(reqData) {
		return this.http.post(this.actionUrl + "refresh/", reqData);
	}

	/**
	 * common function set profile image for Observable
	 */

	setProfileImage(encodedImage) {
		let userData = JSON.parse(localStorage.getItem('userLoginData'));
		let imageBinary = encodedImage; //image binary data response from api
		this.imageUrl = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + imageBinary);
		this.profileImage = this.imageUrl;
		userData.profile_image = this.imageUrl;
		this.profileImageObservable.next(true);
	}

	/**
	 * common function set massage box for Observable
	 */

	globalMassageBox(
		title: string,
		message: string,
		action: string,
		progressber: boolean = false,
		resultbtn: boolean = false,
		okbtn: boolean = false,
		canclebtn: boolean = false,
		closebtn: boolean = false
	) {
		this.dialogRefTags = this.dialog.open(MassageBoxComponent, {
			data: {
				popTitle: title,
				popText: message,
				popAction: action,
				popProgressber: progressber,
				popResultbtn: resultbtn,
				popOkbtn: okbtn,
				popCanclebtn: canclebtn,
				popClosebtn: closebtn,
			},
			disableClose: true
		});

		this.dialogRefTags.afterClosed().subscribe(result => {
			console.log('afterClosed', result);
			return result;
			// this.router.navigate(['/analytics']);
		});
	}

	/**
	 * Get Features Score
	 *
	 * @param - None
	 *
	 * @return Observable
	 */
	getScore() {
		let authHeader = this.getHttpOptionWithLogin();
		return this.http.get(this.actionUrl + "common/features/", authHeader);
	}


	/**
	 * Create Menu in Manu Master
	 *
	 * @param - Form data in json format
	 *
	 * @return Observable
	 */
	createMenu(reqData, menu_id: number) {
		console.log(menu_id);
		let authHeader = this.getadminHttpOptionWithLogin();
		if (menu_id == 0) {
			return this.http.post(this.actionUrl + "common/menu-master/", reqData, authHeader);
		} else {
			return this.http.put(this.actionUrl + "common/menu-master/", reqData, authHeader);
		}
	}


	/**
	 * Get Menu By Menu Type
	 *
	 * @param - Menu Type through query string
	 *
	 * @return Observable
	 */
	getMenu(menu_type: string) {
		let authHeader = this.getadminHttpOptionWithLogin();
		return this.http.get(this.actionUrl + "common/menu-master?type=" + menu_type, authHeader);
	}

	/**
	 * Get Menu By User
	 *
	 * @param - None
	 *
	 * @return Observable
	 */
	getMenuByUser() {
		let authHeader = this.getHttpOptionWithLogin();
		return this.http.get(this.actionUrl + "common/user-menus", authHeader);
	}

	/**
	 * Get Menu By Parent Id
	 *
	 * @param - parent_id, menu_type in JSON Format
	 *
	 * @return Observable
	 */
	getMenuByParentId(reqData) {
		let authHeader = this.getadminHttpOptionWithLogin();
		return this.http.post(this.actionUrl + "common/menu-list-by-parent/", reqData, authHeader);
	}

	/**
	 * Get Menu By Id
	 *
	 * @param - parent_id in JSON Format
	 *
	 * @return Observable
	 */
	getMenuById(reqData) {
		let authHeader = this.getadminHttpOptionWithLogin();
		return this.http.post(this.actionUrl + "common/menu-by-id/", reqData, authHeader);
	}


	/**
	 * Get Menu By role id
	 *
	 * @param - role_id in query string
	 *
	 * @return Observable
	 */
	getMenuByRole(role_id: number) {
		let authHeader = this.getadminHttpOptionWithLogin();
		return this.http.get(this.actionUrl + "common/user-menu-by-role/?role_id=" + role_id, authHeader);
	}

	/**
	 * Get Menu By role id
	 *
	 * @param - role_id in query string
	 *
	 * @return Observable
	 */
	saveMenuByRole(reqData) {
		let authHeader = this.getadminHttpOptionWithLogin();
		return this.http.post(this.actionUrl + "common/user-menu-by-role/", reqData, authHeader);
	}


	/**
	 * Return icons from JSON
	 *
	 * @param -- any
	 *
	 * @return Observable
	 */

	public sampleIconData(): Observable<any> {
		return this.http.get("/assets/sample_json/icon.json");
	}

	/**
	 * Get all user group
	 *
	 * @param -- None
	 *
	 * @return Observable
	 */

	public getAllUserGroup(): Observable<any> {
		let authHeader = this.getadminHttpOptionWithLogin();
		return this.http.get(this.actionUrl + "common/user-group/", authHeader);
	}

	/**
	 * Get all user group
	 *
	 * @param -- None
	 *
	 * @return Observable
	 */

	public getUserByGroup(): Observable<any> {
		let authHeader = this.getadminHttpOptionWithLogin();
		return this.http.get(this.actionUrl + "common/user-group-management/", authHeader);
	}

	/**
	 * Assign user in a group
	 *
	 * @param -- form data in json format
	 *
	 * @return Observable
	 */

	public assignGroupUser(reqData): Observable<any> {
		let authHeader = this.getadminHttpOptionWithLogin();
		return this.http.post(this.actionUrl + "common/user-group-management/", reqData, authHeader);
	}

	/**
	 * Get all user wethout role
	 *
	 * @param -- None
	 *
	 * @return Observable
	 */

	public getUserWethoutRole(reqData): Observable<any> {
		let authHeader = this.getadminHttpOptionWithLogin();
		return this.http.post(this.actionUrl + "common/user-list-wethout-role/", reqData, authHeader);
	}

	/**
	 * Get all user by role
	 *
	 * @param -- None
	 *
	 * @return Observable
	 */

	public getUserByRole(reqData): Observable<any> {
		let authHeader = this.getadminHttpOptionWithLogin();
		return this.http.post(this.actionUrl + "common/user-list-by-role/", reqData, authHeader);
	}


	/**
	 * Add role 
	 *
	 * @param -- Required data in json format
	 *
	 * @return Observable
	 */
	createRole(reqData, role_id: number) {
		let authHeader = this.getadminHttpOptionWithLogin();
		if (role_id == 0) {
			return this.http.post(this.actionUrl + "common/user-group/", reqData, authHeader);
		} else {
			return this.http.put(this.actionUrl + "common/user-group/", reqData, authHeader);
		}

	}

	/**
	 * get user list
	 * 
	 * @param -- Required data in json format
	 * 
	 * @return Observable
	 */
	public getUserList(): Observable<any> {
		let authHeader = this.getadminHttpOptionWithLogin();
		return this.http.get(this.actionUrl + "user/user-list/", authHeader);
	}

	/**
	 * Delete user by id 
	 * 
	 * @param -- Required data in json format
	 * 
	 * @return Observable
	 */
	deleteUser(reqData) {
		let authHeader = this.getadminHttpOptionWithLogin();
		return this.http.put(this.actionUrl + "user/user-list/", reqData, authHeader);
	}

	/**
   * Delete role by id 
   * 
   * @param -- Required data in json format
   * 
   * @return Observable
   */
	deleteRole(reqData) {
		let authHeader = this.getadminHttpOptionWithLogin();
		return this.http.put(this.actionUrl + "common/user-group/", reqData, authHeader);
	}


	getGEOLocation(ip) {
		// Update your api key to get from https://ipgeolocation.io
		let url = "https://api.ipgeolocation.io/ipgeo?apiKey=e8effc440a2142649dbb3b99add722b8&ip=" + ip;
		return this.http
			.get(url)
			.pipe(
				catchError(this.handleError)
			);
	}

	private handleError(error: HttpErrorResponse) {
		if (error.error instanceof ErrorEvent) {
			// A client-side or network error occurred. Handle it accordingly.
			console.error('An error occurred:', error.error.message);
		} else {
			// The backend returned an unsuccessful response code.
			// The response body may contain clues as to what went wrong,
			//   console.error(
			// 	`Backend returned code ${error.status}, ` +
			// 	`body was: ${error.error}`);
		}
		// return an observable with a user-facing error message
		return throwError(
			'Something bad happened; please try again later.');
	}

	/**
	 * get settings list
	 * 
	 * @param -- Required data in json format
	 * 
	 * @return Observable
	 */
	 public getSettings(): Observable<any> {
		let authHeader = this.getadminHttpOptionWithLogin();
		return this.http.get(this.actionUrl + "common/default-settings/", authHeader);
	}

	/**
	 * post settings
	 * 
	 * @param -- Required data in json format
	 * 
	 * @return Observable
	 */
	 public postSettings(reqData): Observable<any> {
		let authHeader = this.getadminHttpOptionWithLogin();
		return this.http.post(this.actionUrl + "common/default-settings/", reqData, authHeader);
	}

	/**
	 * Block Unblock Settigs by id 
	 * 
	 * @param -- Required data in json format
	 * 
	 * @return Observable
	 */
	 blockUnblock(reqData) {
		let authHeader = this.getadminHttpOptionWithLogin();
		return this.http.put(this.actionUrl + "common/default-settings/", reqData, authHeader);
	}

	/**
	 * Settings details Settigs by id 
	 * 
	 * @param -- Required data in json format
	 * 
	 * @return Observable
	 */
	 getSettingsDetails(reqData) {
		let authHeader = this.getadminHttpOptionWithLogin();
		return this.http.post(this.actionUrl + "common/settings-details/", reqData, authHeader);
	}

	/**
	 * Settings details update by id 
	 * 
	 * @param -- Required data in json format
	 * 
	 * @return Observable
	 */
	 putSettingsDetails(reqData) {
		let authHeader = this.getadminHttpOptionWithLogin();
		return this.http.put(this.actionUrl + "common/settings-details/", reqData, authHeader);
	}

}
