import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, OnInit, Inject, OnDestroy} from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { Validators, FormBuilder, FormGroup, FormControl, FormArray } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../services/common/common.service';
import { AccessibilityService } from '../services/accessibility/accessibility.service';
import { AnchorService } from '../services/anchor/anchor.service';
export interface Item {
  id: number;
  name: string;
  color: "" | "primary" | "accent" | "warn";
}

@Component({
  selector: 'app-add-edit-zone',
  templateUrl: './add-edit-zone.component.html',
  styleUrls: ['./add-edit-zone.component.css']
})
export class AddEditZoneComponent implements OnInit {
  addZoneSetup: FormGroup;
  zoneName = new FormControl('', [Validators.required]);
  // zoneCountry = new FormControl('', [Validators.required]);
  // zoneState = new FormControl('', [Validators.required]);
  // zoneArea = new FormControl('', [Validators.required]);
  zoneId: number = 0;
  selectedAnchorArray: any
  pageTitle: string = 'Add Zone'
  buttonText: string = 'Save'

  // public items: Item[] = [
  //   { id: 0, name: "should", color: "primary" },
  //   { id: 1, name: "be", color: "primary" },
  //   { id: 2, name: "preselected", color: "primary" }
  // ];
  public items:any = [];

  constructor(
    _formbuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddEditZoneComponent>, @Inject(MAT_DIALOG_DATA) 
    public zoneData:any,
    private dialog: MatDialog,
    private ar: ActivatedRoute,
    private router: Router,
    private common: CommonService,
    private accessibilityservice: AccessibilityService,
    private anchor: AnchorService
  ) {
    this.addZoneSetup = _formbuilder.group({
      zoneName: this.zoneName,
      // zoneCountry: this.zoneCountry,
      // zoneState: this.zoneState,
      // zoneArea: this.zoneArea
    });

    console.log('Selected Zone Data', zoneData);
    this.zoneId = + zoneData.id;
    this.selectedAnchorArray = zoneData.selectedZone;
    // if(this.zoneId != 0){
    //   this.pageTitle = 'Edit Zone'
    //   this.buttonText = 'Update'
    // }
  }

  ngOnInit() {
    // getZoneDetails
    if(this.selectedAnchorArray.length > 0){
      const formData = {
        "anchor_ids": this.selectedAnchorArray
      }
      this.anchor.getAnchorById(formData).subscribe(
        res => {
          let that = this;
          // console.log('History Details', res);
          if(res['status'] == 1){
            if(res['anchor_details'].length > 0){
              res['anchor_details'].forEach(element => {
                that.items.push({ id: element.id, name: element.anchor.anchor_name, color: "primary" });
              });
            }
            // // Set Value modal field
            // this.addZoneSetup.setValue({
            //   zoneName: res['zone']['user_zone_name'],
            //   zoneCountry: res['zone']['user_zone_country_name'],
            //   zoneState: res['zone']['user_zone_state_name'],
            //   zoneArea: areaArray
            // });
            console.log('History Details', this.items);
          }
          else{
            this.common.openSnackBar('Zone details not found please try later', '');
          }
        },
        err => {
          console.log("ERROR");
          console.log(err);
        }
      );
    }
  }

  saveZone() {
    console.log('addZoneSetup', this.addZoneSetup);
    if(this.addZoneSetup.valid){
      const formData = {
          zone_name: this.addZoneSetup.value.zoneName,
          user_anchor_ids: this.selectedAnchorArray
      }
      if(this.zoneId == 0){ // For add section
        this.accessibilityservice.addZone(formData).subscribe(
          res => {
            if(res['status'] == 1){
              this.closePopup('success');
            }
            else{
              this.common.openSnackBar('Data not save please try later', '');
            }
          },
          err => {
            console.log("ERROR");
            console.log(err);
          }
        );
      }
      // else{ // For edit section
      //   formData['zone_id'] = this.zoneId;
      //   this.accessibilityservice.updateZone(formData).subscribe(
      //     res => {
      //       if(res['status'] == 1){
      //         // this.closePopup();
      //       }
      //       else{
      //         this.common.openSnackBar('Data not save please try later', '');
      //       }
      //     },
      //     err => {
      //       console.log("ERROR");
      //       console.log(err);
      //     }
      //   );
      // }

    }
  }

  closePopup(status) {
    this.dialogRef.close(status);
  }
}
