import { NgModule } from '@angular/core';
import { ModuleWithProviders} from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HostGroupManagementComponent } from './host-group-management.component'

const HOST_GROUP_ROUTER: Routes = [
  {
    path: '', component: HostGroupManagementComponent,
    children: [
      { path:'domain-list', component: HostGroupManagementComponent }
    ]
  },
];

export const HostGroupRouting: ModuleWithProviders = RouterModule.forChild(HOST_GROUP_ROUTER);