import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, OnInit, Inject, OnDestroy, NgZone } from '@angular/core';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { Validators, FormBuilder, FormGroup, FormControl, FormArray } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../../services/common/common.service';
import { AccessibilityService } from '../../services/accessibility/accessibility.service';
import { AnchorService } from '../../services/anchor/anchor.service';
import { AddEditZoneComponent } from '../add-edit-zone.component';

// just an interface for type safety.
interface marker {
  lat: number;
  lng: number;
  label: string;
  draggable: boolean;
  content: string;
  isShown: boolean;
  icon: string;
}


@Component({
  selector: 'app-mapviewanchorbyzone',
  templateUrl: './mapviewanchorbyzone.component.html',
  styleUrls: ['./mapviewanchorbyzone.component.css']
})
export class MapviewanchorbyzoneComponent implements OnInit {
  addZoneSetup: FormGroup;
  zoneName = new FormControl('', [Validators.required]);
  zoneCountry = new FormControl('', [Validators.required]);
  zoneState = new FormControl('', [Validators.required]);
  zoneArea = new FormControl('', [Validators.required]);
  // zoneId: number = 0;
  // pageTitle: string = 'Add Zone'
  buttonText: string = 'Save'
  anchorList: any = [];

  // Map Variables
  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  location: string;
  private geoCoder;

  // Radius
  radius = 50000;
  radiusLat = 0;
  radiusLong = 0;

  markers: marker[] = [];
  countryMarkers: any = [];
  stateMarkers: any = [];
  cityMarkers: any = [];
  locationMarkers: any = [];

  radiousArea: any = [];
  countryCoordinates: any = [];
  stateCoordinates: any = [];
  cityCoordinates: any = [];
  locationCoordinates: any = [];
  zoneAnchorArray: any = [];

  constructor(
    _formbuilder: FormBuilder,
    // public dialogRef: MatDialogRef<AddEditZoneComponent>, @Inject(MAT_DIALOG_DATA) 
    // public zoneData:any,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private dialog: MatDialog,
    private ar: ActivatedRoute,
    private router: Router,
    private common: CommonService,
    private accessibilityservice: AccessibilityService,
    private anchor: AnchorService
  ) {
    this.allUserAnchorList();
    this.addZoneSetup = _formbuilder.group({
      zoneName: this.zoneName,
      zoneCountry: this.zoneCountry,
      zoneState: this.zoneState,
      zoneArea: this.zoneArea
    });

    //set google maps defaults
    this.zoom = 5;
    this.latitude = parseFloat('20.5937');
    this.longitude = parseFloat('78.9629');
  }

  ngOnInit() {
    console.log(this.common.getPreviousUrl());
    this.getMapCoordinatesTree();
    // this.getAnchorRadious();
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
    });
    // getZoneDetails
    // if(this.zoneId != 0){
    //   const formData ={
    //     zone_id: this.zoneId
    //   }
    //   this.accessibilityservice.getZoneDetails(formData).subscribe(
    //     res => {
    //       // console.log('History Details', res);
    //       if(res['status'] == 1){
    //         let areaArray = []
    //         if(res['zone']['area_details'].length > 0){
    //           res['zone']['area_details'].forEach(element => {
    //             areaArray.push(element.area_name);
    //           });
    //         }
    //         // Set Value modal field
    //         this.addZoneSetup.setValue({
    //           zoneName: res['zone']['user_zone_name'],
    //           zoneCountry: res['zone']['user_zone_country_name'],
    //           zoneState: res['zone']['user_zone_state_name'],
    //           zoneArea: areaArray
    //         });
    //       }
    //       else{
    //         this.common.openSnackBar('Zone details not found please try later', '');
    //       }
    //     },
    //     err => {
    //       console.log("ERROR");
    //       console.log(err);
    //     }
    //   );
    // }
  }

  allUserAnchorList() {
    this.anchorList = [];
    this.anchor.getUserAnchors().subscribe(
      res => {
        if (res['status'] == 1) {
          this.anchorList = res['anchor'];
          // console.log('anchorList', this.anchorList);
        }
        if (res['status'] == 0) {
          this.anchorList = [];
        }
      },
      err => {
        console.log("ERROR");
        console.log(err);
        this.common.openSnackBar('Please try later', '');
      }
    );
  }

  // Get Anchor Radious Locations
  private getAnchorRadious() {
    this.radiousArea = [];
    this.anchor.getAnchorsRadiousAria().subscribe(
      res => {
        if (res['status'] == 1) {
          console.log('getAnchorRadious', res);
          this.radiousArea = res['anchor'];
          res['anchor'].forEach(element => {
            this.markers.push(
              {
                lat: element.lat,
                lng: element.long,
                label: element.count.toString(),
                draggable: false,
                content: element.location,
                isShown: false,
                icon: './assets/circle-marker.png'
              }
            );
          });
        }
        else {
          this.common.openSnackBar('Data not save please try later', '');
        }
      },
      err => {
        console.log("ERROR");
        console.log(err);
      }
    );
  }

  // Get Map Coordinates Tree
  getMapCoordinatesTree() {
    this.radiousArea = [];
    this.countryCoordinates = [];
    this.stateCoordinates = [];
    this.cityCoordinates = [];
    this.locationCoordinates = [];

    this.countryMarkers = [];
    this.stateMarkers = [];
    this.cityMarkers = [];
    this.locationMarkers = [];

    let that = this;
    this.anchor.getCoordinateTree().subscribe(
      res => {
        if (res['status'] == 1) {
          // console.log('getCoordinateTree', res);
          res['map_coodinets'].forEach(element => {
            // console.log('States', element.states);
            // Contain Table Create
            let contains_array: any = [];
            let countryAnchorDetails_array: any = [];
            element.children.forEach(item => {
              contains_array.push({ 'name': item.state, 'anchor': item.regiser_anchor_count })
            });
            //console.log(contains_array);
            let marker: any = [];
            let labelOptions = {};
            labelOptions = {
              color: '#762E28',
              fontFamily: '',
              fontSize: '45px',
              fontWeight: 'bold',
              text: element.regiser_anchor_count
            }
            marker.push(
              {
                lat: element.center_latitude,
                lng: element.center_longitude,
                label: labelOptions,
                draggable: false,
                content: contains_array,
                isShown: true,
                title: '',
                // icon: 'assets/images/circle-marker.png'
                icon: './assets/circle-marker.png',
                anchorid: 0
              }
            );
            that.countryCoordinates.push({
              id: element.id,
              center_latitude: element.center_latitude,
              center_longitude: element.center_longitude,
              anchor_no: element.regiser_anchor_count,
              markers: marker,
              anchor_details: countryAnchorDetails_array
            });
            // that.countryCoordinates.push(element);
            // that.radius = 70000 * parseInt(element.regiser_anchor_count);
            that.radius = 700000;
            if (element.children.length > 0) {
              element.children.forEach(stateItem => {
                // Contain Table Create
                let contains_array: any = [];
                let stateAnchorDetails_array: any = [];
                stateItem.children.forEach(item => {
                  contains_array.push({ 'name': item.city, 'anchor': item.regiser_anchor_count })
                });
                let smarker: any = [];
                let labelOptions = {};
                labelOptions = {
                  color: '#762E28',
                  fontFamily: '',
                  fontSize: '35px',
                  fontWeight: 'bold',
                  text: stateItem.regiser_anchor_count
                }
                smarker.push(
                  {
                    lat: stateItem.center_latitude,
                    lng: stateItem.center_longitude,
                    label: labelOptions,
                    draggable: false,
                    content: contains_array,
                    isShown: true,
                    title: '',
                    // icon: 'assets/images/circle-marker.png'
                    icon: './assets/circle-marker.png',
                    anchorid: 0
                  }
                );
                that.stateCoordinates.push({
                  id: stateItem.id,
                  center_latitude: stateItem.center_latitude,
                  center_longitude: stateItem.center_longitude,
                  anchor_no: stateItem.regiser_anchor_count,
                  markers: smarker,
                  anchor_details: stateAnchorDetails_array
                });

                if (stateItem.children.length > 0) {
                  stateItem.children.forEach(cityItem => {
                    // Contain Table Create
                    let contains_array: any = [];
                    let cityAnchorDetails_array: any = [];
                    cityItem.children.forEach(item => {
                      contains_array.push({ 'name': item.location, 'anchor': item.regiser_anchor_count })
                    });

                    // Get Anchor lat Long
                    let cmarker: any = [];
                    let labelOptions = {};
                    for (let i = 0; i < cityItem.user_anchor_ids.length; i++) {
                      cityAnchorDetails_array.push({
                        'anchor_latitude': parseFloat(cityItem.user_anchor_latitudes[i]),
                        'anchor_longitude': parseFloat(cityItem.user_anchor_longitude[i]),
                        'anchor_id': cityItem.user_anchor_ids[i]
                      });
                      labelOptions = {
                        color: '#762E28',
                        fontFamily: '',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        text: i
                      }
                      cmarker.push(
                        {
                          lat: parseFloat(cityItem.user_anchor_latitudes[i]),
                          lng: parseFloat(cityItem.user_anchor_longitude[i]),
                          label: labelOptions,
                          draggable: true,
                          content: contains_array,
                          isShown: true,
                          title: '',
                          // icon: 'assets/circle-marker.png'
                          icon: '',
                          anchorid: cityItem.user_anchor_ids[i]
                        }
                      );
                    }
                    that.cityCoordinates.push({
                      id: cityItem.id,
                      center_latitude: cityItem.center_latitude,
                      center_longitude: cityItem.center_longitude,
                      anchor_no: cityItem.regiser_anchor_count,
                      user_anchor_latitudes: cityItem.user_anchor_latitudes,
                      user_anchor_longitude: cityItem.user_anchor_longitude,
                      markers: cmarker,
                      anchor_details: cityAnchorDetails_array
                    });
                    // that.cityCoordinates.push(cityItem);
                    // that.cityMarkers.push(
                    //   {
                    //     lat: cityItem.center_latitude,
                    //     lng: cityItem.center_longitude,
                    //     label: cityItem.regiser_anchor_count,
                    //     draggable: false,
                    //     content: cityItem.label_type,
                    //     isShown: true,
                    //     icon: './assets/circle-marker.png'
                    //   }
                    // );
                    // if (cityItem.children.length > 0) {
                    //   cityItem.children.forEach(locationItem => {
                    //     that.locationCoordinates.push(locationItem);
                    //     that.locationMarkers.push(
                    //       {
                    //         lat: locationItem.center_latitude,
                    //         lng: locationItem.center_longitude,
                    //         label: locationItem.regiser_anchor_count,
                    //         draggable: false,
                    //         content: locationItem.label_type,
                    //         isShown: true,
                    //         icon: './assets/circle-marker.png'
                    //       }
                    //     );
                    //   });
                    // }
                  });
                }
              });
            }
          });
          // console.log('countryCoordinates', this.countryCoordinates);
          // console.log('stateCoordinates', this.stateCoordinates);
          // console.log('cityCoordinates', this.cityCoordinates);
          // console.log('locationCoordinates', this.locationCoordinates);
          this.radiousArea = this.countryCoordinates;
          this.markers = this.countryMarkers;
        }
        else {
          this.common.openSnackBar('Data not save please try later', '');
        }
      },
      err => {
        console.log("ERROR");
        console.log(err);
      }
    );
  }


  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.radiusLat = this.latitude;
        this.radiusLong = this.longitude;
        this.zoom = 5;

        for (let i = 1; i < 50; i++) {
          this.markers.push(
            {
              lat: this.latitude + Math.random(),
              lng: this.longitude + Math.random(),
              label: `${i}`,
              draggable: false,
              content: `Content no ${i}`,
              isShown: false,
              icon: './assets/marker-red.png'
            }
          );
        }

      });
    }
  }

  // clickedMarker(label: string, index: number) {
  //   console.log(`clicked the marker: ${label || index}`)
  //   console.log('clicked the marker:', index)
  // }
  mouseOver(event){
    let marker_title = this.getAnchorDetails(event);
    // console.log('Over the marker:', event);
    // console.log('user_anchor_id', this.anchorList.length);
    // console.log('marker_title', marker_title);
    this.cityCoordinates.forEach(item => {
      item.markers.forEach(element => {
        // console.log('marker id:', element.anchorid)
        if (event == element.anchorid) {
          // console.log('marker id:', element.anchorid)
          element.title = marker_title;
        }
      });
    });
  }

  clickedMarker(event) {
    let that = this;
    console.log('clicked the marker:', event)
    if (event != 0) {
      const index = this.zoneAnchorArray.indexOf(event);
      if (index > -1) {
        this.cityCoordinates.forEach(item => {
          item.markers.forEach(element => {
            console.log('marker id:', element.anchorid)
            if (event == element.anchorid) {
              console.log('marker id:', element.anchorid)
              element.icon = '';
            }
          });
        });
        this.zoneAnchorArray.splice(index, 1);
      } else {
        this.cityCoordinates.forEach(item => {
          item.markers.forEach(element => {
            console.log('marker id:', element.anchorid)
            if (event == element.anchorid) {
              console.log('marker id:', element.anchorid)
              element.icon = 'assets/images/marker.svg';
            }
          });
        });
        this.zoneAnchorArray.push(event);
      }
    }
    // console.log('zone_anchor_array:', this.zoneAnchorArray);
  }

  radiusDragEnd($event: any) {
    console.log($event);
    this.radiusLat = $event.coords.lat;
    this.radiusLong = $event.coords.lng;
    this.showHideMarkers();
  }

  event(type, $event) {
    console.log(type, $event);
    this.radius = $event;
    this.showHideMarkers();
  }

  showHideMarkers() {
    Object.values(this.markers).forEach(value => {
      value.isShown = this.getDistanceBetween(value.lat, value.lng, this.radiusLat, this.radiusLong);
    });
  }

  getDistanceBetween(lat1, long1, lat2, long2) {
    var from = new google.maps.LatLng(lat1, long1);
    var to = new google.maps.LatLng(lat2, long2);

    if (google.maps.geometry.spherical.computeDistanceBetween(from, to) <= this.radius) {
      console.log('Radius', this.radius);
      console.log('Distance Between', google.maps.geometry.spherical.computeDistanceBetween(
        from, to
      ));
      return true;
    } else {
      return false;
    }
  }

  distance(lat1, lon1, lat2, lon2) {
    var p = 0.017453292519943295;    // Math.PI / 180
    var c = Math.cos;
    var a = 0.5 - c((lat2 - lat1) * p) / 2 +
      c(lat1 * p) * c(lat2 * p) *
      (1 - c((lon2 - lon1) * p)) / 2;

    return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
  }

  circleClick(evant) {
    // console.log('clicked the Circle', evant)
    if (this.zoom == 8) {
      this.markers = [];
      let that = this;
      this.radius = 0;
      this.radiousArea = this.cityCoordinates;
      this.cityCoordinates.forEach(item => {
        console.log('locationItem', item);
        item.anchor_details.forEach(element => {
          console.log('element', element);
          that.markers.push({
            lat: element.anchor_latitude,
            lng: element.anchor_longitude,
            label: element.anchor_id,
            draggable: false,
            content: element.anchor_id,
            isShown: true,
            icon: ''
          }
          );
        });
      });
      // console.log('locationItem', this.cityCoordinates);
    }
  }

  onZoomChange(newZoomValue) {
    this.radiousArea = [];
    this.markers = [];
    this.zoom = newZoomValue;
    if (this.zoom == 5) {
      this.radius = 700000;
      this.radiousArea = this.countryCoordinates;
      this.markers = this.countryMarkers;
    }
    if (this.zoom == 6) {
      this.radius = 125000;
      this.radiousArea = this.stateCoordinates;
      this.markers = this.stateMarkers;
    }
    if (this.zoom == 7) {
      this.radius = 80000;
      this.radiousArea = this.cityCoordinates;
      this.markers = this.cityMarkers;
    }
    // if (this.zoom == 7) {
    //   this.radius = 100000;
    //   this.radiousArea = this.locationCoordinates;
    //   this.markers = this.locationMarkers;
    // }
    if (this.zoom == 8) {
      this.radius = 0;
      this.radiousArea = this.cityCoordinates;
      this.markers = this.cityMarkers;
    }
    if (this.zoom == 9) {
      this.radius = 0;
      this.radiousArea = this.cityCoordinates;
      this.markers = this.cityMarkers;
    }
    if (this.zoom == 10) {
      this.radius = 0;
      this.radiousArea = this.cityCoordinates;
      this.markers = this.cityMarkers;
    }
    if (this.zoom == 11) {
      this.radius = 0;
      this.radiousArea = this.cityCoordinates;
      this.markers = this.cityMarkers;
    }
    if (this.zoom == 12) {
      this.radius = 0;
      this.radiousArea = this.cityCoordinates;
      this.markers = this.cityMarkers;
    }

    // console.log('newZoomValue', this.zoom);
    // console.log('countryCoordinates', this.countryCoordinates);
    // console.log('stateCoordinates', this.stateCoordinates);
    // console.log('cityCoordinates', this.cityCoordinates);
    // console.log('locationCoordinates', this.locationCoordinates);
    // this.radiousArea = this.countryCoordinates;
  }

  dialogRefTags: MatDialogRef<AddEditZoneComponent> | null;
  openAddZonePopUp() {
    console.log('Anchor Map');
    if (this.zoneAnchorArray.length > 0) {
      if (this.zoneAnchorArray.length > 15) {
        alert('You can select only 15 anchors.')
      } else {
        this.dialogRefTags = this.dialog.open(AddEditZoneComponent, {
          data: {
            id: 0,
            selectedZone: this.zoneAnchorArray
          },
          disableClose: false,
        });
        this.dialogRefTags.afterClosed().subscribe(result => {
          console.log('afterClosed', result);
          if (result == 'success') {
            this.router.navigate(['/zone/zone-list/']);
          }
        });
      }
    } else {
      alert('Please select atlist one anchor.')
    }
  }

  getAnchorDetails(user_anchor_id){
    let title_string = '';
    if(this.anchorList.length > 0){
      this.anchorList.forEach(element => {
        if(user_anchor_id == element.id){
          title_string = 'Name: ' + element.anchor_details[0].anchor_name + ' Location: ' + element.location;
        }
      });
    }
    // console.log('title_string', title_string);
    return title_string;
  }

  goTo(){
    this.router.navigate(['/zone/zone-list']);
  }

  saveZone() {
    console.log('addZoneSetup', this.addZoneSetup);
    // if(this.addZoneSetup.valid){
    //   const formData = {
    //       zone_name: this.addZoneSetup.value.zoneName,
    //       zone_country: this.addZoneSetup.value.zoneCountry,
    //       zone_state: this.addZoneSetup.value.zoneState,
    //       zone_area: this.addZoneSetup.value.zoneArea
    //   }
    //   if(this.zoneId == 0){ // For add section
    //     this.accessibilityservice.addZone(formData).subscribe(
    //       res => {
    //         if(res['status'] == 1){
    //           // this.closePopup();
    //         }
    //         else{
    //           this.common.openSnackBar('Data not save please try later', '');
    //         }
    //       },
    //       err => {
    //         console.log("ERROR");
    //         console.log(err);
    //       }
    //     );
    //   }
    //   else{ // For edit section
    //     formData['zone_id'] = this.zoneId;
    //     this.accessibilityservice.updateZone(formData).subscribe(
    //       res => {
    //         if(res['status'] == 1){
    //           // this.closePopup();
    //         }
    //         else{
    //           this.common.openSnackBar('Data not save please try later', '');
    //         }
    //       },
    //       err => {
    //         console.log("ERROR");
    //         console.log(err);
    //       }
    //     );
    //   }

    // }
  }

  // closePopup() {
  //   this.dialogRef.close();
  // }

}
