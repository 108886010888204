import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatRadioChange } from '@angular/material';
import { AnchorService } from '../services/anchor/anchor.service';
import { CommonService } from '../services/common/common.service';
import { ExecutionSetupComponent } from '../anchor/execution-setup/execution-setup.component';
import { PeriodicQueryComponent } from '../anchor/periodic-query/periodic-query.component';
import { ExecutionsComponent } from './executions/executions.component';
import { TracerouteExecutionComponent } from './traceroute_execution/traceroute-execution/traceroute-execution.component'
import { DnsQueryExecutionComponent } from './dns-query-execution/dns-query-execution.component';
@Component({
  selector: 'app-commend',
  templateUrl: './commend.component.html',
  styleUrls: ['./commend.component.css']
})
export class CommendComponent implements OnInit {
  userdetails: any;
  checkboxChecked: boolean = false;
  locationArr: any = [];
  commendArr: any = [];
  payloadArr: any = [];
  commendList: any = [
    {
      "id":1,
      "version": "0.0",
      "name": "Ping",
      "description": "Uses ICMP protocol to measure latency of Domains/IP",
      "action": false
    },
    {
      "id":2,
      "version": "0.0",
      "name": "Traceroute",
      "description": "These tools trace the paths data packets take from their source to their destinations, allowing administrators to better resolve connectivity issues.",
      "action": false
    }
  ];
  constructor(
    private common: CommonService,
    private anchor: AnchorService,
    private router: Router,
    private dialog: MatDialog
  ) { 
    let userData = JSON.parse(localStorage.getItem('userLoginData'));
    if(userData != '' || userData !=undefined){
      this.userdetails = userData;
      console.log(this.userdetails);
    }
  }

  ngOnInit() {
    console.log(this.commendList);
    // this.executeCommendSetup(1);
    // this.getLocation();
    this.getCommendList();
    this.getPayload();
  }

  // getLocation(){
  //   let res = this.anchor.getAllLocationDetails();
  //   this.locationArr = res['data'];
  //   console.log('Location', this.locationArr);
  // }

  getCommendList(){
    this.commendList = [];
    this.anchor.getAllCommendList().subscribe(
      res => {
        if (res['status'] == 1) {
          this.commendList = res['data'];
          // let i = 0;
          // if(this.userdetails['is_superuser'] == true){
          //   this.commendList = res['data'];
          // }else{
          //   res['data'].forEach(element => {
          //     console.log(i);
          //     if(i < 2){
          //       this.commendList.push(element);
          //     }
          //     i++;
          //   });
          // }
          // this.commendList = res['data'];
          console.log(this.commendList);
        }
        if (res['status'] == 0) {
          this.commendList = [];
        }
      },
      err => {
        console.log("ERROR");
        console.log(err);
        this.common.openSnackBar('Please try later', '');
      }
    );
    console.log('getAllCommendList', this.commendArr);
  }

  getPayload(){
    let res = this.anchor.getPayloadQuerySet();
    this.payloadArr = res['data'];
    console.log('getPayloadQuerySet', this.payloadArr);
  }

  toggleCheckbox(event) {
    // console.log(event);
		// if ( event.checked ) {
    //     this.checkboxChecked = true;
   	// 	} else{
   	// 		this.checkboxChecked = false;
   	// 	}
    this.commendList.forEach(element => {
      if(element.id == event.value){
        element.action = true;
      }else{
        element.action = false;
      }
      console.log(element);
    });
  }
  
  dialogRefTags : MatDialogRef<ExecutionCommendMassageComponent> | null;
  dialogRefTagsSetup : MatDialogRef<ExecutionSetupComponent> | null;
  dialogRefTagsPeriodic : MatDialogRef<PeriodicQueryComponent> | null;
  dialogRefTagsQueryType : MatDialogRef<QuerySelectionComponent> | null;
  dialogRefTagsQueryExecutions : MatDialogRef<ExecutionsComponent> | null;
  dialogRefTagsTracerouteExecution : MatDialogRef<TracerouteExecutionComponent> | null;
  dialogRefTagsDnsQueryExecution : MatDialogRef<DnsQueryExecutionComponent> | null;
  
  // executeCommend(list_id):void{
  //   console.log('Commend List ID', list_id);
  //   this.dialogRefTags = this.dialog.open(ExecutionCommendMassageComponent, {
  //     data: {
  //       popText:'Please wait commend execution has been started when it will completed you will get result.',
  //     },
  //       disableClose: true
  //     });

  //     this.dialogRefTags.afterClosed().subscribe(result => {
  //       console.log('afterClosed', result);
  //       this.router.navigate(['/analytics']);
  //     });
  // }

  // selectQueryType(list_name){
  //   this.dialogRefTagsQueryType = this.dialog.open(QuerySelectionComponent, {
  //     data: {
  //       commend: list_name
  //     },
  //       disableClose: true
  //     });

  //     this.dialogRefTagsQueryType.afterClosed().subscribe(result => {
  //       console.log('afterClosed', result);
  //       if(result != undefined){
  //         if(result == 'regular'){
  //           this.executeCommendSetup(list_name);
  //         }
  //         if(result == 'periodic'){
  //           this.executePeriodicSetup(list_name);
  //         }
  //       }
  //     });
  // }

  selectQueryType(selectQueryType){
    console.log(selectQueryType)
    if(selectQueryType == 'Ping'){
      this.pinExecution(selectQueryType);
    }
    if(selectQueryType == 'Traceroute'){
      this.tracerouteExecution(selectQueryType);
      // console.log(selectQueryType);
    }
    if(selectQueryType == 'DNS Query'){
      this.dnsQueryExecution(selectQueryType);
      // console.log(selectQueryType);
    }
  }

  // selectQueryType(list_name){
  //   console.log(list_name)
  //   this.dialogRefTagsQueryExecutions = this.dialog.open(ExecutionsComponent, {
  //     height: '400px',
  //     width: '600px',
  //     data: {
  //       commend: list_name
  //     },
  //       disableClose: true
  //     });
      
  //     this.dialogRefTagsQueryExecutions.afterClosed().subscribe(result => {
  //       console.log('afterClosed', result);
  //       // this.router.navigate(['/analytics']);
  //       if(result['execution'] == "success"){
  //         this.dialogRefTags = this.dialog.open(ExecutionCommendMassageComponent, {
  //           data: {
  //             popText:'Please wait, We are processing your query.',
  //             qtype: result['type']
  //           },
  //             disableClose: true
  //           });
    
  //           this.dialogRefTags.afterClosed().subscribe(result => {
  //             console.log('Massage Popup AfterClosed', result);
  //             // this.router.navigate(['/analytics']);
  //             if(result == 'regular'){
  //               this.router.navigate(['/query/executed-history/regular/']);
  //             }else{
  //               this.router.navigate(['/query/executed-history/periodic/']);
  //             }
  //           });
  //       }
  //     });
  //     // this.dialogRefTagsQueryType.afterClosed().subscribe(result => {
  //     //   console.log('afterClosed', result);
  //     //   if(result != undefined){
  //     //     if(result == 'regular'){
  //     //       this.executeCommendSetup(list_name);
  //     //     }
  //     //     if(result == 'periodic'){
  //     //       this.executePeriodicSetup(list_name);
  //     //     }
  //     //   }
  //     // });
  // }

  pinExecution(list_name){
    console.log(list_name)
    this.dialogRefTagsQueryExecutions = this.dialog.open(ExecutionsComponent, {
      //height: '600px',
      width: '500px',
      data: {
        commend: list_name
      },
        disableClose: true
    });
      
    this.dialogRefTagsQueryExecutions.afterClosed().subscribe(result => {
      console.log('afterClosed', result);
      // this.router.navigate(['/analytics']);
      if(result['execution'] == "success"){
        this.dialogRefTags = this.dialog.open(ExecutionCommendMassageComponent, {
          data: {
            popText:'Please wait, We are processing your query.',
            qtype: result['type']
          },
            disableClose: true
          });
  
          this.dialogRefTags.afterClosed().subscribe(result => {
            console.log('Massage Popup AfterClosed', result);
            // this.router.navigate(['/analytics']);
            if(result == 'regular'){
              this.router.navigate(['/query/executed-history/regular/']);
            }else{
              this.router.navigate(['/query/executed-history/periodic/']);
            }
          });
      }
    });
  }

  tracerouteExecution(list_name){
    console.log(list_name)
    this.dialogRefTagsTracerouteExecution = this.dialog.open(TracerouteExecutionComponent, {
      width: '500px',
      data: {
        commend: list_name
      },
        disableClose: true
    });

    this.dialogRefTagsTracerouteExecution.afterClosed().subscribe(result => {
      console.log('afterClosed', result);
      // this.router.navigate(['/analytics']);
      if(result['execution'] == "success"){
        this.dialogRefTags = this.dialog.open(ExecutionCommendMassageComponent, {
          data: {
            popText:'Please wait, We are processing your query.',
            qtype: result['type']
          },
            disableClose: true
          });
  
          this.dialogRefTags.afterClosed().subscribe(result => {
            console.log('Massage Popup AfterClosed', result);
            this.router.navigate(['/query/executed-history/traceroute/']);
          });
      }
    });
  }

  dnsQueryExecution(list_name){
    console.log(list_name)
    this.dialogRefTagsDnsQueryExecution = this.dialog.open(DnsQueryExecutionComponent, {
      width: '500px',
      data: {
        commend: list_name
      },
        disableClose: true
    });

    this.dialogRefTagsDnsQueryExecution.afterClosed().subscribe(result => {
      console.log('afterClosed', result);
      // this.router.navigate(['/analytics']);
      if(result['execution'] == "success"){
        this.dialogRefTags = this.dialog.open(ExecutionCommendMassageComponent, {
          data: {
            popText:'Please wait, We are processing your query.',
            qtype: result['type']
          },
            disableClose: true
          });
  
          this.dialogRefTags.afterClosed().subscribe(result => {
            console.log('Massage Popup AfterClosed', result);
            this.router.navigate(['/query/executed-history/dnsquery/']);
          });
      }
    });
  }

  executeCommendSetup(list_name){
    this.dialogRefTagsSetup = this.dialog.open(ExecutionSetupComponent, {
      data: {
        commend: list_name
      },
        disableClose: true
      });

      this.dialogRefTagsSetup.afterClosed().subscribe(result => {
        console.log('afterClosed', result);
        // this.router.navigate(['/analytics']);
        if(result == true){
          this.dialogRefTags = this.dialog.open(ExecutionCommendMassageComponent, {
            data: {
              popText:'Please wait, We are processing your query.',
            },
              disableClose: true
            });
    
            this.dialogRefTags.afterClosed().subscribe(result => {
              console.log('afterClosed', result, list_name);
              // this.router.navigate(['/analytics']);
              this.router.navigate(['/query/executed-history/regular/']);
            });
        }
      });
  }

  executePeriodicSetup(list_name){
    this.dialogRefTagsPeriodic = this.dialog.open(PeriodicQueryComponent, {
      data: {
        commend: list_name
      },
        disableClose: true
      });

      this.dialogRefTagsPeriodic.afterClosed().subscribe(result => {
        console.log('afterClosed', result);
        // this.router.navigate(['/analytics']);
        if(result == true){
          this.dialogRefTags = this.dialog.open(ExecutionCommendMassageComponent, {
            data: {
              popText:'Please wait, We are processing your query.',
            },
              disableClose: true
            });
    
            this.dialogRefTags.afterClosed().subscribe(result => {
              console.log('afterClosed', result);
              // this.router.navigate(['/analytics']);
              this.router.navigate(['/query/executed-history/periodic/']);
            });
        }
      });
  }

}

@Component({
  templateUrl: '../popup-template/query-selection.component.html'
  })
  export class QuerySelectionComponent implements OnInit {
    radioArray: any;
    radioValue: string;
    constructor(
        public dialogRefquery: MatDialogRef<QuerySelectionComponent>, @Inject(MAT_DIALOG_DATA) 
        public userData:any, 
        private dialog: MatDialog,
        private ar: ActivatedRoute, 
        private router: Router
      ) {}
  
    ngOnInit() {
      // this.radioArray=[
      //   {name:'Regular Query', value:'regular'},
      //   {name:'Periodic Query', value:'periodic'},
      //   {name:'Constant Query', value:'constant'}
      // ];

      this.radioArray=[
        {name:'Regular Query', value:'regular'},
        {name:'Periodic Query', value:'periodic'}
      ];
      
    }
    
    radioChange($event: MatRadioChange) {
      console.log($event.source.name, $event.value);
      this.radioValue = $event.value;
      // if ($event.source.name === 'radioOpt1') {
      //     // Do whatever you want here
      // }
    }

    redirectTo(){
      this.dialogRefquery.close(this.radioValue);
    }

    closePopup() {
      this.dialogRefquery.close();
    }
  }

@Component({
	templateUrl: '../popup-template/commend-execution-massage.component.html'
  })
  export class ExecutionCommendMassageComponent implements OnInit {
    progressBerShow: boolean = true;
    resultBtnShow: boolean = false;
    queryType: any;
    qtext: any;
		constructor(
			  public dialogRef: MatDialogRef<ExecutionCommendMassageComponent>, @Inject(MAT_DIALOG_DATA) 
			  public userData:any, 
			  private dialog: MatDialog,
		  	private ar: ActivatedRoute, 
		  	private router: Router
		  ) {
        console.log('Query Type', this.userData);
        this.queryType = this.userData['qtype'];
      }
  
		ngOnInit() {
      setInterval(() => {
        this.progressBerShow = false;
        this.resultBtnShow = true;
      }, 10000);
    }
    
    returnResult(){
      this.dialogRef.close(this.queryType);
    }
	  // closePop() {
   //    this.dialogRef.close(this.objdata);
   //  }
  }
