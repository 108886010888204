import { NgModule } from '@angular/core';
import { ModuleWithProviders} from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ExecutedCommendHistoryComponent } from './executed-commend-history.component';

const HISTORY_ROUTER: Routes = [
  {
    path: '', 
    component: ExecutedCommendHistoryComponent,
    children: [
      { path:'executed-history/:historytype', component: ExecutedCommendHistoryComponent },
    ]
  },
];

export const CommandHistoryRouting: ModuleWithProviders = RouterModule.forChild(HISTORY_ROUTER);