import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { ConfigurationService } from '../services/config/configuration.service';
import { CommandRouting } from './command-routing.module';
import { HttpModule } from '@angular/http';
import { HttpClient, HttpHeaders, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { AgmCoreModule } from '@agm/core';
import { CommendComponent, ExecutionCommendMassageComponent, QuerySelectionComponent } from './commend.component';
import { ExecutionSetupComponent } from '../anchor/execution-setup/execution-setup.component';
import { PeriodicQueryComponent } from '../anchor/periodic-query/periodic-query.component';
import { ExecutionsComponent } from './executions/executions.component';
import { TracerouteExecutionComponent } from './traceroute_execution/traceroute-execution/traceroute-execution.component';
import { DnsQueryExecutionComponent } from './dns-query-execution/dns-query-execution.component';
import { CreateZoneComponent } from './create-zone/create-zone.component';
// import { MapviewanchorbyzonepopupComponent } from '../zone/mapviewanchorbyzonepopup/mapviewanchorbyzonepopup.component';
// import { AddEditDomainComponent } from '../domain/add-edit-domain.component';
@NgModule({
  declarations: [CommendComponent, ExecutionSetupComponent, PeriodicQueryComponent, ExecutionCommendMassageComponent, QuerySelectionComponent, ExecutionsComponent, TracerouteExecutionComponent, DnsQueryExecutionComponent, CreateZoneComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    HttpModule,
    HttpClientModule,
    CommandRouting,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAXxByh8R5Ouwe7ouvoqcgdlMXSytHdX6I',
      libraries: ['places', 'drawing', 'geometry', 'map']
      })
  ],
  entryComponents: [
    ExecutionCommendMassageComponent,
		QuerySelectionComponent,
		PeriodicQueryComponent,
		ExecutionSetupComponent,
    ExecutionsComponent,
    TracerouteExecutionComponent,
    DnsQueryExecutionComponent,
    CreateZoneComponent
    // MapviewanchorbyzonepopupComponent
  ],
	providers: [
		ConfigurationService,
		// DatePipe,
		CookieService,
	]
})
export class CommandModule { }
