import { Component, OnInit, Inject } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl, FormArray } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../services/common/common.service';
import { AccessibilityService } from '../services/accessibility/accessibility.service';
import { UserService } from '../services/user/user.service';
import { AddEditDomainComponent } from '../domain/add-edit-domain.component';

@Component({
  selector: 'app-add-edit-host-group',
  templateUrl: './add-edit-host-group.component.html',
  styleUrls: ['./add-edit-host-group.component.css']
})
export class AddEditHostGroupComponent implements OnInit {
  addHostGroupSetup: FormGroup;
  hostgroupName = new FormControl('', [Validators.required]);
  domainIds = new FormControl('', [Validators.required]);
  hostGroup_id: number = 0;
  pageTitle: string = 'Add Service'
  buttonText: string = 'Save'
  domainIdserror: boolean = false;
  domainList: any;
  constructor(
    _formbuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddEditHostGroupComponent>, @Inject(MAT_DIALOG_DATA) 
    public hostGroupData:any,
		private dialog: MatDialog,
		private ar: ActivatedRoute, 
    private router: Router,
    private common: CommonService,
    private accessibilityservice: AccessibilityService,
    private userservice: UserService
  ) {
    this.addHostGroupSetup = _formbuilder.group({
      hostgroupName: this.hostgroupName,
      domainIds: this.domainIds
      // domainIds:  new FormArray([])
    });

    this.hostGroup_id = + hostGroupData.id;
    if(this.hostGroup_id != 0){
      this.pageTitle = 'Edit Service'
      this.buttonText = 'Update'
    }
   }

  ngOnInit() {
    this.getDomainList();
    if(this.hostGroup_id != 0){
      const formData = {
        host_group_id: this.hostGroup_id
      }
      this.accessibilityservice.getHostGroupDetails(formData).subscribe(
        res => {
          if(res['status'] == 1){
            let getArr = [];
            if(res['hostgroup'][0]['domain_details'].length > 0){
              res['hostgroup'][0]['domain_details'].forEach(element => {
                getArr.push(element.domain_id);
              });
            }
            // Set Value modal field
            this.addHostGroupSetup.setValue({
              hostgroupName: res['hostgroup'][0]['host_group_name'],
              domainIds: getArr
            });
          }
          else{
            this.common.openSnackBar('Host group details faled to lode', '');
          }
        },
        err => {
          console.log("ERROR");
          console.log(err);
          this.common.openSnackBar('please try later', '');
        }
      );
    }
  }

  getDomainList(){
    this.domainList = [];
    this.userservice.domainList().subscribe(
      res => {
        if(res['status'] == 1){
          this.domainList = res['domain'];
        }
        else{
          this.common.openSnackBar('Domain details faled to lode', '');
        }
      },
      err => {
        console.log("ERROR");
        console.log(err);
        this.common.openSnackBar('please try later', '');
      }
    );
  }

  onCheckChange(event) {
    const formArray: FormArray = this.addHostGroupSetup.get('domainIds') as FormArray;
  
    /* Selected */
    if(event.checked){
      // Add a new control in the arrayForm
      formArray.push(new FormControl(event.source.value));
    }
    /* unselected */
    else{
      // find the unselected element
      let i: number = 0;
      console.log('formArray', formArray)
      formArray.controls.forEach((ctrl: FormControl) => {
        if(ctrl.value == event.source.value) {
          // Remove the unselected element from the arrayForm
          formArray.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  saveHostGroup(){
    this.domainIdserror = false;
    if(this.addHostGroupSetup.valid){
      const formData = {
        host_group_name: this.addHostGroupSetup.value.hostgroupName,
        domain_ids: this.addHostGroupSetup.value.domainIds
      }
      if(this.hostGroup_id == 0){ // For add section
        this.accessibilityservice.addHostGroup(formData).subscribe(
          res => {
            if(res['status'] == 1){
              this.closePopup();
            }
            else{
              this.common.openSnackBar('Service is not save.', '');
            }
          },
          err => {
            console.log("ERROR");
            console.log(err);
            this.common.openSnackBar('please try later', '');
          }
        );
      }
      else{ // for edit section
        // Update const variable
        formData['id'] = this.hostGroup_id;
        this.accessibilityservice.updateHostGroup(formData).subscribe(
          res => {
            if(res['status'] == 1){
              this.closePopup();
            }
            else{
              this.common.openSnackBar('Service is not save.', '');
            }
          },
          err => {
            console.log("ERROR");
            console.log(err);
            this.common.openSnackBar('please try later', '');
          }
        );
      }
    }
    // console.log('Check Form', this.addHostGroupSetup);
  }

  dialogRefTags : MatDialogRef<AddEditDomainComponent> | null;
  goToDomainList():void{
    // this.closePopup();
    this.dialogRefTags = this.dialog.open(AddEditDomainComponent, {
      data: {
        id: 0
      },
        disableClose: true
      });
      this.dialogRefTags.afterClosed().subscribe(result => {
        console.log('afterClosed', result);
        this.getDomainList();
      });
    // this.router.navigate(['/domain-list']);
  }

  closePopup() {
    this.dialogRef.close();
  }

}
