import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { ConfigurationService } from '../services/config/configuration.service';
import { PeriodicRouting } from './periodic-routing.module';
import { DatePipe} from '@angular/common';
import { HttpModule } from '@angular/http';
import { HttpClient, HttpHeaders, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { PeriodicAnalyticsComponent } from './periodic-analytics.component';
import { PeriodicAnalyticsDetailsComponent } from './periodic-analytics-details.component';
import { PeriodicDomainAnchorAnalyticsComponent } from './periodic-domain-anchor-analytics/periodic-domain-anchor-analytics.component';
import { PeriodicDomainZoneAnalyticsComponent } from './periodic-domain-zone-analytics/periodic-domain-zone-analytics.component';
import { RegularDomainZoneAnalyticsComponent } from './regular-domain-zone-analytics/regular-domain-zone-analytics.component';

@NgModule({
  declarations: [PeriodicAnalyticsComponent, PeriodicAnalyticsDetailsComponent, PeriodicDomainAnchorAnalyticsComponent, PeriodicDomainZoneAnalyticsComponent, RegularDomainZoneAnalyticsComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    HttpModule,
    HttpClientModule,
    PeriodicRouting
  ],
  entryComponents: [
    PeriodicAnalyticsDetailsComponent,
    RegularDomainZoneAnalyticsComponent
  ],
	providers: [
		ConfigurationService,
		// DatePipe,
		CookieService,
    DatePipe

	]
  
})
export class PeriodicModule { }
