import { NgModule } from '@angular/core';
import { ModuleWithProviders} from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PeriodicAnalyticsComponent } from './periodic-analytics.component';
// import { PeriodicAnalyticsDetailsComponent } from './periodic-analytics-details.component';
import { PeriodicDomainAnchorAnalyticsComponent } from './periodic-domain-anchor-analytics/periodic-domain-anchor-analytics.component';
import { PeriodicDomainZoneAnalyticsComponent } from './periodic-domain-zone-analytics/periodic-domain-zone-analytics.component';

const PERIODIC_ROUTER: Routes = [
  {
    path: '', 
    component: PeriodicAnalyticsComponent,
    // pathMatch: 'full',
    children: [
      { path:'periodic-analytics/:tokenid', component: PeriodicAnalyticsComponent, pathMatch: 'full' }
    ]
  },
  {
    path: 'query/periodic-domain-analytics/:tokenid', 
    component: PeriodicDomainAnchorAnalyticsComponent, 
    pathMatch: 'full', 
    children: [
      { path:'periodic-domain-analytics/:tokenid', component: PeriodicDomainAnchorAnalyticsComponent },
    ]
  },
  {
    path: 'query/periodic-domain-zone-analytics/:tokenid', 
    component: PeriodicDomainZoneAnalyticsComponent, 
    pathMatch: 'full', 
    children: [
      { path:'periodic-domain-zone-analytics/:tokenid', component: PeriodicDomainZoneAnalyticsComponent },
    ]
  },

];

export const PeriodicRouting: ModuleWithProviders = RouterModule.forChild(PERIODIC_ROUTER);