import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpClientModule, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { ConfigurationService } from '../config/configuration.service';
import { CommonService } from '../common/common.service'
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AnchorService {
  private actionUrl: string;
  private aiorUrl: string;
  private anchorRequestUrl: string;
  private location: any = {"data":[{"id":1,"country":"India","location":"kolkata","lat":"22.5726° N","long":"88.3639° E"},{"id":1,"country":"India","location":"mumby","lat":"19.0760° N","long":"72.8777° E"}]}
  private commend: any = {"data":[{"id":1,"name":"Ping","commend_key":"pin538exe903"}]};
  private pullquery: any = {"data":[{"status":"request","version":"0.0","cached":false,"see_also":[],"time":"2020-04-15T06:19:42.499628","messages":[],"query_id":"20200408061941-0705c491-fb28-47fe-bdc8-b664000141cf",}]};
  
  public downloadCsvCompleteObservable = new Subject<any>();
  public downloadCsvCallBackResult$ = this.downloadCsvCompleteObservable.asObservable();

  constructor( 
    private http: HttpClient, 
	private _configuration: ConfigurationService, 
    private router: Router,
    private common: CommonService
  ) {
	this.actionUrl = _configuration.ServerWithApiUrl;
	this.aiorUrl = _configuration.AIORAnchorUrl;
	// this.anchorRequestUrl = _configuration.AIORIAnchorRequestUrl;
   }

   /**
	 * Return host details from JSON
	 *
	 * @param -- any
	 *
	 * @return Observable
	 */

	public sampleGraphData(): Observable<any> {
		return this.http.get("/assets/sample_json/graph.json");
	}

    /**
	 * Return host details from JSON
	 *
	 * @param -- any
	 *
	 * @return Observable
	 */

	public getHost(): Observable<any> {
		// return this.http.get("/assets/sample_json/host_list.json");
		let authHeader = this.common.getHttpOptionWithLogin();
		return this.http.get(this.actionUrl + "user/user-domain/",  authHeader);
	}

	/**
	 * Return anchor public ip
	 *
	 * @param -- any
	 *
	 * @return Observable
	 */
	 getAnchorPublicIPFromAIRO(anchor_id): Observable<any>{
		let aioriAuthHeader = this.common.headerApiKeyConfig();
		return this.http.get(this.aiorUrl + 'anchor/pubip/?id='+ anchor_id, aioriAuthHeader);
	}

   /**
	 * Return location details
	 *
	 * @param -- any
	 *
	 * @return Observable
	 */
	getCountryFromAIRO(): Observable<any>{
		let aioriAuthHeader = this.common.headerApiKeyConfig();
		// let headers = new HttpHeaders();
		// headers = headers.append('Content-Type', 'application/json');
		// return this.http.get('http://192.168.100.121:8090/apisrv/api/v1/region/?limit=100')
		return this.http.get(this.aiorUrl + 'region/?limit=100', aioriAuthHeader);
	}

	/**
	 * Return state details
	 *
	 * @param -- any
	 *
	 * @return Observable
	 */
	getStatesFromAIRO(country_code: string): Observable<any>{
		let aioriAuthHeader = this.common.headerApiKeyConfig();
		// return this.http.get('http://192.168.100.121:8090/apisrv/api/v1/region/s/?name=IN')
		return this.http.get(this.aiorUrl + 'region/s/?name=' + country_code, aioriAuthHeader);
	}

	/**
	 * Return region details
	 *
	 * @param -- any
	 *
	 * @return Observable
	 */
	getRegionFromAIRO(state_name: string): Observable<any>{
		let aioriAuthHeader = this.common.headerApiKeyConfig();
		// return this.http.get('http://192.168.100.121:8090/apisrv/api/v1/region/c/?name=WB')
		return this.http.get(this.aiorUrl + 'region/c/?name=' + state_name, aioriAuthHeader);
	}

	/**
	 * Post payload for execute commend
	 *
	 * @param -- form data in JSON formet
	 *
	 * @return Observable
	 */
	postExecuteCommendToAIRO(reqData: any): Observable<any>{
		let aioriAuthHeader = this.common.headerApiKeyConfig();
		// console.log(aioriAuthHeader)
		// return this.http.get('http://192.168.100.121:8090/apisrv/api/v1/region/c/?name=WB')
		return this.http.post(this.aiorUrl + 'ping/', reqData, aioriAuthHeader);
	}

	/**
	 * Post payload for execute commend for regular commond
	 *
	 * @param -- form data in JSON formet
	 *
	 * @return Observable
	 */
	postExecuteCommendRegularToAIRO(reqData: any, lease_id): Observable<any>{
		let aioriAuthHeader = this.common.headerApiKeyConfig();
		return this.http.post(this.aiorUrl + 'ping/?lease_id='+lease_id, reqData, aioriAuthHeader);
	}

	/**
	 * Post payload for  traceroute commond
	 *
	 * @param -- form data in JSON formet
	 *
	 * @return Observable
	 */
	 postTraceRouteToAIRO(reqData: any, lease_id): Observable<any>{
		let aioriAuthHeader = this.common.headerApiKeyConfig();
		return this.http.post(this.aiorUrl + 'traceroute/?lease_id='+lease_id, reqData, aioriAuthHeader);
	}

	/**
	 * Get traceroute data
	 *
	 * @param -- form data in JSON formet
	 *
	 * @return Observable
	 */
	 getTraceRouteDataFromAIRO(query_id): Observable<any>{
		let aioriAuthHeader = this.common.headerApiKeyConfig();
		return this.http.get(this.aiorUrl + 'traceroute/data/'+query_id, aioriAuthHeader);
	}


	/**
	 * Post payload for  dns query commond
	 *
	 * @param -- form data in JSON formet
	 *
	 * @return Observable
	 */
	postDnsQueryToAIRO(reqData: any, lease_id, query_type): Observable<any>{
		console.log(query_type)
		let aioriAuthHeader = this.common.headerApiKeyConfig();
		if(query_type == 'SOA'){
			return this.http.post(this.aiorUrl + 'dnsq/soa/?lease_id='+lease_id, reqData, aioriAuthHeader);
		}else if(query_type == 'CHAOS'){
			return this.http.post(this.aiorUrl + 'dnsq/chaos/?lease_id='+lease_id, reqData, aioriAuthHeader);
		}else{
			return this.http.post(this.aiorUrl + 'dnsq/?lease_id='+lease_id, reqData, aioriAuthHeader);
		}
	}

	/**
	 * Get dns query data
	 *
	 * @param -- form data in JSON formet
	 *
	 * @return Observable
	 */
	 getDnsQueryDataFromAIRO(query_id, qtype): Observable<any>{
		let aioriAuthHeader = this.common.headerApiKeyConfig();
		if(qtype == 'SOA'){
			return this.http.get(this.aiorUrl + 'dnsq/soa/data/'+query_id, aioriAuthHeader);
		}else if(qtype == 'CHAOS'){
			return this.http.get(this.aiorUrl + 'dnsq/chaos/data/'+query_id, aioriAuthHeader);
		}else{
			return this.http.get(this.aiorUrl + 'dnsq/data/'+query_id, aioriAuthHeader);
		}
	}

	// /**
	//  * Post payload for execute commend with lease id
	//  *
	//  * @param -- form data in JSON formet
	//  *
	//  * @return Observable
	//  */
	// postExecuteCommendWithLeaseToAIRO(reqData: any, lease_id: number): Observable<any>{
	// 	let headers = new HttpHeaders();
	// 	headers = headers.append('Content-Type', 'application/json');
	// 	// return this.http.get('http://192.168.100.121:8090/apisrv/api/v1/region/c/?name=WB')
	// 	return this.http.post(this.anchorRequestUrl + 'ping/?lease_id='+lease_id, reqData, {headers:headers});
	// }

	/**
	 * Post payload for execute commend with lease id
	 *
	 * @param -- form data in JSON formet
	 *
	 * @return Observable
	 */
	postExecuteCommendWithLeaseToAIRO(reqData: any, lease_id: number): Observable<any>{
		let aioriAuthHeader = this.common.headerApiKeyConfig();
		return this.http.post(this.aiorUrl + 'ping/?lease_id='+lease_id, reqData, aioriAuthHeader);
	}

	/**
	 * Post payload for execute commend for testing perpus
	 *
	 * @param -- form data in JSON formet
	 *
	 * @return Observable
	 */
	postExecuteCommendToAIROTest(reqData: any): Observable<any>{
		let authHeader = this.common.getHttpOptionWithLogin();
		return this.http.get(this.actionUrl + "anchor/group-command-history/",  authHeader);
	}

	// /**
	//  * Get anchor request from user to AIORI
	//  *
	//  * @param -- None
	//  *
	//  * @return Observable
	//  */
	// requestAnchorToAIROI(): Observable<any>{
	// 	let headers = new HttpHeaders();
	// 	headers = headers.append('Content-Type', 'application/json');
	// 	return this.http.get(this.anchorRequestUrl + 'anchor/get/', {headers:headers});
	// }

	/**
	 * Get anchor request from user to AIORI
	 *
	 * @param -- None
	 *
	 * @return Observable
	 */
	requestAnchorToAIROI(): Observable<any>{
		let aioriAuthHeader = this.common.headerApiKeyConfig();
		return this.http.get(this.aiorUrl + 'anchor/get/', aioriAuthHeader);
	}

	// /**
	//  * anchor register to AIORI
	//  *
	//  * @param -- None
	//  *
	//  * @return Observable
	//  */
	// registerAnchorToAIROI(reqData): Observable<any>{
	// 	let headers = new HttpHeaders();
	// 	headers = headers.append('Content-Type', 'application/json');
	// 	return this.http.post(this.anchorRequestUrl + 'lease/', reqData, {headers:headers});
	// }

	/**
	 * anchor register to AIORI
	 *
	 * @param -- None
	 *
	 * @return Observable
	 */
	registerAnchorToAIROI(reqData): Observable<any>{
		let aioriAuthHeader = this.common.headerApiKeyConfig();
		return this.http.post(this.aiorUrl + 'lease/', reqData, aioriAuthHeader);
	}

	// /**
	//  * anchor location register to AIORI
	//  *
	//  * @param -- None
	//  *
	//  * @return Observable
	//  */
	// registerAnchorLocationToAIROI(reqData, anchor_id): Observable<any>{
	// 	let headers = new HttpHeaders();
	// 	headers = headers.append('Content-Type', 'application/json');
	// 	return this.http.post(this.anchorRequestUrl + 'anchor/region/?id=' + anchor_id, reqData, {headers:headers});
	// }

	/**
	 * anchor location register to AIORI
	 *
	 * @param -- None
	 *
	 * @return Observable
	 */
	registerAnchorLocationToAIROI(reqData, anchor_id): Observable<any>{
		let aioriAuthHeader = this.common.headerApiKeyConfig();
		return this.http.post(this.aiorUrl + 'anchor/region/?id=' + anchor_id, reqData, aioriAuthHeader);
	}

	// /**
	//  * Get anchor location from AIORI
	//  *
	//  * @param -- anchor_id
	//  *
	//  * @return Observable
	//  */
	// getAnchorLocationToAIROI(anchor_id): Observable<any>{
	// 	let headers = new HttpHeaders();
	// 	headers = headers.append('Content-Type', 'application/json');
	// 	return this.http.get(this.anchorRequestUrl + 'anchor/region/?anchor_id=' + anchor_id, {headers:headers});
	// }

	/**
	 * Get anchor location from AIORI
	 *
	 * @param -- anchor_id
	 *
	 * @return Observable
	 */
	getAnchorLocationToAIROI(anchor_id): Observable<any>{
		let aioriAuthHeader = this.common.headerApiKeyConfig();
		return this.http.get(this.aiorUrl + 'anchor/region/?anchor_id=' + anchor_id, aioriAuthHeader);
	}
  
   /**
	 * Return all commend list
	 *
	 * @param -- any
	 *
	 * @return Observable
	 */

	getAllCommendList(): Observable<any> {
		let authHeader = this.common.getHttpOptionWithLogin();
    	return this.http.get(this.actionUrl + 'anchor/command-name/', authHeader);
  	}

  	/**
	 * Return payload query set
	 *
	 * @param -- any
	 *
	 * @return Observable
	 */

	getPayloadQuerySet(): Observable<any> {
		let authHeader = this.common.getHttpOptionWithLogin();
    // return this.http.post(this.actionUrl + 'personality/', authHeader);
    	return this.pullquery;
	  }
	  
	/**
	 * Save commend execution payload data
	 *
	 * @param -- form data in JSON format
	 *
	 * @return Observable
	 */

	postCommendHistory(reqData): Observable<any> {
		let authHeader = this.common.getHttpOptionWithLogin();
    	return this.http.post(this.actionUrl + 'anchor/command-history/', reqData, authHeader);
	  }
  
	/**
	 * Update commend history
	 *
	 * @param -- formdata
	 *
	 * @return Observable
	 */

	putCommendHistory(reqData): Observable<any> {
		let authHeader = this.common.getHttpOptionWithLogin();
    	return this.http.put(this.actionUrl + 'anchor/command-history/', reqData, authHeader);
	  }

	
	/**
	 * Save commend execution payload data for periodic
	 *
	 * @param -- form data in JSON format
	 *
	 * @return Observable
	 */

	postPeriodicCommendHistory(reqData): Observable<any> {
		let authHeader = this.common.getHttpOptionWithLogin();
    	return this.http.post(this.actionUrl + 'anchor/group-command-history/', reqData, authHeader);
	  }
	
	/**
	 * Save commend execution payload data for domain wise periodic
	 *
	 * @param -- form data in JSON format
	 *
	 * @return Observable
	 */

	 postPeriodicDomainAnchorOrZoneWiseCommendHistory(reqData): Observable<any> {
		let authHeader = this.common.getHttpOptionWithLogin();
    	return this.http.post(this.actionUrl + 'anchor/preiodic-command-history-with-anchor-or-zone/', reqData, authHeader);
	  }

	/**
	 * Save traceroute commend execution payload data for domain wise
	 *
	 * @param -- form data in JSON format
	 *
	 * @return Observable
	 */

	 postTracerouteDomainAnchorOrZoneWiseCommendHistory(reqData): Observable<any> {
		let authHeader = this.common.getHttpOptionWithLogin();
    	return this.http.post(this.actionUrl + 'anchor/traceroute-command-history-with-anchor-or-zone/', reqData, authHeader);
	  }

	/**
	 * Save dnsquery commend execution payload data for domain wise
	 *
	 * @param -- form data in JSON format
	 *
	 * @return Observable
	 */

	 postDnsQueryDomainAnchorOrZoneWiseCommendHistory(reqData): Observable<any> {
		let authHeader = this.common.getHttpOptionWithLogin();
    	return this.http.post(this.actionUrl + 'anchor/dns-query-command-history-with-anchor-or-zone/', reqData, authHeader);
	  }




	/**
	 * Update periodic commend history
	 *
	 * @param -- formdata
	 *
	 * @return Observable
	 */

	putPeriodicCommendHistory(reqData): Observable<any> {
		let authHeader = this.common.getHttpOptionWithLogin();
    	return this.http.put(this.actionUrl + 'anchor/group-command-history/', reqData, authHeader);
	  }
	
	  
	/**
	 * Get commend execution list
	 *
	 * @param -- None
	 *
	 * @return Observable
	 */

	getCommendHistory(): Observable<any> {
		let authHeader = this.common.getHttpOptionWithLogin();
		return this.http.get(this.actionUrl + 'anchor/command-history/', authHeader);
	  }

	/**
	 * Get commend execution details by id
	 *
	 * @param -- hostory id
	 *
	 * @return Observable
	 */

	commendHistoryById(reqData): Observable<any> {
		let authHeader = this.common.getHttpOptionWithLogin();
    	return this.http.post(this.actionUrl + 'anchor/command-history-details/', reqData, authHeader);
	  }

	/**
	 * Get commend execution details by id
	 *
	 * @param -- hostory id
	 *
	 * @return Observable
	 */

	 commendHistoryDetailsById(history_id): Observable<any> {
		let authHeader = this.common.getHttpOptionWithLogin();
    	return this.http.get(this.actionUrl + 'anchor/command-history-details-by_id/'+history_id, authHeader);
	  }


	/**
	 * Get commend execution details by id
	 *
	 * @param -- hostory id
	 *
	 * @return Observable
	 */

	 getTracerouteDetailsByCommandHistoryId(reqData): Observable<any> {
		let authHeader = this.common.getHttpOptionWithLogin();
    	return this.http.post(this.actionUrl + 'anchor/command-history-details-by_id/', reqData, authHeader);
	  }

	/**
	 * Get Traceroute Data
	 *
	 * @param -- hostory id
	 *
	 * @return Observable
	 */

	 getTracerouteDetails(history_id: number): Observable<any> {
		let authHeader = this.common.getHttpOptionWithLogin();
    	return this.http.get(this.actionUrl + 'anchor/traceroute-history-details/'+ history_id + '/', authHeader);
	}

	/**
	 * Get Dns Query Details
	 *
	 * @param -- hostory id
	 *
	 * @return Observable
	 */

	 getDnsQueryDetails(history_id: number): Observable<any> {
		let authHeader = this.common.getHttpOptionWithLogin();
    	return this.http.get(this.actionUrl + 'anchor/dnsquery-history-details/'+ history_id + '/', authHeader);
	}

	/**
	 * Get commend execution details by id
	 *
	 * @param -- hostory id
	 *
	 * @return Observable
	 */

	commendHistoryUpdateById(reqData): Observable<any> {
		let authHeader = this.common.getHttpOptionWithLogin();
    	return this.http.put(this.actionUrl + 'anchor/command-history-details/', reqData, authHeader);
	  }

	/**
	 * Get analytical data 
	 *
	 * @param -- id
	 *
	 * @return Observable
	 */

	getAnalyticalData(reqData): Observable<any> {
		let authHeader = this.common.getHttpOptionWithLogin();
    	return this.http.post(this.actionUrl + 'anchor/command-result/', reqData, authHeader);
	}

	/**
	 * Get analytical data 
	 *
	 * @param -- id
	 *
	 * @return Observable
	 */

	 getAnalyticalDataById(reqData): Observable<any> {
		let authHeader = this.common.getHttpOptionWithLogin();
    	return this.http.post(this.actionUrl + 'anchor/periodic-result-by-id/', reqData, authHeader);
	}

	/**
	 * Get regular zone analytical data 
	 *
	 * @param -- id
	 *
	 * @return Observable
	 */

	 getRegularZoneAnalyticalData(reqData): Observable<any> {
		let authHeader = this.common.getHttpOptionWithLogin();
    	return this.http.post(this.actionUrl + 'anchor/regular-zone-command-result/', reqData, authHeader);
	  }

	/**
	 * Get periodic analytical data 
	 *
	 * @param -- id
	 *
	 * @return Observable
	 */

	getPeriodicAnalyticalData(reqData): Observable<any> {
		let authHeader = this.common.getHttpOptionWithLogin();
    	return this.http.post(this.actionUrl + 'anchor/periodic-command-result/', reqData, authHeader);
	  }

	/**
	 * Get periodic analytical data 
	 *
	 * @param -- id
	 *
	 * @return Observable
	 */

	getPeriodicData(reqData): Observable<any> {
		let authHeader = this.common.getHttpOptionWithLogin();
    	return this.http.post(this.actionUrl + 'anchor/periodic-result/', reqData, authHeader);
	  }

	/**
	 * Get periodic analytical data 
	 *
	 * @param -- id
	 *
	 * @return Observable
	 */

	 getPeriodicDataWithInterval(reqData, method): Observable<any> {
		let authHeader = this.common.getHttpOptionWithLogin();
		console.log('method', method);
		if(method == 'put'){
			return this.http.put(this.actionUrl + 'anchor/periodic-result/', reqData, authHeader);
		}
		if(method == 'post'){
			return this.http.post(this.actionUrl + 'anchor/periodic-result/', reqData, authHeader);
		}
	  }

	/**
	 * Get periodic analytical data group by
	 *
	 * @param -- id, duration in JSON
	 *
	 * @return Observable
	 */

	getPeriodicAnalyticalDataGroupBy(reqData): Observable<any> {
		let authHeader = this.common.getHttpOptionWithLogin();
    	return this.http.post(this.actionUrl + 'anchor/periodic-groupby-result/', reqData, authHeader);
	  }

	/**
	 * Get periodic Zone analytical data 
	 *
	 * @param -- id
	 *
	 * @return Observable
	 */

	 getPeriodicZoneData(reqData): Observable<any> {
		let authHeader = this.common.getHttpOptionWithLogin();
    	return this.http.post(this.actionUrl + 'anchor/zone-periodic-result/', reqData, authHeader);
	  }

	/**
	 * Get periodic zone analytical data group by
	 *
	 * @param -- id, duration in JSON
	 *
	 * @return Observable
	 */

	 getPeriodicZoneAnalyticalDataGroupBy(reqData): Observable<any> {
		let authHeader = this.common.getHttpOptionWithLogin();
    	return this.http.post(this.actionUrl + 'anchor/periodic-zone-groupby-result/', reqData, authHeader);
	  }


	/// Test Perpus
	getPeriodicAnalyticalDataGroupByTest(reqData): Observable<any> {
		let authHeader = this.common.getHttpOptionWithLogin();
    	return this.http.put(this.actionUrl + 'anchor/periodic-groupby-result/', reqData, authHeader);
	  }

	/// Test Perpus
	getDownload(filename: string = null, historyid: number): void {
		let authHeader = this.common.getHttpOptionWithLogin();
		// return this.http.get(this.actionUrl + 'anchor/downloads/', authHeader);
		this.http.get(this.actionUrl + 'anchor/downloads/'+historyid,{responseType: 'blob' as 'json'}).subscribe(
			(response: any) =>{
				let dataType = response.type;
				let binaryData = [];
				binaryData.push(response);
				console.log('Download Data', binaryData);
				console.log('Document', document);
				let downloadLink = document.createElement('a');
				console.log('Download downloadLink', downloadLink);
				downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
				console.log('Download downloadLink HREF', downloadLink.href);
				if (filename)
					this.downloadCsvCompleteObservable.next(true);
					downloadLink.setAttribute('download', filename);
				document.body.appendChild(downloadLink);
				console.log('Download downloadLink HREF', document.body.appendChild(downloadLink));
				downloadLink.click();
			}
		)
	  }

	/**
	 * Utilize points 
	 *
	 * @param -- formData
	 *
	 * @return Observable
	 */

	utilizePoint(reqData): Observable<any> {
		let authHeader = this.common.getHttpOptionWithLogin();
    	return this.http.post(this.actionUrl + 'subscription/point-utilization/', reqData, authHeader);
	  }

	/**
	 * Get anchors
	 *
	 * @param -- None
	 *
	 * @return Observable
	 */
	getAnchor(): Observable<any>{
		let authHeader = this.common.getadminHttpOptionWithLogin();
    	return this.http.get(this.actionUrl + 'anchor/anchor-request/', authHeader);
	}

	/**
	 * Get anchors by id
	 *
	 * @param -- anchor ids array
	 *
	 * @return Observable
	 */
	 getAnchorById(reqData): Observable<any>{
		let authHeader = this.common.getHttpOptionWithLogin();
    	return this.http.post(this.actionUrl + 'anchor/anchor-request/', reqData, authHeader);
	}

	/**
	 * Reset And Update anchor
	 *
	 * @param -- anchor_id
	 *
	 * @return Observable
	 */
	 resetAnchor(reqDara): Observable<any>{
		let authHeader = this.common.getadminHttpOptionWithLogin();
    	return this.http.put(this.actionUrl + 'anchor/anchor-request/', reqDara, authHeader);
	}

	/**
	 * Get user anchors
	 *
	 * @param -- None
	 *
	 * @return Observable
	 */
	getUserAnchors(): Observable<any>{
		let authHeader = this.common.getHttpOptionWithLogin();
    	return this.http.get(this.actionUrl + 'anchor/user-anchor/', authHeader);
	}

	/**
	 * Get all user anchors
	 *
	 * @param -- None
	 *
	 * @return Observable
	 */
	 getAllRegisterAnchors(): Observable<any>{
		let authHeader = this.common.getHttpOptionWithLogin();
    	return this.http.get(this.actionUrl + 'common/anchor-locations/', authHeader);
	}

	/**
	 * Get all user anchors
	 *
	 * @param -- None
	 *
	 * @return Observable
	 */
	 getAnchorsRadiousAria(): Observable<any>{
		let authHeader = this.common.getHttpOptionWithLogin();
    	return this.http.get(this.actionUrl + 'anchor/register-anchor-by-location/', authHeader);
	}

	/**
	 * Get all coordenate tree
	 *
	 * @param -- None
	 *
	 * @return Observable
	 */
	 getCoordinateTree(): Observable<any>{
		let authHeader = this.common.getHttpOptionWithLogin();
    	return this.http.get(this.actionUrl + 'anchor/anchor-map-coordinates/', authHeader);
	}

	/**
	 * Get anchor qr code with lease details
	 *
	 * @param -- None
	 *
	 * @return Observable
	 */
	getQrCodeLease(): Observable<any>{
		let authHeader = this.common.getHttpOptionWithLogin();
    	return this.http.get(this.actionUrl + 'anchor/user-qr-code/', authHeader);
	}

	/**
	 * Save anchor request with qr code
	 *
	 * @param -- QR code in JSON format
	 *
	 * @return Observable
	 */
	saveAnchorRequest(reqData): Observable<any>{
		let authHeader = this.common.getHttpOptionWithLogin();
    	return this.http.post(this.actionUrl + 'anchor/user-qr-code/', reqData, authHeader);
	}

	/**
	 * Save anchor request with qr code
	 *
	 * @param -- QR code in JSON format
	 *
	 * @return Observable
	 */
	saveAnchorLeaseDetails(reqData): Observable<any>{
		let authHeader = this.common.getHttpOptionWithLogin();
    	return this.http.post(this.actionUrl + 'anchor/user-anchor/', reqData, authHeader);
	}

	/**
	 * Update anchor request with qr code
	 *
	 * @param -- fornData JSON format
	 *
	 * @return Observable
	 */
	updateAnchorLeaseDetails(reqData): Observable<any>{
		let authHeader = this.common.getHttpOptionWithLogin();
    	return this.http.put(this.actionUrl + 'anchor/user-anchor/', reqData, authHeader);
	}

	/**
	 * Update anchor location status
	 *
	 * @param -- fornData JSON format
	 *
	 * @return Observable
	 */
	updateAnchorLocationStatus(reqData): Observable<any>{
		let authHeader = this.common.getHttpOptionWithLogin();
    	return this.http.put(this.actionUrl + 'anchor/anchor-details/', reqData, authHeader);
	}


	/**
	 * Anchor registration
	 *
	 * @param -- fornData JSON format
	 *
	 * @return Observable
	 */
	anchorRegistration(reqData): Observable<any>{
		// let authHeader = this.common.getHttpOptionWithLogin();
		let userData = JSON.parse(localStorage.getItem('userLoginData'));
		let headers = new HttpHeaders();
		headers = headers.append('Authorization', 'Bearer ' + userData.token);
		let options = { headers: headers };
    	return this.http.post(this.actionUrl + 'anchor/anchor-register/', reqData, options);
	}

	/**
	 * Update Anchor registration
	 *
	 * @param -- fornData JSON format
	 *
	 * @return Observable
	 */
	updateAnchorRegistration(reqData): Observable<any>{
		let authHeader = this.common.getHttpOptionWithLogin();
    	return this.http.put(this.actionUrl + 'anchor/anchor-register/', reqData, authHeader);
	}

	/**
	 * Get User Anchor registration List
	 *
	 * @param -- fornData JSON format
	 *
	 * @return Observable
	 */
	getAnchorRegistrationList(): Observable<any>{
		let authHeader = this.common.getHttpOptionWithLogin();
    	return this.http.get(this.actionUrl + 'anchor/anchor-register/', authHeader);
	}

	/**
	 * Get User Anchor details
	 *
	 * @param -- fornData JSON format
	 *
	 * @return Observable
	 */
	getAnchorDetails(reqData): Observable<any>{
		let authHeader = this.common.getHttpOptionWithLogin();
    	return this.http.post(this.actionUrl + 'anchor/anchor-details/', reqData, authHeader);
	}


	/**
	 * Register an anchor by it name
	 *
	 * @param -- fornData JSON format
	 *
	 * @return Observable
	 */
	 registerAnchorByName(reqData): Observable<any>{
		let authHeader = this.common.getHttpOptionWithLogin();
    	return this.http.post(this.actionUrl + 'anchor/anchor-register-by-name/', reqData, authHeader);
	}

	
	  
}
