import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SubscriptionService } from '../services/subscription/subscription.service';

@Component({
  selector: 'app-subscription-plans',
  templateUrl: './subscription-plans.component.html',
  styleUrls: ['./subscription-plans.component.css']
})
export class SubscriptionPlansComponent implements OnInit {
  userdetails: any;
  subscriptionList: any = [];
  email_error: boolean = false;
  constructor(
    private subscription_service: SubscriptionService,
    private _router: Router
  ) { 
    let userData = JSON.parse(localStorage.getItem('userLoginData'));
    if(userData != '' || userData !=undefined){
      this.userdetails = userData;
      console.log(this.userdetails);
    }
  }

  ngOnInit() {
    this.subscription_service.getAllSubscriptionList().subscribe(
			res => {
				console.log(res);
				if (res['status'] == 1) {
          this.subscriptionList = res['plans'];
					console.log('checkUserExists Status', this.subscriptionList);
				} else {
					this.email_error = false;
				}
			},
			err => {
				console.log("ERROR");
				console.log(err);
			}
		);
  }

  getSubscriptionRequest(subscription_id){
    console.log('Subscription ID', subscription_id);
    const reqData: any = {
      plan_id:subscription_id
    }
    this.subscription_service.subscriptionRequest(reqData).subscribe(
			res => {
				console.log(res);
				if (res['status'] == 1) {
          this._router.navigate(['/subscription-details']);
					// console.log('checkUserExists Status', this.subscriptionList);
				} else {
					this.email_error = false;
				}
			},
			err => {
				console.log("ERROR");
				console.log(err);
			}
		);
  }

}
