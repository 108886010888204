import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { ConfigurationService } from '../services/config/configuration.service';
import { UtilizationRouting } from './utilization-routing.module';
import { HttpModule } from '@angular/http';
import { HttpClient, HttpHeaders, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { PointUtilizationDetailsComponent } from './point-utilization-details.component';


@NgModule({
  declarations: [PointUtilizationDetailsComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    HttpModule,
    HttpClientModule,
    UtilizationRouting
  ],
  entryComponents: [],
	providers: [
		ConfigurationService,
		// DatePipe,
		CookieService,
	]
})
export class UtilizationModule { }
