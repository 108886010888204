import { Component, OnInit, AfterViewInit, Inject } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { AnchorService } from '../../services/anchor/anchor.service';
import { CommonService } from '../../services/common/common.service';
import { AddEditDomainComponent } from '../../domain/add-edit-domain.component';
import { MassageDialogModel, MassageBoxComponent } from '../../global/massage-box/massage-box.component';
@Component({
  selector: 'app-execution-setup',
  templateUrl: './execution-setup.component.html',
  styleUrls: ['./execution-setup.component.css']
})
export class ExecutionSetupComponent implements OnInit {
  commendSetup: FormGroup;
  commendName = new FormControl('', [Validators.required]);
  commendType = new FormControl('', [Validators.required]);
  runTime = new FormControl('', [Validators.required]);
  runCount = new FormControl('', [Validators.required]);
  //locationName = new FormControl('', [Validators.required]);
  //stateName = new FormControl('', [Validators.required]);
  // areaName = new FormControl('', [Validators.required]);
  commendAnchor = new FormControl('', [Validators.required]);
  domainName = new FormControl('', [Validators.required]);
  runTimeHours: any = [];
  showStateSection: boolean = false;
  showAreaSection: boolean = false;
  countryProgressber: boolean = true;
  stateProgressber: boolean = true;
  regionProgressber: boolean = true;
  showLoder: boolean = false;
  region: string = 'kolkata';
  aioriAnchorId: string = '';
  anchorUserId: number;
  lease_id: string = '';
  hostList: any;
  modalData: any;
  countrtList: any;
  stateList: any;
  regionList: any;
  anchorList: any = [];
  constructor(
    _formbuilder: FormBuilder,
    public dialogRef: MatDialogRef<ExecutionSetupComponent>, @Inject(MAT_DIALOG_DATA)
    // public dialogRef1: MatDialogRef<ExecutionSetupComponent>, @Inject(MAT_DIALOG_DATA) 
    public commendData:any,
		private dialog: MatDialog,
		private ar: ActivatedRoute, 
    private router: Router,
    private common: CommonService,
    private anchor: AnchorService
  ) {
    this.commendSetup = _formbuilder.group({
      commendName: this.commendName,
      commendType: this.commendType,
      runTime: this.runTime,
      runCount: this.runCount,
      //locationName: this.locationName,
      //stateName: this.stateName,
      // areaName: this.areaName,
      commendAnchor: this.commendAnchor,
      domainName: this.domainName
    });
    // Get Data From Parent Component
    this.modalData = commendData;
    console.log(this.modalData);
   }

  ngOnInit() {
    this.hostList = [];
    // Set Value modal field
    this.commendSetup.setValue({
      commendName: this.modalData.commend,
      commendType: 'regular',
      runTime: '1',
      runCount: '3',
      //locationName: '',
      //stateName: '',
      //areaName: '',
      commendAnchor: [],
      domainName: ''
    })
    // this.getCountryList();
    this.runTimeHours = [];                      // Results will go here
    // Loop from current hour number to 23
    for(var i = 0; i < 24; i++){
      if(i < 10){
        this.runTimeHours.push("0" + i + ".00");  // Put loop counter into array with "00" next to it
      }else{
        this.runTimeHours.push(i + ".00");  // Put loop counter into array with "00" next to it
      }
    }
    this.allUserAnchorList();
    // this.getCountryList();
    this.getHostList();
  }

  getCountryList(){
    this.countrtList = [];
    this.anchor.getCountryFromAIRO().subscribe(
      res => {
        // console.log('Login Responce', res);
        if(res.length > 0) {
          this.countrtList = res;
          this.countryProgressber = false
        }
        else{
          this.common.openSnackBar('No country list from AIRO Server', '');
          this.countryProgressber = false
        }
      },
      err => {
        console.log("ERROR");
        console.log(err);
        this.common.openSnackBar('No connection error from AIRO Server', '');
        this.countryProgressber = false
      },
    );
  }
  
  getHostList(){
    this.anchor.getHost().subscribe(
      res => {
        console.log('Host List', res);
        if(res["status"] == 1) {
          this.hostList = res['domain'];
        }
        else{
          this.hostList = res['domain'];
        }
      },
      err => {
        console.log("ERROR");
				console.log(err);
      },
    );
  }

  allUserAnchorList(){
    this.anchorList = [];
    this.anchor.getUserAnchors().subscribe(
      res => {
        if(res['status'] == 1){
          this.anchorList = res['anchor'];
          console.log('anchorList', this.anchorList);
        }
        if(res['status'] == 0){
          this.anchorList = [];
        }
      },
      err => {
        console.log("ERROR");
        console.log(err);
        this.common.openSnackBar('Please try later', '');
      }
    );
  }

  onSelectionLocation(event){
    // console.log('Country Name', event)
    this.stateList = [];
    this.showStateSection = true;
    this.anchor.getStatesFromAIRO(event.value).subscribe(
      res => {
        // console.log('stateList', res);
        if(res.length > 0) {
          this.stateList = res;
          this.stateProgressber = false
        }
        else{
          this.common.openSnackBar('No state list from AIRO Server', '');
          this.stateProgressber = false
        }
      },
      err => {
        console.log("ERROR");
        console.log(err);
        this.common.openSnackBar('No connection error from AIRO Server', '');
        this.stateProgressber = false
      },
    );
  }

  onSelectionState(event){
    this.regionList = [];
    this.showAreaSection = true;
    // console.log('Region');
    this.anchor.getRegionFromAIRO(event.value).subscribe(
      res => {
        // console.log('Region', res);
        if(res.length > 0) {
          this.regionList = res;
          this.regionProgressber = false
        }
        else{
          this.common.openSnackBar('No region list from AIRO Server', '');
          this.regionProgressber = false
        }
      },
      err => {
        console.log("ERROR");
        console.log(err);
        this.common.openSnackBar('No connection error from AIRO Server', '');
        this.regionProgressber = false
      },
    );
  }

  onSelectionAnchor(event){
    console.log(event)
    let that = this;
    let anchorID = event.value;
    if(this.anchorList.length > 0){
      this.anchorList.forEach(element => {
        if(element.id == anchorID){
          console.log(element)
          that.region = element.location;
          that.aioriAnchorId = element.aiori_anchor_id;
          that.lease_id = element.lease_id;
          that.anchorUserId = element.user;
        }
      });
    }
  }

  checkPointUtilization(){
    // Status Are
    // user have no subscription points earn entry = 0
    // successfully deduct point status = 1
    // remaining_points is less then deduct point status = 2
    if(this.commendSetup.valid){
      this.showLoder = true;
      const formData: any = {
        command_name: this.commendSetup.value.commendName,
        run_time: this.commendSetup.value.runTime,
        utilize_point: 5,
        anchor_user_id: this.anchorUserId
      }
      this.anchor.utilizePoint(formData).subscribe(
        res => {
          if(res['status']==1) {
            // successfully deduct point
            this.showLoder = false;
            this.saveHistory();
          }
          else if(res['status']==2){
            this.showLoder = false;
            // remaining_points is less then deduct point
            this.dialogRef.close();
            // open massage popup
            const message = 'You have no sufficient points to execute this command. Please contact to administrater.'; // generate massage
            const dialogData = new MassageDialogModel("Insufficient Points", message); // Call Global massage box with parameters, 
            const dialogRefMsg = this.dialog.open(MassageBoxComponent, { // 2 parameters are givin
              maxWidth: "400px",
              data: dialogData
            });
            dialogRefMsg.afterClosed().subscribe(dialogResult => { // After close the massage box return true or false base on button click
            });
          }
          else{
            this.showLoder = false;
            // user have no subscription points earn
            this.dialogRef.close();
            // open massage popup
            const message = 'You have no points to execute this command. Please contact to administrater.'; // generate massage
            const dialogData = new MassageDialogModel("No Points", message); // Call Global massage box with parameters, 
            const dialogRefMsg = this.dialog.open(MassageBoxComponent, { // 2 parameters are givin
              maxWidth: "400px",
              data: dialogData
            });
            dialogRefMsg.afterClosed().subscribe(dialogResult => { // After close the massage box return true or false base on button click
            });
          }
        },
        err => {
          this.showLoder = false;
          console.log("ERROR");
          console.log(err);
          this.common.openSnackBar('Please try later', '');
          this.regionProgressber = false
        },
      );
    }
  }
  
  saveHistory(){
    console.log(this.commendSetup.valid)
    if(this.commendSetup.valid){
      this.showLoder = true;
      let anchors = [];
      anchors.push();
      const formData: any = {
        cmd_value: this.commendSetup.value.commendName,
        cmd_type: this.commendSetup.value.commendType,
        run_time: this.commendSetup.value.runTime,
        run_count: this.commendSetup.value.runCount,
        run_location: this.commendSetup.value.locationName,
        run_state: this.commendSetup.value.stateName,
        // region: this.commendSetup.value.areaName,
        region: this.region,
        anchor_ids: this.commendSetup.value.commendAnchor,
        hosts: this.commendSetup.value.domainName
      }
      console.log('Test', formData);
      const reqData = JSON.stringify(formData);
      /// To Do points check in user account
      this.anchor.postCommendHistory(reqData).subscribe(
        res => {
          // console.log('History Details', res);
          if(res['status'] == 1){
            this.showLoder = false;
            let history_id = res['obj_id']
            this.postCommendRequestToAiro(history_id);
          }
          else{
            this.showLoder = false;
            this.common.openSnackBar('Please try later', '');
          }
        },
        err => {
          this.showLoder = false;
          console.log("ERROR");
          console.log(err);
        }
      );
    }
  }

  postCommendRequestToAiro(id){
    this.showLoder = true;
    let historyId = id;
    let sendhosts: any = [];
    let ancherids: any = [];
    let commend_value: string = this.commendSetup.value.commendName;
    sendhosts.push(this.commendSetup.value.domainName);
    ancherids.push(this.aioriAnchorId);
    const reqCmdData: any = {
      cmd_value: commend_value.toLowerCase(),
      cmd_type: this.commendSetup.value.commendType,
      // run_time: this.commendSetup.value.runTime,
      run_count: this.commendSetup.value.runCount,
      // region: this.commendSetup.value.areaName,
      region: this.region,
      hosts: sendhosts,
      anchors: ancherids
    }
    const post_param = JSON.stringify(reqCmdData);
    this.anchor.postExecuteCommendRegularToAIRO(post_param, this.lease_id).subscribe(
      res => {
        this.showLoder = false;
        console.log('Execution Details', res);
        this.updateCommendHistory(historyId, res['id'], res['status'])
        // if(res['status'] == 1){
        //   this.postCommendRequestToAiro();
        // }
        // else{
        //   this.common.openSnackBar('Please try later', '');
        // }
      },
      err => {
        this.showLoder = false;
        console.log("ERROR");
        console.log(err);
        this.common.openSnackBar('No connection error from AIRO Server', '');
      }
    );
  }

  updateCommendHistory(his_id, qry_id, status){
    this.showLoder = true;
    const reqData: any = {
      id: his_id,
      query_id: qry_id,
      status: 'success'
    }
    this.anchor.putCommendHistory(reqData).subscribe(
      res => {
        console.log('Execution Details', res);
        if(res['status'] == 1){
          this.showLoder = false;
          this.dialogRef.close(true);
        }
        else{
          this.showLoder = false;
          this.common.openSnackBar('Please try later', '');
        }
      },
      err => {
        this.showLoder = false;
        console.log("ERROR");
        console.log(err);
        this.common.openSnackBar('No connection error from AIRO Server', '');
      }
    );
  }
  
  dialogRefTags : MatDialogRef<AddEditDomainComponent> | null;
  goToDomainList():void{
    this.closePopup();
    this.dialogRefTags = this.dialog.open(AddEditDomainComponent, {
      data: {
        id: 0
      },
        disableClose: true
      });
      this.dialogRefTags.afterClosed().subscribe(result => {
        console.log('afterClosed', result);
      });
    // this.router.navigate(['/domain-list']);
  }

  closePopup() {
    this.dialogRef.close();
  }

}
