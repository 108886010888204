import { Component, OnInit, Inject, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import { Router, ActivatedRoute } from '@angular/router';
import { AnchorService } from '../services/anchor/anchor.service';
import { CommonService } from '../services/common/common.service';
import { UserService } from '../services/user/user.service';
// import { XlsdownlodeService } from '../services/download/xlsdownlode.service';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { BaseChartDirective, Color, Label } from 'ng2-charts';
import { MatMenuTrigger } from '@angular/material'
import { Observable, Subject, interval, Subscription } from 'rxjs';
// import { Workbook } from 'exceljs';
// import * as fs from 'file-saver';
// const EXCEL_TYPE = 'application/vnd.openxmlformatsofficedocument.spreadsheetml.sheet;charset=UTF-8';

// const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-periodic-analytics-details',
  templateUrl: './periodic-analytics-details.component.html',
  styleUrls: ['./periodic-analytics-details.component.css']
})
export class PeriodicAnalyticsDetailsComponent implements OnInit, AfterViewInit, OnDestroy {

  public graphGenerateCompleteObservable = new Subject<any>();
  public graphDataCallBackResult$ = this.graphGenerateCompleteObservable.asObservable();

  @ViewChild(BaseChartDirective) chart: BaseChartDirective;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  lineChartData: any = [
    { data: [], label: '' }
  ];
  ChartDataSets: any = [];
  // lineChartData: ChartDataSets[] = [
  //   { data: [80, 90, 60, 80, 75, 40], label: 'Crude oil prices' },
  //   { data: [85, 72, 78, 75, 77, 75], label: 'Crude oil prices' },
  //   { data: [85, 72, 78, 75, 77, 80], label: 'Crude oil prices' },
  // ];

  lineChartLabels: any = [];
  Label: any = [];

  lineChartOptions = {
    responsive: true,
  };

  lineChartColors: Color[] = [
    {
      borderColor: 'red',
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
    },
  ];

  lineChartLegend = true;
  lineChartPlugins = [];
  lineChartType = 'line';

  rtt_max: any = []; 
  rtt_med: any = []; 
  rtt_min: any = [];

  historyId: number = 0;
  historyDetailsId: number = 0;
  historyList: any = [];
  domainList: any;
  subscription: Subscription;
  queryStartDate: string;
  queryEndDate: string;
  queryRunTime: number;
  filterByArray: any = [];
  formModel: any = {};
  chartNewValue: any = [];
  chartNewLabeles: any = [];
  downloadProgressBer: boolean = false;
  graphTemplateComplete: boolean = true;
  // Date picker Variable
  minDate: Date;
  maxDate: Date;
  queryMinDate: Date;
  queryMaxDate: Date;
  constructor(
    private datePipe: DatePipe,
    private router: Router,
    private activatedroute: ActivatedRoute,
    private common: CommonService,
    private anchor: AnchorService,
    private userservice: UserService,
    // private download: XlsdownlodeService,
  ) {
    console.log('this.activatedroute.snapshot.params', this.router);
    // this.historyId = + atob(this.activatedroute.snapshot.params['tokenid']);
    // this.historyDetailsId = + atob(this.activatedroute.snapshot.params['detailtoken'])
    console.log('History ID', this.activatedroute.snapshot.params['tokenid']);
    this.formModel.filter_by = '10s';

    // Set the minimum to January 1st 20 years in the past and December 31st a year in the future.
    const currentYear = new Date().getFullYear();
    // this.minDate = datePipe.transform(Date.now(),'yyyy-MM-dd');
    // this.minDate = new Date();
    var ONE_HOUR = 10 * 60 * 1000; /* ms */
    this.queryMaxDate = new Date();
    this.queryMinDate = new Date();
    this.formModel.formDateTime = new Date(this.queryMaxDate.getTime() - ONE_HOUR).toJSON();
    this.formModel.toDateTime = new Date().toJSON();
    // console.log('Current Time', new Date(this.queryMaxDate.getTime() + ONE_HOUR));
    // console.log('2 Hours Left', new Date(this.queryMaxDate.getTime() - ONE_HOUR));
    this.formModel.downloadFormDateTime = new Date().toJSON();
    this.formModel.downloadToDateTime = new Date().toJSON();
  }

  ngOnInit() {
    setTimeout(() => {
      this.common.setLoader(true);
    });
    this.getDomainList();
    const fromData = {
      history_id: this.historyId
    }
    this.anchor.commendHistoryById(fromData).subscribe(
      res => {
        if(res['status'] == 1){
          let that = this;
          // console.log('Periodic History', res['history']);
          this.queryStartDate = res['history']['created_date'];
          this.minDate = new Date(this.queryStartDate);
          this.queryEndDate = res['history']['query_execution_end_date'];
          this.maxDate = new Date(this.queryEndDate);
          this.queryRunTime = res['history']['commend_request_payload']['run_time'];
          // console.log('queryRunTime', this.queryRunTime);
          this.historyList = res['history']['query_details'];
          console.log(this.historyList);
          this.historyList.forEach(element => {
            element['todate'] = that.queryEndDate;
            let hostName = that.filterDomainName(element.commend_request_payload.hosts.toString());
            element.commend_request_payload.hosts = hostName;
          });
          // console.log('Periodic History' ,this.historyList);
          // Set Start and End Date in Datepicker calender
          // this.minDate = this.datePipe.transform(new Date(this.queryStartDate),'yyyy-MM-dd');
          // this.minDate = new Date(fullyear + '-' + month + '-' + day);
          // this.maxDate = new Date();
          // this.formModel.formDateTime = this.queryStartDate.toLocaleString();
          // this.formModel.toDateTime = this.queryEndDate.toLocaleString();
          // console.log('From Date', this.formModel.formDateTime);
          // console.log('From minDate', this.minDate);
          // console.log('To Date', this.formModel.toDateTime);


          let differance = this.diff_minutes(new Date(this.queryEndDate), this.queryMaxDate);
          // console.log('Differ', differance);
          if(differance > 0){
            this.formModel.filter_by = '10s';
            this.filterByArray.push(
              {'name':'Live', 'value':'10s'},
              {'name':'Hourly', 'value':'1h'},
              {'name':'Daily', 'value':'1d'},
              {'name':'Weekly', 'value':'7d'},
              {'name':'Monthly', 'value':'30d'},
              {'name':'Yearly', 'value':'365d'}
            );
            this.generateAnalyticalGraph();
          }
          else{
            this.filterByArray.push(
              {'name':'Minutes', 'value':'60s'},
              {'name':'Hourly', 'value':'1h'},
              {'name':'Daily', 'value':'1d'},
              {'name':'Weekly', 'value':'7d'},
              {'name':'Monthly', 'value':'30d'},
              {'name':'Yearly', 'value':'365d'}
            );
            // Reset form and to Date and intervel variable
            this.formModel.filter_by = '60s';
            var ONE_HOUR = 60 * 60 * 1000; /* ms */
            this.queryMaxDate = new Date(this.queryEndDate);
            this.formModel.formDateTime = new Date(this.queryMaxDate.getTime() - ONE_HOUR).toJSON();
            this.formModel.toDateTime = this.queryMaxDate.toJSON();
            // console.log('Current Time', new Date(this.queryMaxDate.getTime() + ONE_HOUR));
            // console.log('2 Hours Left', new Date(this.queryMaxDate.getTime() - ONE_HOUR));
            // this.graphFilter(this.formModel.filter_by);
            this.generateAnalyticalGraph();
          }
          setTimeout(() => {
            this.common.setLoader(false);
          });
        }
        if(res['status'] == 0){
          this.historyList = [];
          setTimeout(() => {
            this.common.setLoader(false);
          });
        }
        // setTimeout(() => {
        //   this.common.setLoader(false);
        // }, 300);
      },
      err => {
        // this.common.setLoader(false);
        setTimeout(() => {
          this.common.setLoader(false);
        }, 300);
        console.log("ERROR");
        console.log(err);
        this.common.openSnackBar('Please try later', '');
      }
    );
  }

  diff_minutes(dt2, dt1){
    var d2 = Date.parse(dt2) / 1000;
    // console.log('Daten2', d2);
    var d1 = Date.parse(dt1) / 1000;
    // console.log('Daten1', d1);
    var diff =(d2 - d1);
    // console.log('Diffrence', diff);
    // var diff =(dt2.getTime() - dt1.getTime()) / 1000;
    if(diff > 60){
      // console.log('Not In Minus');
      diff /= 60;
      return Math.abs(Math.round(diff));
    }
    else{
      // console.log('In Minus');
      return 0;
    }
  }

  ngAfterViewInit(){
    // console.log('New Graph Call')
    this.graphDataCallBackResult$.subscribe(value =>{
      // console.log('graphDataCallBackResult', value);
      // console.log('Chart Data Set', this.chart.chart.data);
      if(value){
        // console.log('success');
        // const source = interval(300000);
        const source = interval(10000);
		    this.subscription = source.subscribe(val => this.generateNewDataSets())
      }
    }); 
  }

  generateNewDataSets(){
    // console.log('generateNewDataSets');
    // console.log('Chart Data Set', this.chart.chart.data.datasets[0].data);
    // Runtime calculateion
    var current_date = new Date();
    var current_timestamp = current_date.getTime();
    var execution_end_date = new Date(this.queryEndDate);
    var execution_end_timestamp = execution_end_date.getTime();
    if(this.formModel.filter_by == '10s'){
      if(current_timestamp < execution_end_timestamp){
        // console.log('this.queryEndDate', this.queryEndDate);
        // console.log('Chart Data Set', this.chart.chart.data.datasets[0].data);
        // this.graphDataAdd();
        // this.graphDataAdd1();
        this.graphDataAdd2();
      }
    }
  }

  getDomainList(){
    this.userservice.domainList().subscribe(
      res => {
        if(res['status'] == 1){
          this.domainList = res['domain'];
        }
        if(res['status'] == 0){
          this.domainList = [];
        }
      },
      err => {
        console.log("ERROR");
        console.log(err);
        this.common.openSnackBar('Please try later', '');
      }
    );
  }

  filterDomainName(domain_ip){
    let domain_name = '';
    if(this.domainList.length > 0){
      this.domainList.forEach(element => {
        if(domain_ip == element.domain_ip){
          domain_name = element.domain_name;
          // console.log('Domain Name', domain_name);
        }
      });
    }
    return domain_name;
  }

  // generateAnalyticalGraph(){
  //   setTimeout(() => {
  //     this.common.setLoader(true);
  //   });
  //   console.log('Graph');
  //   const formData: any = {
  //     id: this.historyId,
  //     duration: this.formModel.filter_by,
  //     from_date: this.formModel.formDateTime,
  //     to_date: this.formModel.toDateTime
  //   }
  //     this.rtt_max = []; 
  //     this.rtt_med = []; 
  //     this.rtt_min = [];
  //     this.lineChartLabels = [];
  //     let that = this;
  //     this.lineChartData.push(
  //         {"data":this.rtt_max, "label": 'RTT-MAX'}
  //         // {"data":this.rtt_med, "label": 'RTT-MED'},
  //         // {"data":this.rtt_min, "label": 'RTT-MIN'}
  //     );
  //     this.anchor.getPeriodicAnalyticalData(formData).subscribe(
  //         res => {
  //           if(res['status'] == 1){
  //             this.lineChartData = [];
  //             this.lineChartLabels = [];
  //             // console.log('Graph Data', res);
  //             // console.log('Graph Data Length', Object.keys(res['data']).length);
  //             // console.log('Graph Data Length', (Object.keys(res['data']).length - 1));
  //             if(Object.keys(res['data']).length > 0){
  //               let count = 0;
  //               Object.keys(res['data']).forEach(key => {
  //                 let keyname = key;
  //                 let value = res['data'][key];
  //                 // console.log(keyname);
  //                 // console.log(value)
  //                 if(value.length > 0){
  //                   that.rtt_max = [];
  //                   value.forEach(element => {
  //                     that.rtt_max.push(element.rtt_avg)

  //                     // Create Labels
  //                     let unix_timestamp = element.time
  //                     // Create a new JavaScript Date object based on the timestamp
  //                     // multiplied by 1000 so that the argument is in milliseconds, not seconds.
  //                     var date = new Date(unix_timestamp);
  //                     // Hours part from the timestamp
  //                     var hours = date.getHours();
  //                     // Minutes part from the timestamp
  //                     var minutes = "0" + date.getMinutes();
  //                     // Seconds part from the timestamp
  //                     var seconds = "0" + date.getSeconds();

  //                     // Will display time in 10:30:23 format
  //                     var formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
  //                     if((Object.keys(res['data']).length - 1) == count){
  //                       that.lineChartLabels.push(formattedTime);
  //                     }
  //                     // console.log('count', count);
  //                   });
  //                 }
  //                 let hostName = that.filterDomainName(keyname);
  //                 this.lineChartData.push({"data":that.rtt_max, "label": hostName});
  //                 count ++
  //               });
  //               setTimeout(() => {
  //                 this.graphGenerateCompleteObservable.next(true);
  //                 this.common.setLoader(false);
  //               });
  //             }
  //             console.log('Final Grapd Data', this.lineChartData);
  //           }
  //           if(res['status'] == 0){
  //             this.lineChartData.push(
  //                 {"data":this.rtt_max, "label": 'RTT-MAX'},
  //                 {"data":this.rtt_med, "label": 'RTT-MED'},
  //                 {"data":this.rtt_min, "label": 'RTT-MIN'}
  //             );
  //             setTimeout(() => {
  //               this.common.setLoader(false);
  //             }, 300);
  //           }
  //         },
  //         err => {
  //           setTimeout(() => {
  //             this.common.setLoader(false);
  //           }, 300);
  //           console.log("ERROR");
  //           console.log(err);
  //           this.common.openSnackBar('Please try later', '');
  //         }
  //       );
  // }

  // graphDataAdd(){
  //   this.maxDate = new Date();
  //   let differTime = this.diff_minutes(this.maxDate, this.minDate);
  //   if(differTime > 2){
  //     this.minDate = new Date();
  //     this.formModel.formDateTime = new Date(this.minDate.getTime() - (10 * 60 * 1000)).toJSON();
  //   }
  //   // this.minDate = new Date();
  //   // console.log('Set Time', this.fromTime);
  //   console.log('New Time', this.maxDate.getTime());
  //   console.log('Difference Time', differTime);
  //   // this.formModel.formDateTime = new Date(this.maxDate.getTime() - (2 * 60 * 1000)).toJSON();
  //   this.formModel.toDateTime = new Date().toJSON();

  //   const formData: any = {
  //     id: this.historyId,
  //     duration: this.formModel.filter_by,
  //     from_date: this.formModel.formDateTime,
  //     to_date: this.formModel.toDateTime
  //   }
  //   let that = this;
  //   this.anchor.getPeriodicAnalyticalDataGroupByTest(formData).subscribe(
  //     res => {
  //       if(res['status'] == 1){
  //         if(Object.keys(res['data']).length > 0){
  //           let cnt = 0;
  //           Object.keys(res['data']).forEach(key => {
  //             let keyname = key;
  //             // console.log('keyname', keyname);
  //             let value = res['data'][key];
  //             if(value.length > 0){
  //               that.chartNewValue = [];
  //               that.chartNewLabeles = []
  //               value.forEach(element => {
  //                 that.chartNewValue.push(element.mean)
  //                 // Create Labels
  //                 let unix_timestamp = element.time
  //                 // Create a new JavaScript Date object based on the timestamp
  //                 // multiplied by 1000 so that the argument is in milliseconds, not seconds.
  //                 var date = new Date(unix_timestamp);
  //                 // Date part from the timestamp
  //                 var day = date.getDate();
  //                 // Month part from the timestamp
  //                 var month = (date.getMonth() + 1);
  //                 // Year part from the timestamp
  //                 var fullyear = date.getFullYear();
  //                 // Hours part from the timestamp
  //                 var hours = date.getHours();
  //                 // Minutes part from the timestamp
  //                 var minutes = "0" + date.getMinutes();
  //                 // Seconds part from the timestamp
  //                 var seconds = "0" + date.getSeconds();

  //                 // Will display date in 10-06:2020 format
  //                 var formattedDate = day + '-' + month + '-' + fullyear;
  //                 // Will display time in 10:30:23 format
  //                 var formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
  //                 if((Object.keys(res['data']).length - 1) == cnt){
  //                   if(this.formModel.filter_by === '1h' || this.formModel.filter_by === '10s'){
  //                     that.chartNewLabeles.push(formattedDate + '-' +formattedTime);
  //                   }else{
  //                     that.chartNewLabeles.push(formattedDate);
  //                   }
                    
  //                 }
  //               });
  //             }
  //             ///Data push in graph canvus
  //             this.chart.chart.data.datasets[cnt].data = that.chartNewValue;
  //             this.chart.chart.update();
  //             cnt ++;
  //           });
  //           this.chart.chart.data.labels = that.chartNewLabeles;
  //           this.chart.chart.update();
  //         }
  //       }
        
  //     },
  //     err => {
  //       console.log("ERROR");
  //       console.log(err);
  //       this.common.openSnackBar('Please try later', '');
  //     }
  //   );
  // }

  // graphDataAdd1(){
  //   this.maxDate = new Date();
  //   // let differTime = this.diff_minutes(this.maxDate, this.minDate);
  //   // if(differTime > 2){
  //   //   this.minDate = new Date();
  //   //   this.formModel.formDateTime = new Date(this.minDate.getTime() - (10 * 60 * 1000)).toJSON();
  //   // }
  //   // this.minDate = new Date();
  //   // console.log('Set Time', this.fromTime);
  //   // console.log('New Time', this.maxDate.getTime());
  //   // console.log('Difference Time', differTime);
  //   this.formModel.formDateTime = new Date(this.maxDate.getTime() - (10 * 60 * 1000)).toJSON();
  //   this.formModel.toDateTime = new Date().toJSON();

  //   const formData: any = {
  //     id: this.historyId,
  //     duration: this.formModel.filter_by,
  //     from_date: this.formModel.formDateTime,
  //     to_date: this.formModel.toDateTime
  //   }
  //   let that = this;
  //   this.anchor.getPeriodicAnalyticalDataGroupByTest(formData).subscribe(
  //     res => {
  //       if(res['status'] == 1){
  //         if(Object.keys(res['data']).length > 0){
  //           let cnt = 0;
  //           Object.keys(res['data']).forEach(key => {
  //             let keyname = key;
  //             // console.log('keyname', keyname);
  //             let value = res['data'][key];
  //             if(value.length > 0){
  //               that.chartNewValue = [];
  //               that.chartNewLabeles = []
  //               value.forEach(element => {
  //                 that.chartNewValue.push(element.mean)
  //                 // Create Labels
  //                 let unix_timestamp = element.time
  //                 // Create a new JavaScript Date object based on the timestamp
  //                 // multiplied by 1000 so that the argument is in milliseconds, not seconds.
  //                 var date = new Date(unix_timestamp);
  //                 // Date part from the timestamp
  //                 var day = date.getDate();
  //                 // Month part from the timestamp
  //                 var month = (date.getMonth() + 1);
  //                 // Year part from the timestamp
  //                 var fullyear = date.getFullYear();
  //                 // Hours part from the timestamp
  //                 var hours = date.getHours();
  //                 // Minutes part from the timestamp
  //                 var minutes = "0" + date.getMinutes();
  //                 // Seconds part from the timestamp
  //                 var seconds = "0" + date.getSeconds();

  //                 // Will display date in 10-06:2020 format
  //                 var formattedDate = day + '-' + month + '-' + fullyear;
  //                 // Will display time in 10:30:23 format
  //                 var formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
  //                 if((Object.keys(res['data']).length - 1) == cnt){
  //                   if(this.formModel.filter_by === '1h' || this.formModel.filter_by === '10s'){
  //                     that.chartNewLabeles.push(formattedDate + '-' +formattedTime);
  //                   }else{
  //                     that.chartNewLabeles.push(formattedDate);
  //                   }
                    
  //                 }
  //               });
  //             }
  //             ///Data push in graph canvus
  //             this.chart.chart.data.datasets[cnt].data = that.chartNewValue;
  //             this.chart.chart.update();
  //             cnt ++;
  //           });
  //           this.chart.chart.data.labels = that.chartNewLabeles;
  //           this.chart.chart.update();
  //         }
  //       }
        
  //     },
  //     err => {
  //       console.log("ERROR");
  //       console.log(err);
  //       this.common.openSnackBar('Please try later', '');
  //     }
  //   );
  // }

  graphDataAdd2(){
    this.queryMaxDate = new Date();
    // let differTime = this.diff_minutes(this.maxDate, this.minDate);
    // if(differTime > 2){
    //   this.minDate = new Date();
    //   this.formModel.formDateTime = new Date(this.minDate.getTime() - (10 * 60 * 1000)).toJSON();
    // }
    // this.minDate = new Date();
    // console.log('Set Time', this.fromTime);
    // console.log('New Time', this.maxDate.getTime());
    // console.log('Difference Time', differTime);
    // this.formModel.formDateTime = new Date(this.maxDate.getTime() - (10 * 1000)).toJSON();

    this.formModel.formDateTime = this.formModel.toDateTime;
    this.formModel.toDateTime = new Date().toJSON();

    const formData: any = {
      id: this.historyId,
      duration: this.formModel.filter_by,
      from_date: this.formModel.formDateTime,
      to_date: this.formModel.toDateTime
    }
    let that = this;
    this.anchor.getPeriodicAnalyticalDataGroupByTest(formData).subscribe(
      res => {
        if(res['status'] == 1){
          if(Object.keys(res['data']).length > 0){
            let cnt = 0;
            Object.keys(res['data']).forEach(key => {
              let keyname = key;
              // console.log('keyname', keyname);
              let value = res['data'][key];
              if(value.length > 0){
                that.chartNewValue = [];
                that.chartNewLabeles = []
                value.forEach(element => {
                  that.chartNewValue.push(element.mean? element.mean : 0)

                  ///Data pop & push in graph canvus
                  this.chart.chart.data.datasets[cnt].data.shift();
                  // this.chart.chart.data.datasets[cnt].data.push(element.mean? element.mean : 0);
                  (this.chart.chart.data.datasets[cnt].data as (number | Chart.ChartPoint)[]).push(element.mean? element.mean : 0);

                  // Create Labels
                  let unix_timestamp = element.time
                  // Create a new JavaScript Date object based on the timestamp
                  // multiplied by 1000 so that the argument is in milliseconds, not seconds.
                  var date = new Date(unix_timestamp);
                  // Date part from the timestamp
                  var day = date.getDate();
                  // Month part from the timestamp
                  var month = (date.getMonth() + 1);
                  // Year part from the timestamp
                  var fullyear = date.getFullYear();
                  // Hours part from the timestamp
                  var hours = date.getHours();
                  // Minutes part from the timestamp
                  var minutes = "0" + date.getMinutes();
                  // Seconds part from the timestamp
                  var seconds = "0" + date.getSeconds();

                  // Will display date in 10-06:2020 format
                  var formattedDate = day + '-' + month + '-' + fullyear;
                  // Will display time in 10:30:23 format
                  var formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
                  if((Object.keys(res['data']).length - 1) == cnt){
                    if(this.formModel.filter_by === '1h' || this.formModel.filter_by === '10s'){
                      that.chartNewLabeles.push(formattedDate + '-' +formattedTime);
                      this.chart.chart.data.labels.shift();
                      this.chart.chart.data.labels.push(formattedDate + '-' +formattedTime);
                    }else{
                      that.chartNewLabeles.push(formattedDate);
                      this.chart.chart.data.labels.shift();
                      this.chart.chart.data.labels.push(formattedDate);
                    }
                    
                  }
                });
              }
              ///Data push in graph canvus
              // let newChartArray: any = [];
              // let afterShiftChartArray: any = [];
              // this.chart.chart.data.datasets[cnt].data = that.chartNewValue;
              // this.chart.chart.data.datasets[cnt].data.shift();
              // this.chart.chart.data.datasets[cnt].data.push(that.chartNewValue);
              // this.chart.chart.data.datasets[cnt].data = afterShiftChartArray;
              // this.chart.chart.update();
              cnt ++;
            });
            // this.chart.chart.data.labels = that.chartNewLabeles;
            // this.chart.chart.data.labels.shift();
            // this.chart.chart.data.labels.push(that.chartNewLabeles);
            // this.chart.chart.data.labels = afterPushChatyLabels;
            this.chart.chart.update();
          }
        }
        
      },
      err => {
        console.log("ERROR");
        console.log(err);
        this.common.openSnackBar('Please try later', '');
      }
    );
  }

  // filterByDateTime(){
  //   console.log('this.formModel.formDateTime', this.formModel.formDateTime);
  //   console.log('this.formModel.toDateTime', this.formModel.toDateTime);

  //   setTimeout(() => {
  //     this.common.setLoader(true);
  //   });
  //   const formData: any = {
  //     id: this.historyId,
  //     duration: this.formModel.filter_by,
  //     from_date: this.formModel.formDateTime,
  //     to_date: this.formModel.toDateTime
  //   }
  //   this.rtt_max = [];
  //   this.lineChartData.push(
  //     {"data":this.rtt_max, "label": 'RTT-MAX'}
  //   );
  //   let that = this;
  //   this.anchor.getPeriodicAnalyticalDataGroupByTest(formData).subscribe(
  //     res => {
  //       if(res['status'] == 1){
  //         this.ChartDataSets = [];
  //         this.Label = [];
  //         if(Object.keys(res['data']).length > 0){
  //           let count = 0;
  //           Object.keys(res['data']).forEach(key => {
  //             let keyname = key;
  //             let value = res['data'][key];
  //             if(value.length > 0){
  //               that.rtt_max = [];
  //               value.forEach(element => {
  //                 // that.rtt_max.push(element.mean ? element.mean : 0)
  //                 that.rtt_max.push(element.mean)

  //                 // Create Labels
  //                 let unix_timestamp = element.time
  //                 // Create a new JavaScript Date object based on the timestamp
  //                 // multiplied by 1000 so that the argument is in milliseconds, not seconds.
  //                 var date = new Date(unix_timestamp);
  //                 // Date part from the timestamp
  //                 var day = date.getDate();
  //                 // Month part from the timestamp
  //                 var month = (date.getMonth() + 1);
  //                 // Year part from the timestamp
  //                 var fullyear = date.getFullYear();
  //                 // Hours part from the timestamp
  //                 var hours = date.getHours();
  //                 // Minutes part from the timestamp
  //                 var minutes = "0" + date.getMinutes();
  //                 // Seconds part from the timestamp
  //                 var seconds = "0" + date.getSeconds();

  //                 // Will display date in 10-06:2020 format
  //                 var formattedDate = day + '-' + month + '-' + fullyear;
  //                 // Will display time in 10:30:23 format
  //                 var formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
  //                 if((Object.keys(res['data']).length - 1) == count){
  //                   if(this.formModel.filter_by === '1h' || this.formModel.filter_by === '10s'){
  //                     that.Label.push(formattedDate + '-' +formattedTime);
  //                   }else{
  //                     that.Label.push(formattedDate);
  //                   }
                    
  //                 }
  //               });
  //             }
  //             let hostName = that.filterDomainName(keyname);
  //             that.ChartDataSets.push({"data":that.rtt_max, "label": hostName});
  //             count ++
  //           });
  //           setTimeout(() => {
  //             this.graphGenerateCompleteObservable.next(true);
  //             this.common.setLoader(false);
  //           });
  //         }
  //         this.lineChartData = [];
  //         this.lineChartLabels = [];
  //         this.lineChartData = this.ChartDataSets;
  //         this.lineChartLabels = this.Label;
  //         // console.log('Final Grapd Data', this.ChartDataSets);
  //         // console.log('Final Grapd Label', this.Label);

  //       }
  //       if(res['status'] == 0){
  //         this.lineChartData.push(
  //             {"data":this.rtt_max, "label": 'RTT-MAX'},
  //         );
  //       }
  //     },
  //     err => {
  //       console.log("ERROR");
  //       console.log(err);
  //       this.common.openSnackBar('Please try later', '');
  //     }
  //   );
  // }

  generateAnalyticalGraph(){
    // console.log('analyticalDataFilterBy', this.lineChartData);
    setTimeout(() => {
      this.common.setLoader(true);
    });
    const formData: any = {
      id: this.historyId,
      duration: this.formModel.filter_by,
      from_date: this.formModel.formDateTime,
      to_date: this.formModel.toDateTime
    }
    this.rtt_max = [];
    this.lineChartData.push(
      {"data":this.rtt_max, "label": 'RTT-MAX'}
    );
    let that = this;
    this.anchor.getPeriodicAnalyticalDataGroupBy(formData).subscribe(
      res => {
        if(res['status'] == 1){
          this.ChartDataSets = [];
          this.Label = [];
          if(Object.keys(res['data']).length > 0){
            let count = 0;
            Object.keys(res['data']).forEach(key => {
              let keyname = key;
              let value = res['data'][key];
              if(value.length > 0){
                that.rtt_max = [];
                value.forEach(element => {
                  that.rtt_max.push(element.mean ? element.mean : 0)
                  // that.rtt_max.push(element.mean)

                  // Create Labels
                  let unix_timestamp = element.time
                  // Create a new JavaScript Date object based on the timestamp
                  // multiplied by 1000 so that the argument is in milliseconds, not seconds.
                  var date = new Date(unix_timestamp);
                  // Date part from the timestamp
                  var day = date.getDate();
                  // Month part from the timestamp
                  var month = (date.getMonth() + 1);
                  // Year part from the timestamp
                  var fullyear = date.getFullYear();
                  // Hours part from the timestamp
                  var hours = date.getHours();
                  // Minutes part from the timestamp
                  var minutes = "0" + date.getMinutes();
                  // Seconds part from the timestamp
                  var seconds = "0" + date.getSeconds();

                  // Will display date in 10-06:2020 format
                  var formattedDate = day + '-' + month + '-' + fullyear;
                  // Will display time in 10:30:23 format
                  var formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
                  if((Object.keys(res['data']).length - 1) == count){
                    that.Label.push(formattedDate + '-' + formattedTime);
                    // if(this.formModel.filter_by === '1h' || this.formModel.filter_by === '10s'){
                    //   that.Label.push(formattedDate + '-' +formattedTime);
                    // }else{
                    //   that.Label.push(formattedDate);
                    // }
                    
                  }
                });
              }
              let hostName = that.filterDomainName(keyname);
              that.ChartDataSets.push({"data":that.rtt_max, "label": hostName});
              count ++
            });
            setTimeout(() => {
              this.graphGenerateCompleteObservable.next(true);
              this.common.setLoader(false);
            });
          }
          this.lineChartData = [];
          this.lineChartLabels = [];
          this.lineChartData = this.ChartDataSets;
          this.lineChartLabels = this.Label;
          // console.log('Final Grapd Data', this.ChartDataSets);
          // console.log('Final Grapd Label', this.Label);

        }
        if(res['status'] == 0){
          this.lineChartData = [];
          this.graphTemplateComplete = false;
          setTimeout(() => {
            this.graphGenerateCompleteObservable.next(true);
            this.common.setLoader(false);
          });
        }
      },
      err => {
        console.log("ERROR");
        console.log(err);
        this.common.openSnackBar('Please try later', '');
        this.graphTemplateComplete = false;
        setTimeout(() => {
          this.graphGenerateCompleteObservable.next(true);
          this.common.setLoader(false);
        });
      }
    );
  }

  graphFilter(filter_by){
    this.formModel.filter_by === filter_by;
    // Reset form and to Date and intervel variable
    let differance = this.diff_minutes(new Date(this.queryEndDate), this.queryMaxDate);
        // console.log('Differ', differance);
        if(differance > 0){
          this.formModel.filter_by = filter_by;
          if(filter_by == '10s'){
            var limitTime = 10 * 60 * 1000; /* ms */
            this.queryMaxDate = new Date();
            this.queryMinDate = new Date();
            this.formModel.formDateTime = new Date(this.queryMaxDate.getTime() - limitTime).toJSON();
            this.formModel.toDateTime = new Date().toJSON();
          }
          else if(filter_by == '1h'){
            var limitTime = 1440 * 60 * 1000; /* ms */
            this.queryMaxDate = new Date();
            this.queryMinDate = new Date();
            this.formModel.formDateTime = new Date(this.queryMaxDate.getTime() - limitTime).toJSON();
            this.formModel.toDateTime = new Date().toJSON();
          }
          else {
            this.formModel.formDateTime = new Date(this.queryStartDate).toJSON();
            this.formModel.toDateTime = new Date(this.queryEndDate).toJSON();
          }

          // this.generateAnalyticalGraph();
        }
        else{
          if(filter_by == '60s'){
            var limitTime = 60 * 60 * 1000; /* ms */
            this.queryMaxDate = new Date(this.queryEndDate);
            this.queryMinDate = new Date();
            this.formModel.formDateTime = new Date(this.queryMaxDate.getTime() - limitTime).toJSON();
            this.formModel.toDateTime = new Date(this.queryEndDate).toJSON();
          }
          else if(filter_by == '1h'){
            var limitTime = 1440 * 60 * 1000; /* ms */
            this.queryMaxDate = new Date();
            this.queryMinDate = new Date();
            this.formModel.formDateTime = new Date(this.queryMaxDate.getTime() - limitTime).toJSON();
            this.formModel.toDateTime = new Date(this.queryEndDate).toJSON();
          }
          else {
            this.formModel.formDateTime = new Date(this.queryStartDate).toJSON();
            this.formModel.toDateTime = new Date(this.queryEndDate).toJSON();
          }

          // this.generateAnalyticalGraph();
        }
      
    // this.generateAnalyticalGraph();
    // console.log('analyticalDataFilterBy', this.lineChartData);
    setTimeout(() => {
      this.common.setLoader(true);
    });
    const formData: any = {
      id: this.historyId,
      duration: this.formModel.filter_by,
      from_date: this.formModel.formDateTime,
      to_date: this.formModel.toDateTime
    }
    this.rtt_max = [];
    this.lineChartData.push(
      {"data":this.rtt_max, "label": 'RTT-MAX'}
    );
    let that = this;
    this.anchor.getPeriodicAnalyticalDataGroupBy(formData).subscribe(
      res => {
        if(res['status'] == 1){
          this.ChartDataSets = [];
          this.Label = [];
          if(Object.keys(res['data']).length > 0){
            let count = 0;
            Object.keys(res['data']).forEach(key => {
              let keyname = key;
              let value = res['data'][key];
              if(value.length > 0){
                that.rtt_max = [];
                value.forEach(element => {
                  that.rtt_max.push(element.mean ? element.mean : 0)
                  // that.rtt_max.push(element.mean)

                  // Create Labels
                  let unix_timestamp = element.time
                  // Create a new JavaScript Date object based on the timestamp
                  // multiplied by 1000 so that the argument is in milliseconds, not seconds.
                  var date = new Date(unix_timestamp);
                  // Date part from the timestamp
                  var day = date.getDate();
                  // Month part from the timestamp
                  var month = (date.getMonth() + 1);
                  // Year part from the timestamp
                  var fullyear = date.getFullYear();
                  // Hours part from the timestamp
                  var hours = date.getHours();
                  // Minutes part from the timestamp
                  var minutes = "0" + date.getMinutes();
                  // Seconds part from the timestamp
                  var seconds = "0" + date.getSeconds();

                  // Will display date in 10-06:2020 format
                  var formattedDate = day + '-' + month + '-' + fullyear;
                  // Will display time in 10:30:23 format
                  var formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
                  if((Object.keys(res['data']).length - 1) == count){
                    that.Label.push(formattedDate + '-' + formattedTime);
                    // if(this.formModel.filter_by === '1h' || this.formModel.filter_by === '10s'){
                    //   that.Label.push(formattedDate + '-' +formattedTime);
                    // }else{
                    //   that.Label.push(formattedDate);
                    // }
                    
                  }
                });
              }
              let hostName = that.filterDomainName(keyname);
              that.ChartDataSets.push({"data":that.rtt_max, "label": hostName});
              count ++
            });
            setTimeout(() => {
              // this.graphGenerateCompleteObservable.next(true);
              this.common.setLoader(false);
            });
          }
          this.lineChartData = [];
          this.lineChartLabels = [];
          this.lineChartData = this.ChartDataSets;
          this.lineChartLabels = this.Label;
          // console.log('Final Grapd Data', this.ChartDataSets);
          // console.log('Final Grapd Label', this.Label);

        }
        if(res['status'] == 0){
          this.lineChartData = [];
          setTimeout(() => {
            // this.graphGenerateCompleteObservable.next(false);
            this.common.setLoader(false);
          });
        }
      },
      err => {
        console.log("ERROR");
        console.log(err);
        this.common.openSnackBar('Please try later', '');
        setTimeout(() => {
          // this.graphGenerateCompleteObservable.next(false);
          this.common.setLoader(false);
        });
      }
    );
  }

  csv(){
    // console.log('uyu');
    this.downloadProgressBer = true;
    const formData: any = {
      history_id: this.historyId,
      filename: 'Periodic'
    }
    var current_date = new Date();
		var current_timestamp = current_date.getTime();
    this.anchor.getDownload('Periodic-'+ current_timestamp +'.csv', this.historyId);
    this.anchor.downloadCsvCallBackResult$.subscribe(value =>{
      // console.log('downloadCsvCallBackResult', value);
      if(value){
        // console.log('success');
        this.downloadProgressBer = false;
      }
    });
  }

  showDetails(){
    console.log('Details View');
  }

  printPage() {
    window.print();
  }

  ngOnDestroy() {
		this.subscription && this.subscription.unsubscribe();
	}

}
