import { Component, OnInit, Inject } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { AnchorService } from '../../../services/anchor/anchor.service';
import { CommonService } from '../../../services/common/common.service';
import { AccessibilityService } from '../../../services/accessibility/accessibility.service';
import { AddEditDomainComponent } from '../../../domain/add-edit-domain.component';
import { AddEditHostGroupComponent } from '../../../hostgroup/add-edit-host-group.component';
import { CreateZoneComponent } from '../../create-zone/create-zone.component';
import { MassageDialogModel, MassageBoxComponent } from '../../../global/massage-box/massage-box.component';

@Component({
  selector: 'app-traceroute-execution',
  templateUrl: './traceroute-execution.component.html',
  styleUrls: ['./traceroute-execution.component.css']
})
export class TracerouteExecutionComponent implements OnInit {
  traceRouteExecutionSetup: FormGroup;
  hostid = new FormControl('', [Validators.required]);
  ancherIds = new FormControl('', [Validators.required]);
  // commendName = new FormControl('', [Validators.required]);
  // commendType = new FormControl('', [Validators.required]);
  // commendService = new FormControl('', [Validators.required]);
  // commendZone = new FormControl('', [Validators.required]);
  // runCount = new FormControl('', [Validators.required]);
  // runTime = new FormControl('', [Validators.required]);
  // timeUnit = new FormControl('', [Validators.required]);

  historyMasterId = 0;
  runTimeUnit: any = [];
  serviceList: any = [];
  zoneList: any = [];
  anchorList: any = [];
  hostList: any = [];
  modalData: any;
  region: string = 'kolkata';
  aioriAnchorId: string = '';
  anchorUserId: number;
  lease_id: string = '';
  showLoder: boolean = false;
  domainWiseFormSetArray: any = [];
  tracerouteUpdateArray: any = [];
  selectedAnchorOrZoneRadio = 'anchor'; //default value
  selectedAnchorOrZoneValue = 'anchor'; //default value
  displayZoneArea: boolean = false;
  displatAnchorArea: boolean = true;
  displayTracerouteDomainArea: boolean = true;
  displayTracerouteAnchorArea: boolean = false;
  selectedDomainOrAnchorRadio = 'domain';
  selectedDomainOrAnchorValue = 'domain';
  selectedQueryTypeValue = 'traceroute';

  constructor(
    _formbuilder: FormBuilder,
    public dialogRef: MatDialogRef<TracerouteExecutionComponent>, @Inject(MAT_DIALOG_DATA)
    public commendData: any,
    private dialog: MatDialog,
    private ar: ActivatedRoute,
    private router: Router,
    private common: CommonService,
    private anchor: AnchorService,
    private accessibilityservice: AccessibilityService,
  ) {
    this.traceRouteExecutionSetup = _formbuilder.group({
      hostid: this.hostid,
      ancherIds: this.ancherIds
    });
  }

  ngOnInit() {
    this.getHostList();
    // this.allServiceList();
    // this.allZoneList();
    this.allUserAnchorList();
    this.allZoneList();
  }

  anchorOrZoneRadioChange(e) {
    this.selectedAnchorOrZoneValue = e.value;
    if (e.value === 'zone') {
      this.displayZoneArea = true;
      this.displatAnchorArea = false;
    } else {
      this.displayZoneArea = false;
      this.displatAnchorArea = true;
    }
  }

  tracerouteDomainOrAnchorTypeRadioChange(e){
    console.log('tracerouteDomainOrAnchorTypeRadioChange', e);
    this.selectedDomainOrAnchorValue = e.value;
    if (e.value === 'domain') {
      this.displayTracerouteDomainArea = true;
      this.displayTracerouteAnchorArea = false;
    } else {
      this.displayTracerouteDomainArea = false;
      this.displayTracerouteAnchorArea = true;
    }
  }

  getHostList() {
    this.anchor.getHost().subscribe(
      res => {
        console.log('Host List', res);
        if (res["status"] == 1) {
          this.hostList = res['domain'];
        }
        else {
          this.hostList = res['domain'];
        }
      },
      err => {
        console.log("ERROR");
        console.log(err);
      },
    );
  }

  // allServiceList(){
  //   this.serviceList = [];
  //   this.accessibilityservice.getAllHostGroupList().subscribe(
  //     res => {
  //       if(res['status'] == 1){
  //         this.serviceList = res['hostgroup'];
  //         console.log(this.serviceList);
  //       }
  //       if(res['status'] == 0){
  //         this.serviceList = [];
  //       }
  //     },
  //     err => {
  //       console.log("ERROR");
  //       console.log(err);
  //       this.common.openSnackBar('Please try later', '');
  //     }
  //   );
  // }

  allZoneList() {
    this.zoneList = [];
    this.accessibilityservice.getAllZoneList().subscribe(
      res => {
        if (res['status'] == 1) {
          this.zoneList = res['zone'];
          console.log(this.zoneList);
        }
        if (res['status'] == 0) {
          this.zoneList = [];
        }
      },
      err => {
        console.log("ERROR");
        console.log(err);
        this.common.openSnackBar('Please try later', '');
      }
    );
  }

  allUserAnchorList() {
    this.anchorList = [];
    this.anchor.getUserAnchors().subscribe(
      res => {
        if (res['status'] == 1) {
          this.anchorList = res['anchor'];
          console.log('anchorList', this.anchorList);
        }
        if (res['status'] == 0) {
          this.anchorList = [];
        }
      },
      err => {
        console.log("ERROR");
        console.log(err);
        this.common.openSnackBar('Please try later', '');
      }
    );
  }

  dialogRefTags2: MatDialogRef<CreateZoneComponent> | null;
  goToZoneCreate() {
    // this.closePopup();
    // this.router.navigate(['/zone/map-view']);
    this.dialogRefTags2 = this.dialog.open(CreateZoneComponent, {
      data: {
        id: 0
      },
      disableClose: false,
      width: '90%'
    });
    this.dialogRefTags2.afterClosed().subscribe(result => {
      console.log('afterClosed', result);
      this.allZoneList();
    });
  }

  onSelectionAnchor(event) {
    console.log(event)
    let that = this;
    let anchorID = event.value;
    if (this.anchorList.length > 0) {
      this.anchorList.forEach(element => {
        if (element.id == anchorID) {
          console.log(element)
          that.region = element.location;
          that.aioriAnchorId = element.aiori_anchor_id;
          that.lease_id = element.lease_id;
          that.anchorUserId = element.user;
          console.log('that.region', that.region);
          console.log('that.aioriAnchorId', that.aioriAnchorId);
          console.log('that.lease_id', that.lease_id);
          console.log('that.anchorUserId', that.anchorUserId);
        }
      });
    }
  }

  regularCheckPointUtilization() {
    // Status Are
    // user have no subscription points earn entry = 0
    // successfully deduct point status = 1
    // remaining_points is less then deduct point status = 2
    if (this.traceRouteExecutionSetup.valid) {
      this.showLoder = true;
      let anchor_user_id_number: number = 0;
      anchor_user_id_number = this.anchorUserId;
      let anchor_user_id_arr: any = [];
      // if(this.displayZoneArea){
      //   this.domainWiseFormSetArray.forEach(element => {
      //     anchor_user_id_arr.push(element.user_anchor_id)
      //   });
      // }
      // if(this.displatAnchorArea){
      //   anchor_user_id_number = this.anchorUserId;
      // }
      const formData: any = {
        command_name: 'traceroute',
        run_time: 1,
        utilize_point: 5,
        anchor_user_id: anchor_user_id_number,
        anchor_user_ids: anchor_user_id_arr
      }
      console.log('regularCheckPointUtilization', formData);
      this.anchor.utilizePoint(formData).subscribe(
        res => {
          if (res['status'] == 1) {
            // successfully deduct point
            this.showLoder = false;
            this.regularSaveHistory();
          }
          else if (res['status'] == 2) {
            this.showLoder = false;
            // remaining_points is less then deduct point
            this.dialogRef.close();
            // open massage popup
            const message = 'You have no sufficient points to execute this command. Please contact to administrater.'; // generate massage
            const dialogData = new MassageDialogModel("Insufficient Points", message); // Call Global massage box with parameters, 
            const dialogRefMsg = this.dialog.open(MassageBoxComponent, { // 2 parameters are givin
              maxWidth: "400px",
              data: dialogData
            });
            dialogRefMsg.afterClosed().subscribe(dialogResult => { // After close the massage box return true or false base on button click
            });
          }
          else {
            this.showLoder = false;
            // user have no subscription points earn
            this.dialogRef.close();
            // open massage popup
            const message = 'You have no points to execute this command. Please contact to administrater.'; // generate massage
            const dialogData = new MassageDialogModel("No Points", message); // Call Global massage box with parameters, 
            const dialogRefMsg = this.dialog.open(MassageBoxComponent, { // 2 parameters are givin
              maxWidth: "400px",
              data: dialogData
            });
            dialogRefMsg.afterClosed().subscribe(dialogResult => { // After close the massage box return true or false base on button click
            });
          }
        },
        err => {
          this.showLoder = false;
          console.log("ERROR");
          console.log(err);
          this.common.openSnackBar('Please try later', '');
        },
      );
    }
  }

  regularSaveHistory() {
    console.log(this.traceRouteExecutionSetup.valid)
    if (this.traceRouteExecutionSetup.valid) {
      this.showLoder = true;
      // let anchors = [];
      // anchors.push();
      const formData: any = {
        cmd_value: 'traceroute',
        cmd_type: 'traceroute',
        region: this.region,
        anchor_ids: this.traceRouteExecutionSetup.value.ancherIds,
        hosts: this.traceRouteExecutionSetup.value.hostid
      }
      console.log('Test', formData);
      const reqData = JSON.stringify(formData);
      /// To Do points check in user account
      this.anchor.postCommendHistory(reqData).subscribe(
        res => {
          // console.log('History Details', res);
          if (res['status'] == 1) {
            this.showLoder = false;
            let history_id = res['obj_id']
            console.log('Return History Id', history_id);
            this.postTraceRouteExecutionToAiro(history_id);
          }
          else {
            this.showLoder = false;
            this.common.openSnackBar('Please try later', '');
          }
        },
        err => {
          this.showLoder = false;
          console.log("ERROR");
          console.log(err);
        }
      );
    }
  }

  postTraceRouteExecutionToAiro(id) {
    this.showLoder = true;
    let historyId = id;
    let ancherids: any = [];
    ancherids.push(this.aioriAnchorId);
    const reqtracerouteData: any = {
      host: this.traceRouteExecutionSetup.value.hostid,
      anchors: ancherids
    }
    const post_param = JSON.stringify(reqtracerouteData);
    console.log(reqtracerouteData)
    console.log(post_param)
    console.log(this.lease_id)
    this.anchor.postTraceRouteToAIRO(post_param, this.lease_id).subscribe(
      res => {
        this.showLoder = false;
        console.log('Execution Details', res);
        this.updateCommendHistory(historyId, res['id'], res['status'], 'running')
        // if(res['status'] == 1){
        //   this.postCommendRequestToAiro();
        // }
        // else{
        //   this.common.openSnackBar('Please try later', '');
        // }
      },
      err => {
        this.showLoder = false;
        console.log("ERROR");
        console.log(err);
        // this.updateRegularCommendHistory(historyId, '', '', 'failure')
        this.common.openSnackBar('No connection error from AIRO Server', '');
      }
    );
  }

  updateCommendHistory(his_id, qry_id, status, execution_status) {
    this.showLoder = true;
    const reqData: any = {
      id: his_id,
      query_id: qry_id,
      status: execution_status
    }
    this.anchor.putCommendHistory(reqData).subscribe(
      res => {
        console.log('Execution Details', res);
        if (res['status'] == 1) {
          this.showLoder = false;
          let obj = {
            'execution': 'success',
            'type': 'traceroute'
          }
          this.dialogRef.close(obj);
        }
        else {
          this.showLoder = false;
          this.common.openSnackBar('Please try later', '');
        }
      },
      err => {
        this.showLoder = false;
        console.log("ERROR");
        console.log(err);
        this.common.openSnackBar('No connection error from AIRO Server', '');
      }
    );
  }

  updateTraceRouteCommendHistory() {
    if (this.tracerouteUpdateArray.length > 0) {
      this.showLoder = true;
      const reqData: any = {
        update_set: this.tracerouteUpdateArray
      }
      // After return AIRO Server update history table
      this.anchor.putPeriodicCommendHistory(reqData).subscribe(
        res => {
          // console.log('Execution Details', res);
          if (res['status'] == 1) {
            this.showLoder = false;
            let obj = {
              'execution': 'success',
              'type': this.selectedQueryTypeValue
            }
            this.dialogRef.close(obj);
          }
          else {
            this.showLoder = false;
            this.common.openSnackBar('Please try later', '');
          }
        },
        err => {
          this.showLoder = false;
          console.log("ERROR");
          console.log(err);
          this.common.openSnackBar('No connection error from AIRO Server', '');
        }
      );
    }
    else {
      this.showLoder = false;
      this.common.openSnackBar('Update payload is blank.', '');
    }
  }

  postTraceRouteExecutionToAiroMN(id) {
    let that = this;
    this.showLoder = true;
    let historyId = id;
    let tracerouteData: any = {};
    if (this.domainWiseFormSetArray.length > 0) {
      this.domainWiseFormSetArray.forEach(element => {
        console.log('Element', element)
        let lease_id = element.lease_id;
        const reqtracerouteData: any = {
          host: element.hosts,
          anchors: element.anchors
        }
        console.log('Element', reqtracerouteData)
        const post_param = JSON.stringify(reqtracerouteData);
        //Post Command To AIORI SERVER
        this.anchor.postTraceRouteToAIRO(post_param, lease_id).subscribe(
          res => {
            that.tracerouteUpdateArray.push(
              {
                parent_id: element.parent_id,
                chield_id: element.chield_id,
                query_id: res['id'],
                status: "running"
              }
            );
            // check total query post is complete or not
            if (that.domainWiseFormSetArray.length === that.tracerouteUpdateArray.length) {
              that.showLoder = false;
              that.updateTraceRouteCommendHistory();
            }
          },
          err => {
            this.showLoder = false;
            console.log("ERROR");
            console.log(err);
            // this.updateRegularCommendHistory(historyId, '', '', 'failure')
            this.common.openSnackBar('No connection error from AIRO Server', '');
          }
        );
      });
    }
  }

  CheckPointUtilization() {
    console.log('check points')
    let massageTitle = 'No Points';
    // Generate update json data for insufficient point
    this.showLoder = true;
    const putData = {
      id: this.historyMasterId,
      use_point_status: "insufficient point",
      status: "failure"
    }
    // calculate howmany points will be deduct
    let deduct_points = 0
    if (this.domainWiseFormSetArray.length > 0) {
      // deduct_points = (parseInt(this.domainWiseFormSetArray.length) * 5);
      deduct_points = 5;
    }

    // Status Are
    // user have no subscription points earn entry = 0
    // successfully deduct point status = 1
    // remaining_points is less then deduct point status = 2
    if (this.domainWiseFormSetArray.length > 0) {
      let anchor_user_id_number: number = 0;
      let anchor_user_id_arr: any = [];
      if (this.displayZoneArea) {
        this.domainWiseFormSetArray.forEach(element => {
          anchor_user_id_arr.push(element.user_anchor_id)
        });
      }
      if (this.displatAnchorArea) {
        anchor_user_id_number = this.anchorUserId;
      }
      const formData: any = {
        command_name: 'traceroute',
        run_time: 1,
        utilize_point: deduct_points,
        anchor_user_id: anchor_user_id_number,
        anchor_user_ids: anchor_user_id_arr
      }
      this.anchor.utilizePoint(formData).subscribe(
        res => {
          if (res['status'] == 1) {
            this.showLoder = false;
            // successfully deduct point
            this.postTraceRouteExecutionToAiroMN(this.historyMasterId);
          }
          else {
            this.showLoder = false;
            if (res['status'] == 2) {
              massageTitle = 'Insufficient Points';
            }
            //execution history update status
            this.anchor.commendHistoryUpdateById(putData).subscribe(
              res => {
                this.dialogRef.close();
                // open massage popup
                const message = 'You have no sufficient points to execute this command. Please contact to administrater.'; // generate massage
                const dialogData = new MassageDialogModel(massageTitle, message); // Call Global massage box with parameters, 
                const dialogRefMsg = this.dialog.open(MassageBoxComponent, { // 2 parameters are givin
                  maxWidth: "400px",
                  data: dialogData
                });
                dialogRefMsg.afterClosed().subscribe(dialogResult => { // After close the massage box return true or false base on button click
                });
              },
              err => {
                this.showLoder = false;
                console.log("ERROR");
                console.log(err);
                this.common.openSnackBar('Please try later', '');
              }
            );
          }
        },
        err => {
          console.log("ERROR");
          console.log(err);
          this.common.openSnackBar('Please try later', '');
        },
      );
    } else {
      this.showLoder = false;
    }
  }



  submitExecution() {
    console.log('submitExecution', this.traceRouteExecutionSetup.valid);
    if (this.traceRouteExecutionSetup.valid) {
      // this.regularCheckPointUtilization();
      console.log('this.traceRouteExecutionSetup.value', this.traceRouteExecutionSetup.value);
      let zoneid: any = [];
      let anchorid: any = [];
      let reqData: any = {};
      // For Domain Anchor Area
      if (this.selectedQueryTypeValue == "traceroute" && this.selectedDomainOrAnchorValue == "domain" && this.selectedAnchorOrZoneValue == "anchor") {
        anchorid.push(this.traceRouteExecutionSetup.value.ancherIds);
        // Set Value modal field
        reqData = {
          cmd_value: 'traceroute',
          cmd_type: this.selectedQueryTypeValue,
          type_fo_query: "domain_anchor_traceroute",
          host_id: this.traceRouteExecutionSetup.value.hostid,
          zone_id: zoneid,
          anchor_id: anchorid,
          run_time: '1',
          run_count: '1',
          time_unit: 'minute'
        }
        console.log('Payload set Anchor', reqData);

      }
      // For Domain Zone Area
      if (this.selectedQueryTypeValue == "traceroute" && this.selectedDomainOrAnchorValue == "domain" && this.selectedAnchorOrZoneValue == "zone") {
        zoneid.push(this.traceRouteExecutionSetup.value.ancherIds);
        // Set Value modal field
        reqData = {
          cmd_value: 'traceroute',
          cmd_type: this.selectedQueryTypeValue,
          type_fo_query: "domain_zone_traceroute",
          host_id: this.traceRouteExecutionSetup.value.hostid,
          zone_id: zoneid,
          anchor_id: anchorid,
          run_time: '1',
          run_count: '1',
          time_unit: 'minute'
        }
        console.log('Payload set Zone', reqData);

      }



      // this.postTraceRouteExecutionToAiro(141);
      /// Post Data to our database through API
      this.anchor.postTracerouteDomainAnchorOrZoneWiseCommendHistory(reqData).subscribe(
        res => {
          if (res['status'] == 1) {
            this.showLoder = false;
            this.historyMasterId = + res['serialize_obj'][0]['parent_id'];
            this.domainWiseFormSetArray = res['serialize_obj'];
            console.log('this.domainWiseFormSetArray', this.domainWiseFormSetArray);
            this.CheckPointUtilization();
            // this.postCommendRequestToAiro();
            console.log(this.historyMasterId, this.domainWiseFormSetArray);
          }
          if (res['status'] == 0) {
            this.showLoder = false;
            this.domainWiseFormSetArray = [];
          }
        },
        err => {
          this.showLoder = false;
          console.log("ERROR");
          console.log(err);
          this.common.openSnackBar('Please try later', '');
        }
      );
    }
  }

  dialogRefTags: MatDialogRef<AddEditDomainComponent> | null;
  goToDomainList(): void {
    // this.closePopup();
    this.dialogRefTags = this.dialog.open(AddEditDomainComponent, {
      data: {
        id: 0
      },
      disableClose: true
    });
    this.dialogRefTags.afterClosed().subscribe(result => {
      console.log('afterClosed', result);
      this.getHostList();
    });
    // this.router.navigate(['/domain-list']);
  }

  closePopup() {
    let obj = {
      'execution': 'falure',
      'type': 'none'
    }
    this.dialogRef.close(obj);
  }

}
