import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
// import { CommonService } from '../../services/common/common.service';
import { LoginsignupService } from '../../services/login_signup/loginsignup.service';
import { ConfigurationService } from '../../services/config/configuration.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class AdminLoginComponent implements OnInit {
  	userLogin: FormGroup;
	email = new FormControl('', [
		Validators.required,
		Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
	]);
	password = new FormControl('', [Validators.required]);
	loginData: any;
	checkboxChecked = false;
	isChecked = false;
	show_errormsg: boolean = false;
	error_msg: string = '';
	show: boolean = false;
	showLoder: boolean = false;
	constructor(
		fb: FormBuilder, 
			// private common: CommonService,
			private loginsignup: LoginsignupService,
			private router: Router, 
			private ar: ActivatedRoute, 
			private config: ConfigurationService, 
			private cookieService: CookieService,
	) {
		this.userLogin = fb.group({
				email: this.email,
				password: this.password
			});

			console.log('This Is Login Page');
	}

	ngOnInit() {
		if(this.cookieService.check('cookieBrandUsername') && this.cookieService.check('cookieBrandPassword')) {
				this.userLogin.patchValue({
					email: this.cookieService.get('cookieBrandUsername'),
					password : this.cookieService.get('cookieBrandPassword'),
				});
				this.isChecked = true;
				this.checkboxChecked = true;
			} else {
				this.isChecked = false;
				this.checkboxChecked = false;
			}
	}

	passwordControll() {
	    this.show = !this.show;
	}

	toggleCheckbox(event) {
		
		if ( event.checked ) {
        	this.checkboxChecked = true;
   		} else{
   			this.checkboxChecked = false;
   		}
	}

	Login(){
		if(this.userLogin.valid){
			this.showLoder = true;
			const reqData: any = {
				email: this.userLogin.value.email,
				password: this.userLogin.value.password
			}
			this.loginsignup.authLogin(reqData).subscribe(
				res => {
					console.log('Login Responce', res);
					if(res["status"] == 1) { // success Login 
						this.loginData = JSON.stringify(res);
						localStorage.setItem('adminLoginData', this.loginData);
						if(this.checkboxChecked) {
							this.cookieService.set('cookieBrandUsername', this.userLogin.value.email);
							this.cookieService.set('cookieBrandPassword', this.userLogin.value.password);
						} else {
							this.cookieService.delete('cookieBrandUsername');
							this.cookieService.delete('cookieBrandPassword');
						}
						// Get JWT Token From API
						this.getTokenPair();
						this.loginsignup.tokenOverwriteCallBackResult$.subscribe((value)=>{
							this.showLoder = false;
							if(value === 'success'){
								this.router.navigate(['/admin/dashboard']);
							}
						});
					} 
					if(res["status"] == 0){
						this.showLoder = false;
						this.show_errormsg = true;
						this.error_msg = res["msg"]
					}
				},
				err => {
					// console.log("ERROR");
					// console.log(err);
					console.log('ERROR', err.status);
					console.log('MSG', err.msg);
					this.show_errormsg = true;
					this.error_msg = "Invalid Email or Password";
					// this.common.openSnackBar('Some error occured, please try again later', '');
				},
			);
		}
	}

	getTokenPair(){
		let userData = JSON.parse(localStorage.getItem('adminLoginData'));
		if(userData != null || userData!= undefined){
			const reqData: any = {
				username: userData.username,
				password: this.userLogin.value.password
			}
			this.loginsignup.admintokenPairOverwriteInLocalstorage(reqData);
		}else{
			// this.common.openSnackBar('Localstorage is not set, please it set first', '');
			console.log('Localstorage is not set, please it set first');
		}
	}

	goTo(){
		console.log('Fine');
		this.router.navigate(['/signup']);
	}
}
