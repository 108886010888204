import { Component, OnInit, Inject, NgZone, ElementRef, ViewChild } from '@angular/core';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AnchorService } from '../../services/anchor/anchor.service';
import { CommonService } from '../../services/common/common.service';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';

@Component({
  selector: 'app-traceroutevisualiser',
  templateUrl: './traceroutevisualiser.component.html',
  styleUrls: ['./traceroutevisualiser.component.css']
})
export class TraceroutevisualiserComponent implements OnInit {
  userdetails: any;
  history_id: number;
  command_query_id: any;
  traceRouteArr: any;

  // For Map
  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  location: string;
  private geoCoder;
  @ViewChild('search')
  public searchElementRef: ElementRef;
  lineChartData: any = [
    { data: [], label: '' }
  ];
  ChartDataSets: any = [];
  lineChartLabels: any = [];
  Label: any = [];

  lineChartOptions = {
    responsive: true,
  };

  lineChartColors: Color[] = [
    {
      borderColor: 'red',
      // backgroundColor: 'rgba(255, 255, 255, 0.8)',
      backgroundColor: 'rgba(192,192,192,0.2)',
    },
  ];

  lineChartLegend = true;
  lineChartPlugins = [];
  lineChartType = 'line';

  rtt_max: any = [];
  rtt_avg: any = [];
  rtt_min: any = [];


  // Test Polygon
  // public latitude: number;
  // public longitude: number;
  public maxSpeed: number;
  // public zoom: number;
  public polyline: Array<any>;
  public polylines: Array<any>;

  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    public dialogRef: MatDialogRef<TraceroutevisualiserComponent>, @Inject(MAT_DIALOG_DATA)
    public histort_data: any,
    private dialog: MatDialog,
    private anchor: AnchorService,
    private common: CommonService,
  ) {
    this.latitude = parseFloat('22.5726');
    this.longitude = parseFloat('88.3639');
    this.zoom = 2;
    this.history_id = + histort_data.id;
    this.command_query_id = histort_data.query_id;
    // console.log(this.histort_data);
  }

  ngOnInit() {
    this.getTrachrouteData();
    // this.setRegisterLocation();
    // this.setCurrentLocation();
    //load Places Autocomplete
    // this.mapsAPILoader.load().then(() => {
    //   // this.setCurrentLocation();
    //   this.setRegisterLocation();
    //   this.geoCoder = new google.maps.Geocoder;

    //   // let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
    //   // autocomplete.addListener("place_changed", () => {
    //   //   this.ngZone.run(() => {
    //   //     //get the place result
    //   //     let place: google.maps.places.PlaceResult = autocomplete.getPlace();

    //   //     //verify result
    //   //     if (place.geometry === undefined || place.geometry === null) {
    //   //       return;
    //   //     }

    //   //     //set latitude, longitude and zoom
    //   //     this.latitude = place.geometry.location.lat();
    //   //     this.longitude = place.geometry.location.lng();
    //   //     this.zoom = 2;
    //   //     this.getAddress(this.latitude, this.longitude);
    //   //   });
    //   // });
    // });

    //set google maps defaults
    this.zoom = 2;
    this.maxSpeed = 40;
    this.latitude = parseFloat('22.5726');;
    this.longitude = parseFloat('88.3639');

    this.polyline = [
      {
        latitude: 19.0760,
        longitude: 72.8777,
        speed: 1
      },
      {
        latitude: 22.5726,
        longitude: 88.3639,
        speed: 2
      },
      {
        latitude: 13.0827,
        longitude: 80.2707,
        speed: 3
      },
      {
        latitude: 28.7041,
        longitude: 77.1025,
        speed: 4
      }
      // {
      //   latitude: 12.9716,
      //   longitude: 77.5946,
      //   speed: 5
      // },
      // {
      //   latitude: 26.1445,
      //   longitude: 91.7362,
      //   speed: 6
      // }
    ]
    this.polylines = this.rebuildPolylines();


    //set current position
    // this.setCurrentPosition();

    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {

    });
  }

  private rebuildPolylines() {
    let polylines = [];
    let i = 0;
    let newPolyline = { path: [], color: 'blue' };
    for (let point of this.polyline) {
      console.log(point)
      newPolyline.path.push(point);
      const speedChanged = this.polyline[i + 1] && (point.speed < this.maxSpeed && this.polyline[i + 1].speed < this.maxSpeed) || (point.speed > this.maxSpeed && this.polyline[i + 1].speed > this.maxSpeed)
      if (point.speed > this.maxSpeed) {
        newPolyline.color = 'blue';
      }
      if (speedChanged) {
        newPolyline.path.push(this.polyline[i + 1]);
        polylines.push(newPolyline);
        newPolyline = { path: [], color: 'blue' };
      }
      i++;
    }
    console.log(polylines);
    return polylines;

  }

  private setCurrentPosition() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 2;
      });
    }
  }

  // Get register location
  setRegisterLocation() {
    const formData = {
      anchor_id: 12
    }
    this.anchor.getAnchorDetails(formData).subscribe(
      res => {
        if (res['status'] == 1) {
          // console.log('Anchor Location', res);
          this.latitude = parseFloat(res['anchor_details']['latitude']);
          this.longitude = parseFloat(res['anchor_details']['longitude']);
          this.zoom = 4;
          this.getAddress(this.latitude, this.longitude);
        }
        else {
          // console.log('Anchor Location', res);
          this.setCurrentLocation();
        }
      },
      err => {
        console.log("ERROR");
        console.log(err);
        this.common.openSnackBar('Please try later', '');
      }
    );
  }

  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 2;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  markerDragEnd($event: MouseEvent) {
    console.log($event);
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      // console.log(results);
      // console.log(status);
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 2;
          this.address = results[0].formatted_address;
          // this.location = results[0].address_components[5].long_name;
          results[0].address_components.forEach(element => {
            Object.keys(element).forEach(key => {
              let keyname = key;
              let value = element[key];
              if (keyname == 'types') {
                if (value.length > 0) {
                  if (value[0] == 'locality') {
                    // console.log('address_components', keyname);
                    // console.log('address_components value', value[0]);
                    // console.log('address_components Area name', element.long_name);
                    this.location = element.long_name;
                  }
                }
              }
            });
            // if(element.)
          });
          // console.log('this.location', this.location);
        } else {
          window.alert('No results found');
        }
      } else {
        // window.alert('Geocoder failed due to: ' + status);
      }

    });
  }

  getHistoryDetails() {

  }

  getTrachrouteData() {
    this.anchor.getTraceRouteDataFromAIRO(this.command_query_id).subscribe(
      res => {
        let hostName = '';
        // console.log('Graph Data', res);
        // this.ChartDataSets = [];
        // this.rtt_avg = [];
        // this.Label = [];
        this.lineChartData.push(
          { "data": this.rtt_avg, "label": 'RTT-AVG' }
        );
        let that = this;
        if (res['data'].length > 0) {
          this.traceRouteArr = res['data'];
          this.ChartDataSets = [];
          this.Label = [];
          this.rtt_avg = [];
          res['data'].forEach(element => {
            // console.log('Data Is', element);
            that.rtt_avg.push(element.avg_rtt);
            hostName = element.node
            that.Label.push(element.node);
          });
          // console.log('AVG', that.rtt_avg);
          // console.log('LABLE', that.Label);
          that.ChartDataSets.push({ "data": that.rtt_avg, "label": hostName });
          this.lineChartData = [];
          this.lineChartLabels = [];
          this.lineChartData = this.ChartDataSets;
          this.lineChartLabels = this.Label;

          this.getIpDetails(this.traceRouteArr);
        }
      },
      err => {
        console.log("ERROR");
        console.log(err);
        this.common.openSnackBar('Please try later', '');
      }
    );
  }

  getIpDetails(arr) {
    arr.forEach(element => {
      this.common.getGEOLocation(element.node).subscribe(
        res => {
          // console.log('GEO IP DETAILS', res);
        },
        err => {
          console.log("ERROR");
          console.log(err);
          this.common.openSnackBar('Please try later', '');
        }
      );
    });
  }

  closePopup() {
    this.dialogRef.close();
  }

}
