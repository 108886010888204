import { NgModule } from '@angular/core';
import { ModuleWithProviders} from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CommendComponent } from './commend.component';
import { ExecutionSetupComponent } from '../anchor/execution-setup/execution-setup.component';
import { PeriodicQueryComponent } from '../anchor/periodic-query/periodic-query.component';

const COMMAND_ROUTER: Routes = [
  {
    path: '', 
    component: CommendComponent,
    children: [
      { path:'execute', component: CommendComponent },
    ]
  },
];

export const CommandRouting: ModuleWithProviders = RouterModule.forChild(COMMAND_ROUTER);