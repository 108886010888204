import { Component, OnInit, Inject } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { AnchorService } from '../../services/anchor/anchor.service';
import { CommonService } from '../../services/common/common.service';
import { AccessibilityService } from '../../services/accessibility/accessibility.service';
import { AddEditDomainComponent } from '../../domain/add-edit-domain.component';
import { AddEditHostGroupComponent } from '../../hostgroup/add-edit-host-group.component';
import { CreateZoneComponent } from '../create-zone/create-zone.component';
import { MassageDialogModel, MassageBoxComponent } from '../../global/massage-box/massage-box.component';

@Component({
  selector: 'app-executions',
  templateUrl: './executions.component.html',
  styleUrls: ['./executions.component.css']
})
export class ExecutionsComponent implements OnInit {
  executionSetup: FormGroup;
  // commendName = new FormControl('', [Validators.required]);
  // commendType = new FormControl('', [Validators.required]);
  commendService = new FormControl('', [Validators.required]);
  commendZone = new FormControl('', [Validators.required]);
  runCount = new FormControl('', [Validators.required]);
  runTime = new FormControl('', [Validators.required]);
  timeUnit = new FormControl('', [Validators.required]);
  intervalUnit = new FormControl('', [Validators.required]);
  intervalTime = new FormControl('', [Validators.required]);

  historyMasterId = 0;
  runTimeUnit: any = [];
  intervalTimeUnit: any = [];
  intervalTimes: any = [];
  intervalSeconds: any = [];
  intervalMinuts: any = [];
  intervalHours: any = [];
  serviceList: any = [];
  zoneList: any = [];
  anchorList: any = [];
  hostList: any = [];
  modalData: any;
  region: string = 'kolkata';
  aioriAnchorId: string = '';
  anchorUserId: number;
  lease_id: string = '';
  domainWiseFormSetArray: any = [];
  periodicFormSetArray: any = [];
  periodicUpdateArray: any = [];
  displayZoneArea: boolean = false;
  displatAnchorArea: boolean = true;
  displayDomainList: boolean = true;
  displayServiceList: boolean = false;
  displayTimeArea: boolean = false;
  displayIntervalArea: boolean = false;
  displayRegularTimeArea: boolean = false;
  showLoder: boolean = false;
  selectedRadio = 'anchor'; //default value
  selectedAnchorOrZoneValue = 'anchor'; //default value
  selectedServiceValue = 'domain';
  selectedQueryTypeValue = 'regular';
  leaseId: number = 0;
  runTimeMinuteError: boolean = false;

  constructor(
    _formbuilder: FormBuilder,
    public dialogRef: MatDialogRef<ExecutionsComponent>, @Inject(MAT_DIALOG_DATA)
    public commendData: any,
    private dialog: MatDialog,
    private ar: ActivatedRoute,
    private router: Router,
    private common: CommonService,
    private anchor: AnchorService,
    private accessibilityservice: AccessibilityService,
  ) {
    this.executionSetup = _formbuilder.group({
      commendName: 'ping',
      commendType: 'regular',
      commendService: this.commendService,
      commendZone: this.commendZone,
      runTime: this.runTime,
      runCount: this.runCount,
      timeUnit: this.timeUnit,
      intervalUnit: this.intervalUnit,
      intervalTime: this.intervalTime
    });
  }

  ngOnInit() {
    this.generateIntervalTimeLoop();
    console.log('this.commendService', this.commendService);
    this.executionSetup.setValue({
      commendName: 'ping',
      commendType: 'regular',
      commendService: [],
      commendZone: [],
      runTime: '1',
      runCount: '3',
      timeUnit: 'minute',
      intervalUnit: 'second',
      intervalTime: '10'
    });

    this.runTimeUnit = [
      { name: 'Minute', value: 'minute' },
      { name: 'Hour', value: 'hour' },
      { name: 'Day', value: 'day' }
    ]
    this.intervalTimeUnit = [
      { name: 'Second', value: 'second' },
      { name: 'Minute', value: 'minute' },
      { name: 'Hour', value: 'hour' }
    ];
    this.getHostList();
    this.allServiceList();
    this.allZoneList();
    this.allUserAnchorList();
  }

  generateIntervalTimeLoop(){
    this.intervalSeconds = [];
    this.intervalMinuts = [];
    this.intervalHours = [];
    for(var i=1; i <= 5; i++){
      // console.log('intervalSeconds', i*10);
      if(i*10 == 30 || i*10 == 50){
        this.intervalSeconds.push( { name: i*10 + ' seconds', value:  i*10});
      }
      this.intervalMinuts.push( { name: i*10 + ' minutes', value:  i*10});
    }
    for(var c=1; c <= 24; c++){
      // console.log('intervalHours', c);
      if(c == 1 || c == 3 || c == 6 || c == 12 || c == 24){
        this.intervalHours.push( { name: c + ' hour', value:  c});
      }
    }
    this.intervalTimes = this.intervalSeconds;
  }

  intervalTimeUnitChange(e){
    console.log('event', e);
    this.intervalTimes = [];
    if(e.value == 'second'){this.intervalTimes = this.intervalSeconds;}
    if(e.value == 'minute'){this.intervalTimes = this.intervalMinuts;}
    if(e.value == 'hour'){this.intervalTimes = this.intervalHours;}
  }

  checkLength(event: any) {
    console.log('KEY INPUT', event);
    const pattern = /^[0-9]*$/;
    if (!pattern.test(event.target.value)) {//enter only numeric
      event.target.value = event.target.value.replace(/[^0-9]/g, "");
    }
    if (event.target.value == 0) {  //avoid 0
      event.target.value = event.target.value.replace(event.target.value, "");
    }
    if (event.target.value > 999) {//to block user enter more than 999
      event.target.value = event.target.value.replace(event.target.value, event.target.value.slice(0, 3));
    }
  }

  getHostList() {
    this.anchor.getHost().subscribe(
      res => {
        console.log('Host List', res);
        if (res["status"] == 1) {
          this.hostList = res['domain'];
        }
        else {
          this.hostList = res['domain'];
        }
      },
      err => {
        console.log("ERROR");
        console.log(err);
      },
    );
  }

  allServiceList() {
    this.serviceList = [];
    this.accessibilityservice.getAllHostGroupList().subscribe(
      res => {
        if (res['status'] == 1) {
          this.serviceList = res['hostgroup'];
          console.log(this.serviceList);
        }
        if (res['status'] == 0) {
          this.serviceList = [];
        }
      },
      err => {
        console.log("ERROR");
        console.log(err);
        this.common.openSnackBar('Please try later', '');
      }
    );
  }

  allZoneList() {
    this.zoneList = [];
    this.accessibilityservice.getAllZoneList().subscribe(
      res => {
        if (res['status'] == 1) {
          this.zoneList = res['zone'];
          console.log(this.zoneList);
        }
        if (res['status'] == 0) {
          this.zoneList = [];
        }
      },
      err => {
        console.log("ERROR");
        console.log(err);
        this.common.openSnackBar('Please try later', '');
      }
    );
  }

  allUserAnchorList() {
    this.anchorList = [];
    this.anchor.getUserAnchors().subscribe(
      res => {
        if (res['status'] == 1) {
          this.anchorList = res['anchor'];
          console.log('anchorList', this.anchorList);
        }
        if (res['status'] == 0) {
          this.anchorList = [];
        }
      },
      err => {
        console.log("ERROR");
        console.log(err);
        this.common.openSnackBar('Please try later', '');
      }
    );
  }


  queryTimeTypeRadioChange(e) {
    console.log(e);
    this.selectedQueryTypeValue = e.value;
    this.displayServiceList = false;
    this.displayDomainList = true;
    if (e.value == 'regular') {
      this.displayTimeArea = false;
      this.displayIntervalArea = false;
      this.displayRegularTimeArea = true;
    }
    if (e.value == 'periodic') {
      this.displayTimeArea = true;
      this.displayIntervalArea = true;
      this.displayRegularTimeArea = false;
    }
  }

  domainOrServiceRadioChange(e) {
    this.selectedServiceValue = e.value;
    if (e.value == 'domain') {
      this.displayServiceList = false;
      this.displayDomainList = true;
    }
    if (e.value == 'service') {
      this.displayServiceList = true;
      this.displayDomainList = false;
    }
  }

  anchorOrZoneRadioChange(e) {
    this.selectedAnchorOrZoneValue = e.value;
    if (e.value === 'zone') {
      this.displayZoneArea = true;
      this.displatAnchorArea = false;
    } else {
      this.displayZoneArea = false;
      this.displatAnchorArea = true;
    }
  }

  radioChange(e) {
    // console.log(e);
    if (e.value === 'zone') {
      this.displayZoneArea = true;
      this.displatAnchorArea = false;
    } else {
      this.displayZoneArea = false;
      this.displatAnchorArea = true;
    }
  }

  listRadioChange(e) {
    // console.log(e);
    if (e.value === 'service') {
      this.selectedQueryTypeValue = 'periodic';
      this.displayTimeArea = true;
      this.displayIntervalArea = true;
      this.displayServiceList = false;
      this.displayDomainList = true;
      this.displayRegularTimeArea = false;
      this.executionSetup.reset();
      this.executionSetup.setValue({
        commendName: 'ping',
        commendType: 'periodic',
        commendService: [],
        commendZone: [],
        runTime: this.executionSetup.value.runTime,
        runCount: '3',
        timeUnit: this.executionSetup.value.timeUnit,
        intervalUnit: this.executionSetup.value.intervalUnit,
        intervalTime: this.executionSetup.value.intervalTime
      })
    } else {
      this.selectedQueryTypeValue = 'regular';
      this.displayTimeArea = false;
      this.displayIntervalArea = false;
      this.displayServiceList = false;
      this.displayDomainList = true;
      this.executionSetup.reset();
      this.executionSetup.setValue({
        commendName: 'ping',
        commendType: 'regular',
        commendService: [],
        commendZone: [],
        runTime: '1',
        runCount: '3',
        timeUnit: 'minute',
        intervalUnit: 'second',
        intervalTime: '10'
      });
    }
  }

  queryTypeRadioChange(e) {
    // console.log(e);
    this.selectedQueryTypeValue = e.value;
    if (e.value === 'regular') {
      this.displayTimeArea = false;
      this.displayIntervalArea = false;
      this.displayServiceList = false;
      this.displayDomainList = true;
      this.executionSetup.setValue({
        commendName: 'ping',
        commendType: 'regular',
        commendService: [],
        commendZone: [],
        runTime: '1',
        runCount: '3',
        timeUnit: 'minute',
        intervalUnit: 'second',
        intervalTime: '10'
      });
    } else {
      this.displayTimeArea = true;
      this.displayIntervalArea = true;
      this.displayServiceList = true;
      this.displayDomainList = false;
      this.executionSetup.reset();
      this.executionSetup.setValue({
        commendName: 'ping',
        commendType: 'periodic',
        commendService: [],
        commendZone: [],
        runTime: this.executionSetup.value.runTime,
        runCount: '3',
        timeUnit: this.executionSetup.value.runTime,
        intervalUnit: this.executionSetup.value.intervalUnit,
        intervalTime: this.executionSetup.value.intervalTime,
      })
    }
  }

  onSelectionAnchor(event) {
    console.log(event)
    let that = this;
    let anchorID = event.value;
    if (this.anchorList.length > 0) {
      this.anchorList.forEach(element => {
        if (element.id == anchorID) {
          console.log(element)
          that.region = element.location;
          that.aioriAnchorId = element.aiori_anchor_id;
          that.lease_id = element.lease_id;
          that.anchorUserId = element.user;
          console.log('that.region', that.region);
          console.log('that.aioriAnchorId', that.aioriAnchorId);
          console.log('that.lease_id', that.lease_id);
          console.log('that.anchorUserId', that.anchorUserId);
        }
      });
    }
  }

  /// Regular execution check points avaleable
  regularCheckPointUtilization() {
    // Status Are
    // user have no subscription points earn entry = 0
    // successfully deduct point status = 1
    // remaining_points is less then deduct point status = 2
    if (this.executionSetup.valid) {
      this.showLoder = true;
      let anchor_user_id_number: number = 0;
      anchor_user_id_number = this.anchorUserId;
      let anchor_user_id_arr: any = [];
      // if(this.displayZoneArea){
      //   this.domainWiseFormSetArray.forEach(element => {
      //     anchor_user_id_arr.push(element.user_anchor_id)
      //   });
      // }
      // if(this.displatAnchorArea){
      //   anchor_user_id_number = this.anchorUserId;
      // }
      const formData: any = {
        command_name: this.executionSetup.value.commendName,
        run_time: this.executionSetup.value.runTime,
        utilize_point: 5,
        anchor_user_id: anchor_user_id_number,
        anchor_user_ids: anchor_user_id_arr
      }
      console.log('regularCheckPointUtilization', formData);
      this.anchor.utilizePoint(formData).subscribe(
        res => {
          if (res['status'] == 1) {
            // successfully deduct point
            this.showLoder = false;
            this.regularSaveHistory();
          }
          else if (res['status'] == 2) {
            this.showLoder = false;
            // remaining_points is less then deduct point
            this.dialogRef.close();
            // open massage popup
            const message = 'You have no sufficient points to execute this command. Please contact to administrater.'; // generate massage
            const dialogData = new MassageDialogModel("Insufficient Points", message); // Call Global massage box with parameters, 
            const dialogRefMsg = this.dialog.open(MassageBoxComponent, { // 2 parameters are givin
              maxWidth: "400px",
              data: dialogData
            });
            dialogRefMsg.afterClosed().subscribe(dialogResult => { // After close the massage box return true or false base on button click
            });
          }
          else {
            this.showLoder = false;
            // user have no subscription points earn
            this.dialogRef.close();
            // open massage popup
            const message = 'You have no points to execute this command. Please contact to administrater.'; // generate massage
            const dialogData = new MassageDialogModel("No Points", message); // Call Global massage box with parameters, 
            const dialogRefMsg = this.dialog.open(MassageBoxComponent, { // 2 parameters are givin
              maxWidth: "400px",
              data: dialogData
            });
            dialogRefMsg.afterClosed().subscribe(dialogResult => { // After close the massage box return true or false base on button click
            });
          }
        },
        err => {
          this.showLoder = false;
          console.log("ERROR");
          console.log(err);
          this.common.openSnackBar('Please try later', '');
        },
      );
    }
  }

  PeriodicCheckPointUtilization() {
    console.log('check points')
    let massageTitle = 'No Points';
    // Generate update json data for insufficient point
    this.showLoder = true;
    const putData = {
      id: this.historyMasterId,
      use_point_status: "insufficient point",
      status: "failure"
    }
    // calculate howmany points will be deduct
    let deduct_points = 0
    if (this.domainWiseFormSetArray.length > 0) {
      if(this.displayServiceList){
        deduct_points = (parseInt(this.domainWiseFormSetArray.length) * 5);
      }else{
        deduct_points = 5;
      }
    }
    // Status Are
    // user have no subscription points earn entry = 0
    // successfully deduct point status = 1
    // remaining_points is less then deduct point status = 2
    if (this.domainWiseFormSetArray.length > 0) {
      let anchor_user_id_number: number = 0;
      let anchor_user_id_arr: any = [];
      if(this.displayZoneArea){
        this.domainWiseFormSetArray.forEach(element => {
          anchor_user_id_arr.push(element.user_id)
        });
      }
      if(this.displatAnchorArea){
        anchor_user_id_number = this.anchorUserId;
      }
      const formData: any = {
        command_name: this.executionSetup.value.commendName,
        run_time: this.executionSetup.value.runTime,
        utilize_point: deduct_points,
        anchor_user_id: anchor_user_id_number,
        anchor_user_ids: anchor_user_id_arr
      }
      this.anchor.utilizePoint(formData).subscribe(
        res => {
          if (res['status'] == 1) {
            this.showLoder = false;
            // successfully deduct point
            // if (this.displayZoneArea) {
            //   this.postPeriodicCommendRequestToAiroWithLeaseId();
            // }
            // if (this.displatAnchorArea) {
            //   this.postPeriodicCommendRequestToAiroWithLeaseId();
            // }
            if(this.selectedQueryTypeValue == "regular"){
              this.postPeriodicCommendRequestToAiroWithLeaseId();
            }
            if(this.selectedQueryTypeValue == "periodic"){
              let obj = {
                'execution': 'success',
                'type': this.selectedQueryTypeValue
              }
              this.dialogRef.close(obj);
            }
          }
          // else if(res['status']==2){
          //   // remaining_points is less then deduct point
          //   //execution history update status
          //   this.anchor.commendHistoryUpdateById(putData).subscribe(
          //     res => {
          //       this.dialogRef.close();
          //       // open massage popup
          //       const message = 'You have no sufficient points to execute this command. Please contact to administrater.'; // generate massage
          //       const dialogData = new MassageDialogModel("Insufficient Points", message); // Call Global massage box with parameters, 
          //       const dialogRefMsg = this.dialog.open(MassageBoxComponent, { // 2 parameters are givin
          //         maxWidth: "400px",
          //         data: dialogData
          //       });
          //       dialogRefMsg.afterClosed().subscribe(dialogResult => { // After close the massage box return true or false base on button click
          //       });
          //     },
          //     err => {
          //       console.log("ERROR");
          //       console.log(err);
          //       this.common.openSnackBar('Please try later', '');
          //     }
          //   );
          // }
          else {
            this.showLoder = false;
            if (res['status'] == 2) {
              massageTitle = 'Insufficient Points';
            }
            // user have no subscription points earn
            // this.dialogRef.close();
            // // open massage popup
            // const message = 'You have no points to execute this command. Please contact to administrater.'; // generate massage
            // const dialogData = new MassageDialogModel("No Points", message); // Call Global massage box with parameters, 
            // const dialogRefMsg = this.dialog.open(MassageBoxComponent, { // 2 parameters are givin
            //   maxWidth: "400px",
            //   data: dialogData
            // });
            // dialogRefMsg.afterClosed().subscribe(dialogResult => { // After close the massage box return true or false base on button click
            // });
            //execution history update status
            this.anchor.commendHistoryUpdateById(putData).subscribe(
              res => {
                this.dialogRef.close();
                // open massage popup
                const message = 'You have no sufficient points to execute this command. Please contact to administrater.'; // generate massage
                const dialogData = new MassageDialogModel(massageTitle, message); // Call Global massage box with parameters, 
                const dialogRefMsg = this.dialog.open(MassageBoxComponent, { // 2 parameters are givin
                  maxWidth: "400px",
                  data: dialogData
                });
                dialogRefMsg.afterClosed().subscribe(dialogResult => { // After close the massage box return true or false base on button click
                });
              },
              err => {
                this.showLoder = false;
                console.log("ERROR");
                console.log(err);
                this.common.openSnackBar('Please try later', '');
              }
            );
          }
        },
        err => {
          console.log("ERROR");
          console.log(err);
          this.common.openSnackBar('Please try later', '');
        },
      );
    } else {
      this.showLoder = false;
    }
  }

  submitQueryExecution() {
    this.runTimeMinuteError = false;
    this.selectedAnchorOrZoneValue;
    this.selectedServiceValue;
    this.selectedQueryTypeValue;
    if (this.executionSetup.valid) {
      // For Regular Domain Anchor Area
      if (this.selectedQueryTypeValue == "regular" && this.selectedServiceValue == "domain" && this.selectedAnchorOrZoneValue == "anchor") {
        console.log('One time, domain, anchor');
        // Set Value modal field
        this.executionSetup.setValue({
          commendName: 'ping',
          commendType: 'regular',
          commendService: this.executionSetup.value.commendService,
          commendZone: this.executionSetup.value.commendZone,
          runTime: '1',
          runCount: '3',
          timeUnit: 'minute',
          intervalUnit: 'second',
          intervalTime: '10'
        })
        this.regularCheckPointUtilization();

      }
      // For Periodic Domain Anchor Area
      if (this.selectedQueryTypeValue == "periodic" && this.selectedServiceValue == "domain" && this.selectedAnchorOrZoneValue == "anchor") {
        console.log('time range, domain, anchor')
        if (this.executionSetup.value.timeUnit == 'minute' && this.executionSetup.value.runTime < 10) {
          this.runTimeMinuteError = true;
          return false;
        } else {
          if (this.executionSetup.valid) {
            // this.showLoder = true;
            /// Post time convert to minits
            let runTimeInMinits = this.executionSetup.value.runTime;
            console.log(runTimeInMinits, this.executionSetup.value.runTime)
            if (this.executionSetup.value.timeUnit == 'day') {
              runTimeInMinits = ((this.executionSetup.value.runTime * 24) * 60);
            }
            if (this.executionSetup.value.timeUnit == 'hour') {
              runTimeInMinits = (this.executionSetup.value.runTime * 60);
            }

            let commend_value: string = this.executionSetup.value.commendName;
            let serviceids = [];
            serviceids.push(this.executionSetup.value.commendService);
            let zoneids = [];
            let anchorids = [];
            if (this.displayZoneArea) {
              console.log('Display Zone', this.executionSetup.value.commendZone);
              zoneids.push(this.executionSetup.value.commendZone);
            }
            if (this.displatAnchorArea) {
              console.log('Display Area', this.executionSetup.value.commendZone);
              anchorids.push(this.executionSetup.value.commendZone);
            }
            const reqData = {
              cmd_value: commend_value.toLowerCase(),
              cmd_type: this.selectedQueryTypeValue,
              type_fo_query: "domain_anchor_periodic",
              service_ids: serviceids,
              host_id: this.executionSetup.value.commendService,
              zone_id: zoneids,
              anchor_id: anchorids,
              run_time: runTimeInMinits,
              run_count: this.executionSetup.value.runCount,
              time_unit: this.executionSetup.value.timeUnit,
              query_status: "enqueue",
              interval_unit: this.executionSetup.value.intervalUnit,
              interval_time: this.executionSetup.value.intervalTime
            }
            console.log('Payload set', reqData);
            // return false;
            /// Post Data to our database through API
            this.anchor.postPeriodicDomainAnchorOrZoneWiseCommendHistory(reqData).subscribe(
              res => {
                if (res['status'] == 1) {
                  this.showLoder = false;
                  this.historyMasterId = + res['serialize_obj'][0]['parent_id'];
                  this.domainWiseFormSetArray = res['serialize_obj'];
                  this.PeriodicCheckPointUtilization();
                  // this.postCommendRequestToAiro();
                  console.log(this.historyMasterId, this.domainWiseFormSetArray);
                }
                if (res['status'] == 0) {
                  this.showLoder = false;
                  this.domainWiseFormSetArray = [];
                }
              },
              err => {
                this.showLoder = false;
                console.log("ERROR");
                console.log(err);
                this.common.openSnackBar('Please try later', '');
              }
            );
          }
        }

      }
      // For Regular Domain Zone Area
      if (this.selectedQueryTypeValue == "regular" && this.selectedServiceValue == "domain" && this.selectedAnchorOrZoneValue == "zone") {
        console.log('One time, domain, zone')
        if (this.executionSetup.valid) {
          // this.showLoder = true;
          /// Post time convert to minits
          let serviceids = [];
          serviceids.push(this.executionSetup.value.commendService);
          let zoneids = [];
          let anchorids = [];
          if (this.displayZoneArea) {
            console.log('Display Zone', this.executionSetup.value.commendZone);
            zoneids.push(this.executionSetup.value.commendZone);
          }
          if (this.displatAnchorArea) {
            console.log('Display Area', this.executionSetup.value.commendZone);
            anchorids.push(this.executionSetup.value.commendZone);
          }
          const reqData = {
            cmd_value: 'ping',
            cmd_type: this.selectedQueryTypeValue,
            type_fo_query: "domain_zone_regular",
            service_ids: serviceids,
            host_id: this.executionSetup.value.commendService,
            zone_id: zoneids,
            anchor_id: anchorids,
            run_time: '1',
            run_count: '3',
            time_unit: 'minute',
            query_status: "running",
            interval_unit: this.executionSetup.value.intervalUnit,
            interval_time: this.executionSetup.value.intervalTime
          }
          console.log('Payload set', reqData);

          /// Post Data to our database through API
          this.anchor.postPeriodicDomainAnchorOrZoneWiseCommendHistory(reqData).subscribe(
            res => {
              if (res['status'] == 1) {
                this.showLoder = false;
                this.historyMasterId = + res['serialize_obj'][0]['parent_id'];
                this.domainWiseFormSetArray = res['serialize_obj'];
                this.PeriodicCheckPointUtilization();
                // this.postCommendRequestToAiro();
                console.log(this.historyMasterId, this.domainWiseFormSetArray);
              }
              if (res['status'] == 0) {
                this.showLoder = false;
                this.domainWiseFormSetArray = [];
              }
            },
            err => {
              this.showLoder = false;
              console.log("ERROR");
              console.log(err);
              this.common.openSnackBar('Please try later', '');
            }
          );
        }
      }
      // For Periodic Domain Zone Area
      if (this.selectedQueryTypeValue == "periodic" && this.selectedServiceValue == "domain" && this.selectedAnchorOrZoneValue == "zone") {
        console.log('time range, domain, zone')
        if (this.executionSetup.value.timeUnit == 'minute' && this.executionSetup.value.runTime < 10) {
          this.runTimeMinuteError = true;
          return false;
        } else {
          if (this.executionSetup.valid) {
            // this.showLoder = true;
            /// Post time convert to minits
            let runTimeInMinits = this.executionSetup.value.runTime;
            console.log(runTimeInMinits, this.executionSetup.value.runTime)
            if (this.executionSetup.value.timeUnit == 'day') {
              runTimeInMinits = ((this.executionSetup.value.runTime * 24) * 60);
            }
            if (this.executionSetup.value.timeUnit == 'hour') {
              runTimeInMinits = (this.executionSetup.value.runTime * 60);
            }

            let commend_value: string = this.executionSetup.value.commendName;
            let serviceids = [];
            serviceids.push(this.executionSetup.value.commendService);
            let zoneids = [];
            let anchorids = [];
            if (this.displayZoneArea) {
              console.log('Display Zone', this.executionSetup.value.commendZone);
              zoneids.push(this.executionSetup.value.commendZone);
            }
            if (this.displatAnchorArea) {
              console.log('Display Area', this.executionSetup.value.commendZone);
              anchorids.push(this.executionSetup.value.commendZone);
            }
            const reqData = {
              cmd_value: commend_value.toLowerCase(),
              cmd_type: this.selectedQueryTypeValue,
              type_fo_query: "domain_zone_periodic",
              service_ids: serviceids,
              host_id: this.executionSetup.value.commendService,
              zone_id: zoneids,
              anchor_id: anchorids,
              run_time: runTimeInMinits,
              run_count: this.executionSetup.value.runCount,
              time_unit: this.executionSetup.value.timeUnit,
              query_status: "enqueue",
              interval_unit: this.executionSetup.value.intervalUnit,
              interval_time: this.executionSetup.value.intervalTime
            }
            console.log('Payload set', reqData);

            /// Post Data to our database through API
            this.anchor.postPeriodicDomainAnchorOrZoneWiseCommendHistory(reqData).subscribe(
              res => {
                if (res['status'] == 1) {
                  this.showLoder = false;
                  this.historyMasterId = + res['serialize_obj'][0]['parent_id'];
                  this.domainWiseFormSetArray = res['serialize_obj'];
                  this.PeriodicCheckPointUtilization();
                  // this.postCommendRequestToAiro();
                  console.log(this.historyMasterId, this.domainWiseFormSetArray);
                }
                if (res['status'] == 0) {
                  this.showLoder = false;
                  this.domainWiseFormSetArray = [];
                }
              },
              err => {
                this.showLoder = false;
                console.log("ERROR");
                console.log(err);
                this.common.openSnackBar('Please try later', '');
              }
            );
          }
        }
      }
      // For Regular Service Anchor Area
      if (this.selectedQueryTypeValue == "regular" && this.selectedServiceValue == "service" && this.selectedAnchorOrZoneValue == "anchor") {
        alert('One time, service, anchor')
      }
      // For Periodic Service Anchor Area 
      if (this.selectedQueryTypeValue == "periodic" && this.selectedServiceValue == "service" && this.selectedAnchorOrZoneValue == "anchor") {
        alert('time range, service, anchor')
      }
      // For Regular Service Zone Area
      if (this.selectedQueryTypeValue == "regular" && this.selectedServiceValue == "service" && this.selectedAnchorOrZoneValue == "zone") {
        alert('One time, service, zone')
      }
      // For Periodic Service Zone Area 
      if (this.selectedQueryTypeValue == "periodic" && this.selectedServiceValue == "service" && this.selectedAnchorOrZoneValue == "zone") {
        alert('time range, service, zone')
      }
    } else {
      this.common.openSnackBar('Please fillup all the fields.', '');
    }
  }

  submitExecution() {
    this.runTimeMinuteError = false;
    console.log('Setup Data', this.executionSetup.value);
    console.log('selectedQueryTypeValue', this.selectedQueryTypeValue)
    // return false;
    if (this.executionSetup.valid) {
      if (this.selectedQueryTypeValue == 'regular') {
        // Set Value modal field
        this.executionSetup.setValue({
          commendName: 'ping',
          commendType: 'regular',
          commendService: this.executionSetup.value.commendService,
          commendZone: this.executionSetup.value.commendZone,
          runTime: '1',
          runCount: '3',
          timeUnit: 'minute'
        })
        this.regularCheckPointUtilization();
      }
      if (this.selectedQueryTypeValue == 'periodic') {
        if (this.executionSetup.value.timeUnit == 'minute' && this.executionSetup.value.runTime < 10) {
          this.runTimeMinuteError = true;
          return false;
        } else {
          this.domainWiseFormSetArray = [];
          console.log('Validation Check', this.executionSetup.valid);
          if (this.executionSetup.valid) {
            this.showLoder = true;
            /// Post time convert to minits
            let runTimeInMinits = this.executionSetup.value.runTime;
            console.log(runTimeInMinits, this.executionSetup.value.runTime)
            if (this.executionSetup.value.timeUnit == 'day') {
              runTimeInMinits = ((this.executionSetup.value.runTime * 24) * 60);
            }
            if (this.executionSetup.value.timeUnit == 'hour') {
              runTimeInMinits = (this.executionSetup.value.runTime * 60);
            }

            let commend_value: string = this.executionSetup.value.commendName;
            let serviceids = [];
            serviceids.push(this.executionSetup.value.commendService);
            let zoneids = [];
            let anchorids = [];
            if (this.displayZoneArea) {
              console.log('Display Zone', this.executionSetup.value.commendZone);
              zoneids.push(this.executionSetup.value.commendZone);
            }
            if (this.displatAnchorArea) {
              console.log('Display Area', this.executionSetup.value.commendZone);
              anchorids.push(this.executionSetup.value.commendZone);
            }
            const reqData = {
              cmd_value: commend_value.toLowerCase(),
              cmd_type: this.executionSetup.value.commendType,
              service_ids: serviceids,
              zone_ids: zoneids,
              anchor_ids: anchorids,
              run_time: runTimeInMinits,
              run_count: this.executionSetup.value.runCount,
              time_unit: this.executionSetup.value.timeUnit
            }
            console.log('Payload set', reqData);
            // return false
            /// Post Data to our database through API
            this.anchor.postPeriodicCommendHistory(reqData).subscribe(
              res => {
                if (res['status'] == 1) {
                  this.showLoder = false;
                  this.historyMasterId = + res['serialize_obj'][0]['parent_id'];
                  this.domainWiseFormSetArray = res['serialize_obj'];
                  this.PeriodicCheckPointUtilization();
                  // this.postCommendRequestToAiro();
                  // console.log(this.zoneList);
                }
                if (res['status'] == 0) {
                  this.showLoder = false;
                  this.domainWiseFormSetArray = [];
                }
              },
              err => {
                this.showLoder = false;
                console.log("ERROR");
                console.log(err);
                this.common.openSnackBar('Please try later', '');
              }
            );
          }
        }
      }
      console.log('Setup Data', this.executionSetup);
    } else {
      this.common.openSnackBar('Please fillup all the fields.', '');
    }
    console.log('submitExecution', this.selectedQueryTypeValue);
  }

  regularSaveHistory() {
    console.log(this.executionSetup.valid)
    if (this.executionSetup.valid) {
      this.showLoder = true;
      let anchors = [];
      anchors.push();
      const formData: any = {
        cmd_value: this.executionSetup.value.commendName,
        cmd_type: this.executionSetup.value.commendType,
        run_time: this.executionSetup.value.runTime,
        run_count: this.executionSetup.value.runCount,
        region: this.region,
        anchor_ids: this.executionSetup.value.commendZone,
        hosts: this.executionSetup.value.commendService
      }
      console.log('Test', formData);
      const reqData = JSON.stringify(formData);
      /// To Do points check in user account
      this.anchor.postCommendHistory(reqData).subscribe(
        res => {
          // console.log('History Details', res);
          if (res['status'] == 1) {
            this.showLoder = false;
            let history_id = res['obj_id']
            this.postRegularCommendRequestToAiro(history_id);
          }
          else {
            this.showLoder = false;
            this.common.openSnackBar('Please try later', '');
          }
        },
        err => {
          this.showLoder = false;
          console.log("ERROR");
          console.log(err);
        }
      );
    }
  }

  postRegularCommendRequestToAiro(id) {
    this.showLoder = true;
    let historyId = id;
    let sendhosts: any = [];
    let ancherids: any = [];
    let commend_value: string = this.executionSetup.value.commendName;
    sendhosts.push(this.executionSetup.value.commendService);
    ancherids.push(this.aioriAnchorId);
    const reqCmdData: any = {
      cmd_value: commend_value.toLowerCase(),
      cmd_type: this.executionSetup.value.commendType,
      // run_time: this.commendSetup.value.runTime,
      run_count: this.executionSetup.value.runCount,
      // region: this.commendSetup.value.areaName,
      region: this.region,
      hosts: sendhosts,
      anchors: ancherids
    }
    const post_param = JSON.stringify(reqCmdData);
    this.anchor.postExecuteCommendRegularToAIRO(post_param, this.lease_id).subscribe(
      res => {
        this.showLoder = false;
        console.log('Execution Details', res);
        this.updateRegularCommendHistory(historyId, res['id'], res['status'], 'running')
        // if(res['status'] == 1){
        //   this.postCommendRequestToAiro();
        // }
        // else{
        //   this.common.openSnackBar('Please try later', '');
        // }
      },
      err => {
        this.showLoder = false;
        console.log("ERROR");
        console.log(err);
        this.updateRegularCommendHistory(historyId, '', '', 'failure')
        this.common.openSnackBar('No connection error from AIRO Server', '');
      }
    );
  }

  updateRegularCommendHistory(his_id, qry_id, status, execution_status) {
    this.showLoder = true;
    const reqData: any = {
      id: his_id,
      query_id: qry_id,
      status: execution_status
    }
    this.anchor.putCommendHistory(reqData).subscribe(
      res => {
        console.log('Execution Details', res);
        if (res['status'] == 1) {
          this.showLoder = false;
          let obj = {
            'execution': 'success',
            'type': this.selectedQueryTypeValue
          }
          this.dialogRef.close(obj);
        }
        else {
          this.showLoder = false;
          this.common.openSnackBar('Please try later', '');
        }
      },
      err => {
        this.showLoder = false;
        console.log("ERROR");
        console.log(err);
        this.common.openSnackBar('No connection error from AIRO Server', '');
      }
    );
  }

  postPeriodicCommendRequestToAiro() {
    this.showLoder = true;
    let that = this;
    console.log('this.domainWiseFormSetArray.length', this.domainWiseFormSetArray.length);
    if (this.domainWiseFormSetArray.length > 0) {
      this.domainWiseFormSetArray.forEach(element => {
        // console.log(element);
        const reqCmdData: any = {
          cmd_value: element.cmd_value,
          cmd_type: element.cmd_type,
          run_time: element.run_time,
          region: element.region,
          run_count: element.run_count,
          hosts: element.hosts
        }
        const post_param = JSON.stringify(reqCmdData);
        // console.log('postCommendRequestToAiro', post_param);
        /// Send data to AIRO Server through API
        that.anchor.postExecuteCommendToAIRO(post_param).subscribe(
          res => {
            that.periodicUpdateArray.push(
              {
                parent_id: element.parent_id,
                chield_id: element.chield_id,
                query_id: res['id'],
                status: "running"
              }
            );
            // check total query post is complete or not
            if (that.domainWiseFormSetArray.length === that.periodicUpdateArray.length) {
              that.showLoder = false;
              that.updatePeriodicCommendHistory();
            }
          },
          err => {
            this.showLoder = false;
            console.log("ERROR");
            console.log(err);
            this.common.openSnackBar('Connection error from AIRO Server', '');
          }
        );
      });
    }
    else {
      this.common.openSnackBar('connection error please try later', '');
    }
  }

  postPeriodicCommendRequestToAiroWithLeaseId() {
    this.showLoder = true;
    console.log('Lease');
    this.leaseId = 0;
    let that = this;
    if (this.anchorList.length > 0) {
      this.anchorList.forEach(item => {
        if (item.id === this.executionSetup.value.commendZone) {
          that.leaseId = item.lease_id;
        }
      });
    }
    console.log('this.domainWiseFormSetArray.length', this.domainWiseFormSetArray.length);
    if (this.domainWiseFormSetArray.length > 0) {
      this.domainWiseFormSetArray.forEach(element => {
        console.log('domainWiseFormSetArray', element);
        const reqCmdData: any = {
          cmd_value: element.cmd_value,
          cmd_type: element.cmd_type,
          run_time: element.run_time,
          region: element.region,
          run_count: element.run_count,
          hosts: element.hosts,
          anchors: element.anchors
        }
        if(this.displayZoneArea){
          // Payload For Zone Wise Form Data
          // reqCmdData['region'] = element.region
          that.leaseId = element.lease_id;
        }
        // if(this.displatAnchorArea){
        //   // Payload For Anchor Wise Form Data
        //   reqCmdData['anchors'] = element.anchors
        // }
        console.log('POST RD3 MN DATA', reqCmdData)
        const post_param = JSON.stringify(reqCmdData);
        // console.log('postCommendRequestToAiro', post_param);
        /// Send data to AIRO Server through API
        that.anchor.postExecuteCommendWithLeaseToAIRO(post_param, this.leaseId).subscribe(
          res => {
            that.periodicUpdateArray.push(
              {
                parent_id: element.parent_id,
                chield_id: element.chield_id,
                query_id: res['id'],
                status: "running"
              }
            );
            // check total query post is complete or not
            if (that.domainWiseFormSetArray.length === that.periodicUpdateArray.length) {
              this.showLoder = false;
              that.updatePeriodicCommendHistory();
            }
          },
          err => {
            that.periodicUpdateArray.push(
              {
                parent_id: element.parent_id,
                chield_id: element.chield_id,
                query_id: '',
                status: "failure"
              }
            );
            // check total query post is complete or not
            if (that.domainWiseFormSetArray.length === that.periodicUpdateArray.length) {
              this.showLoder = false;
              that.updatePeriodicCommendHistory();
            }
            // this.showLoder = false;
            console.log("ERROR");
            console.log(err);
            this.common.openSnackBar('Connection error from AIRO Server', '');
          }
        );
      });
    }
    else {
      this.showLoder = false;
      this.common.openSnackBar('connection error please try later', '');
    }
  }

  updatePeriodicCommendHistory() {
    if (this.periodicUpdateArray.length > 0) {
      this.showLoder = true;
      const reqData: any = {
        update_set: this.periodicUpdateArray
      }
      // After return AIRO Server update history table
      this.anchor.putPeriodicCommendHistory(reqData).subscribe(
        res => {
          // console.log('Execution Details', res);
          if (res['status'] == 1) {
            this.showLoder = false;
            let obj = {
              'execution': 'success',
              'type': this.selectedQueryTypeValue
            }
            this.dialogRef.close(obj);
          }
          else {
            this.showLoder = false;
            this.common.openSnackBar('Please try later', '');
          }
        },
        err => {
          this.showLoder = false;
          console.log("ERROR");
          console.log(err);
          this.common.openSnackBar('No connection error from AIRO Server', '');
        }
      );
    }
    else {
      this.showLoder = false;
      this.common.openSnackBar('Update payload is blank.', '');
    }
  }

  dialogRefTags: MatDialogRef<AddEditDomainComponent> | null;
  goToDomainList(): void {
    // this.closePopup();
    this.dialogRefTags = this.dialog.open(AddEditDomainComponent, {
      data: {
        id: 0
      },
      disableClose: true
    });
    this.dialogRefTags.afterClosed().subscribe(result => {
      console.log('afterClosed', result);
      this.getHostList();
    });
    // this.router.navigate(['/domain-list']);
  }

  dialogRefTags1: MatDialogRef<AddEditHostGroupComponent> | null;
  goToServiceAdd(): void {
    // this.closePopup();
    this.dialogRefTags1 = this.dialog.open(AddEditHostGroupComponent, {
      data: {
        id: 0
      },
      disableClose: true
    });
    this.dialogRefTags1.afterClosed().subscribe(result => {
      console.log('afterClosed', result);
      this.allServiceList();
    });
    // this.router.navigate(['/domain-list']);
  }

  dialogRefTags2: MatDialogRef<CreateZoneComponent> | null;
  goToZoneCreate(): void{
    // this.closePopup();
    // this.router.navigate(['/zone/map-view']);
    // this.closePopup();
    this.dialogRefTags2 = this.dialog.open(CreateZoneComponent, {
      data: {
        id: 0
      },
      disableClose: false,
      width: '90%'
    });
    this.dialogRefTags2.afterClosed().subscribe(result => {
      console.log('afterClosed', result);
      this.allZoneList();
    });
  }

  closePopup() {
    let obj = {
      'execution': 'falure',
      'type': 'none'
    }
    this.dialogRef.close(obj);
  }

}
