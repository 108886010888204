import { Component, OnInit, Inject, ViewChild, ElementRef, NgZone, } from '@angular/core';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { Validators, FormBuilder, FormGroup, FormControl, FormArray } from '@angular/forms';
import { DISABLED } from '@angular/forms/src/model';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { AnchorService } from '../../services/anchor/anchor.service';
import { CommonService } from '../../services/common/common.service';

@Component({
  selector: 'app-leaseanchor',
  templateUrl: './leaseanchor.component.html',
  styleUrls: ['./leaseanchor.component.css']
})
export class LeaseanchorComponent implements OnInit {
  formModel: any = {};
	leaseFromDate: Date;
	anchorLeaseSetup: FormGroup;
	AnchorName = new FormControl('', [Validators.required]);
	// RegisterAddress = new FormControl('', [Validators.required]);
  latitude: number;
  longitude: number;
  address: string;
  location: string;
  address_error: boolean = false;
  showRegisterLoder: boolean = false;
  private geoCoder;
  @ViewChild('search')
  public searchElementRef: ElementRef;

  constructor(
    _formbuilder: FormBuilder,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
		public dialogRef: MatDialogRef<LeaseanchorComponent>, @Inject(MAT_DIALOG_DATA) 
		public anchorData:any,
		private dialog: MatDialog,
		private ar: ActivatedRoute, 
		private router: Router,
		private anchor: AnchorService,
		private common: CommonService,
  ) { 
    this.latitude = parseFloat('22.5726');
    this.longitude = parseFloat('88.3639');
    this.anchorLeaseSetup = _formbuilder.group({
			AnchorName: this.AnchorName
			// RegisterAddress: this.RegisterAddress
		});
  }

  ngOnInit() {
    this.mapsAPILoader.load().then(() => {
      // this.setCurrentLocation();
      // this.setRegisterLocation();
      this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          console.log('google.maps.places.PlaceResult', place);
          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.getAddress(this.latitude, this.longitude);
        });
      });
    });
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      console.log(results);
      console.log(status);
      if (status === 'OK') {
        if (results[0]) {
          this.address = results[0].formatted_address;
          console.log('GEO LOCATION', results[0]);
          // this.location = results[0].address_components[5].long_name;
          results[0].address_components.forEach(element => {
            Object.keys(element).forEach(key => {
              let keyname = key;
              let value = element[key];
              if(keyname == 'types'){
                if(value.length > 0){
                  if(value[0] == 'locality'){
                    console.log('address_components', keyname);
                    console.log('address_components value', value[0]);
                    console.log('address_components Area name', element.long_name);
                    this.location = element.long_name;
                  }
                }
              }
            });
            // if(element.)
          });
          console.log('this.location', this.location);
        } else {
          window.alert('No results found');
        }
      } else {
        // window.alert('Geocoder failed due to: ' + status);
      }

    });
  }

  LeaseAnchor(){
    this.address_error = false;
    // if(this.address != undefined || this.location != undefined){
    //   this.address_error = true;

    //   return false;
    // }else{
      if(this.anchorLeaseSetup.valid){
        this.showRegisterLoder = true;
        console.log('Anchor Lease');
        const formData = {
            "anchor_name": this.anchorLeaseSetup.value.AnchorName,
            "address": this.address,
            "location": this.location,
            "lat": this.latitude,
            "long": this.longitude
        }
        if((this.address != undefined && this.location != undefined) ||(this.address != null && this.location != null)){
          console.log('Anchor Lease address_error', this.address_error);
          console.log('this.address', this.address);
          console.log('this.location', this.location);
          console.log('this.latitude', this.latitude);
          console.log('this.longitude', this.longitude);
          console.log('Form Data', formData);
          this.anchor.registerAnchorByName(formData).subscribe(
            res => {
              this.showRegisterLoder = false;
              this.common.openSnackBar(res['massage'], '');
              console.log('AIORI SERVER RETURN', res);
              this.closePopup();
            },
            err => {
              this.showRegisterLoder = false;
              console.log("ERROR");
              console.log(err);
              // this.saveUserAnchorLocation('ssd');
              this.common.openSnackBar('Anchor is not register in AIROI server please try later', '');
            }
          );
        }else{
          this.address_error = true;
          this.showRegisterLoder = false;
          console.log('this.address', this.address);
          console.log('this.location', this.location);
          console.log('this.latitude', this.latitude);
          console.log('this.longitude', this.longitude);
          console.log('Anchor Lease address_error', this.address_error);
          return false;
        }
        // console.log('Form Data', formData);
        //   this.anchor.registerAnchorByName(formData).subscribe(
        //     res => {
        //       this.common.openSnackBar(res['massage'], '');
        //       console.log('AIORI SERVER RETURN', res);
        //       this.closePopup();
        //     },
        //     err => {
        //       console.log("ERROR");
        //       console.log(err);
        //       // this.saveUserAnchorLocation('ssd');
        //       this.common.openSnackBar('Anchor is not register in AIROI server please try later', '');
        //     }
        //   );
        
      }
  }

  closePopup() {
		this.dialogRef.close();
	}

}
