import { Component, OnInit, Inject, OnDestroy, NgZone } from '@angular/core';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { AnchorService } from '../../services/anchor/anchor.service';
import { CommonService } from '../../services/common/common.service';

@Component({
  selector: 'app-anchorlocation-mapview',
  templateUrl: './anchorlocation-mapview.component.html',
  styleUrls: ['./anchorlocation-mapview.component.css']
})
export class AnchorlocationMapviewComponent implements OnInit {
  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  location: string;
  registerAnchors: any;
  selectedAnchorIds = [];
  private geoCoder;

  constructor(
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private activatedroute: ActivatedRoute,
    private common: CommonService,
    private anchor: AnchorService,
    // public dialogRef: MatDialogRef<AnchorlocationMapviewComponent>, @Inject(MAT_DIALOG_DATA),
  ) { 
    //set google maps defaults
    this.zoom = 6;
    this.latitude = parseFloat('20.5937');
    this.longitude = parseFloat('78.9629');
  }

  ngOnInit() {
    this.getAllRegisterAnchorLocation();
    this.mapsAPILoader.load().then(() => {

    });
  }

  getAllRegisterAnchorLocation(){
    this.registerAnchors = [];
    this.anchor.getAllRegisterAnchors().subscribe(
      res => {
        if(res['status'] == 1){
          console.log(res);
          let that = this;
          if(res['data'].length > 0){
            res['data'].forEach(element => {
              that.registerAnchors.push({
                "latitude": parseFloat(element.latitude), 
                "longitude": parseFloat(element.longitude),
                "anchor_id": element.id
              });
            });
          }
        }
        if(res['status'] == 0){
          this.registerAnchors = [];
        }
      },
      err => {
        console.log("ERROR");
        console.log(err);
        this.common.openSnackBar('Please try later', '');
      }
    );
  }

  markerDragEnd(event){
    console.log(event);
  }

  markerClickEvent(anchor_id){
    console.log(anchor_id);
    this.selectedAnchorIds.push(anchor_id);
    console.log('Selected', this.selectedAnchorIds);
  }

}
