import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignuplayoutComponent } from './layouts/signuplayout/signuplayout.component';
import { AdminLoginComponent } from './admin/login/login.component';
// import { AuthGuardAdmin, AuthLoginAdmin, GlobalService } from './_services/index';
// import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
// import { LoginComponent } from './signups_logins/login/login.component';
// import { RegistrationComponent } from './signups_logins/registration/registration.component';
// import { ProfileComponent } from './user/profile/profile.component';
// import { SubscriptionPlansComponent } from './subscription/subscription-plans/subscription-plans.component';
// import { SubscriptionDetailsComponent } from './subscription/subscription-details/subscription-details.component';
// import { CommendComponent } from './anchor/commend/commend.component';
// import { ExecutedCommendHistoryComponent } from './anchor/executed-commend-history/executed-commend-history.component';
// import { AnalyticsComponent } from './anchor/analytics/analytics.component';
// import { PeriodicAnalyticsComponent } from './anchor/periodic-analytics/periodic-analytics.component';
// import { DomainListComponent } from './user/domain-list/domain-list.component';
// import { PointUtilizationDetailsComponent } from './subscription/point-utilization-details/point-utilization-details.component';
// import { HostGroupManagementComponent } from './accessibility/host-group-management/host-group-management.component';
// import { ZoneManagementComponent } from './accessibility/zone-management/zone-management.component';

const LOGIN_SIGNUP_ROUTES: Routes = [
    // { path: '', pathMatch: 'full', redirectTo: '/login' },
    // { path: '', component: LoginComponent },
    // { path: 'login', component: LoginComponent },
    // { path: 'signup', component: RegistrationComponent }
];

const MAIN_LAYOUT: Routes = [
    // { path: 'profile', component: ProfileComponent },
    // { path: 'subscription', component: SubscriptionPlansComponent },
    // { path: 'subscription-details', component: SubscriptionDetailsComponent },
    // { path: 'command', component: CommendComponent },
    // { path: 'executed-history/:historytype', component: ExecutedCommendHistoryComponent },
    // { path: 'analytics', component: AnalyticsComponent },
    // { path: 'periodic-analytics/:tokenid', component: PeriodicAnalyticsComponent },
    // { path: 'domain-list', component: DomainListComponent },
    // { path: 'utilization-details', component: PointUtilizationDetailsComponent },
    // { path: 'service-list', component: HostGroupManagementComponent },
    // { path: 'zone-list', component: ZoneManagementComponent },
];

const APP_ROUTES: Routes = [
    // { path: '', component: SignuplayoutComponent, children: LOGIN_SIGNUP_ROUTES },
    // { path: '', component: MainLayoutComponent, children: MAIN_LAYOUT },
    // {
    //     path: '', 
    //     component: SignuplayoutComponent,
    //     children: [
    //         { path: '', pathMatch: 'full', redirectTo: '/login' },
    //         { path:'', component: LoginComponent },
    //         { path: 'login', component: LoginComponent }
    //     ]
    // },
    // {
    //     path: 'signup',
    //     loadChildren: './signups_logins/loginsignup.module#LoginsignupModule'
    // },
    // { path: '', redirectTo: 'admin/login', pathMatch: 'full' },
    { path: 'admin/login', component: AdminLoginComponent, pathMatch: 'full' },
    { path: 'admin',
        // canActivate: [AuthGuardAdmin],
        data: { title: 'Admin Views' },
        // loadChildren: 'app/backend/layouts/admin/admin.module#AdminModule'
        loadChildren: './layouts/admin/admin.module#AdminModule'
    },
    {
        path: '',
        loadChildren: './signups_logins/loginsignup.module#LoginsignupModule'
    },
    {
        path: 'user',
        loadChildren: './layouts/main-layout/main-layout.module#MainLayoutModule'
    },
    {
        path: 'discussion',
        loadChildren: './layouts/main-layout/main-layout.module#MainLayoutModule'
    },
    {
        path: 'domain',
        loadChildren: './layouts/main-layout/main-layout.module#MainLayoutModule'
    },
    {
        path: 'hostgroup',
        loadChildren: './layouts/main-layout/main-layout.module#MainLayoutModule'
    },
    {
        path: 'zone',
        loadChildren: './layouts/main-layout/main-layout.module#MainLayoutModule'
    },
    {
        path: 'points',
        loadChildren: './layouts/main-layout/main-layout.module#MainLayoutModule'
    },
    {
        path: 'utilization',
        loadChildren: './layouts/main-layout/main-layout.module#MainLayoutModule'
    },
    {
        path: 'command',
        loadChildren: './layouts/main-layout/main-layout.module#MainLayoutModule'
    },
    {
        path: 'query',
        loadChildren: './layouts/main-layout/main-layout.module#MainLayoutModule'
    },
    {
        path: 'visualizer',
        loadChildren: './layouts/main-layout/main-layout.module#MainLayoutModule'
    },
    {
        path: 'dns_query',
        loadChildren: './layouts/main-layout/main-layout.module#MainLayoutModule'
    },
    {
        path: 'periodic',
        loadChildren: './layouts/main-layout/main-layout.module#MainLayoutModule'
    },
    {
        path: 'anchor',
        loadChildren: './layouts/main-layout/main-layout.module#MainLayoutModule'
    },
    {
        path: 'thirdparty',
        loadChildren: './layouts/main-layout/main-layout.module#MainLayoutModule'
    },
];
@NgModule({
    imports: [RouterModule.forRoot(APP_ROUTES)],
    exports: [RouterModule]
})
export class AppRoutingModule { }

