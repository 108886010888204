import { Component, OnInit, Inject } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { AnchorService } from '../../services/anchor/anchor.service';
import { CommonService } from '../../services/common/common.service';
import { AccessibilityService } from '../../services/accessibility/accessibility.service';
import { AddEditDomainComponent } from '../../domain/add-edit-domain.component';
import { AddEditHostGroupComponent } from '../../hostgroup/add-edit-host-group.component';
import { MassageDialogModel, MassageBoxComponent } from '../../global/massage-box/massage-box.component';


@Component({
  selector: 'app-dns-query-execution',
  templateUrl: './dns-query-execution.component.html',
  styleUrls: ['./dns-query-execution.component.css']
})
export class DnsQueryExecutionComponent implements OnInit {
  executionSetup: FormGroup;
  // commendName = new FormControl('', [Validators.required]);
  // commendType = new FormControl('', [Validators.required]);
  commendService = new FormControl('', [Validators.required]);
  commendZone = new FormControl('', [Validators.required]);
  //runCount = new FormControl('', [Validators.required]);
  //runTime = new FormControl('', [Validators.required]);
  //timeUnit = new FormControl('', [Validators.required]);
  dnsQueryType = new FormControl('', [Validators.required]);

  historyMasterId = 0;
  serviceList: any = [];
  zoneList: any = [];
  anchorList: any = [];
  hostList: any = [];
  domainWiseFormSetArray: any = [];
  dnsUpdateArray: any = [];
  
  displayZoneArea: boolean = false;
  displatAnchorArea: boolean = true;
  displayDomainList: boolean = true;
  displayServiceList: boolean = false;
  selectedQueryTypeValue = 'dnsquery';
  selectedAnchorOrZoneValue = 'anchor'; //default value
  selectedServiceValue = 'domain';
  showLoder: boolean = false;

  region: string = 'kolkata';
  aioriAnchorId: string = '';
  anchorUserId: number;
  lease_id: string = '';
  dns_query_type: any = [
    {'value':"A", 'name':"A"},
    {'value':"AAAA", 'name':"AAAA"},
    {'value':"CNAME", 'name':"CNAME"},
    {'value':"SOA", 'name':"SOA"},
    {'value':"MX", 'name':"MX"},
    {'value':"NS", 'name':"NS"},
    {'value':"DNSKEY", 'name':"DNSKEY"},
    {'value':"CHAOS", 'name':"CHAOS"}
  ];

  exclude_anchar_array: any = [
    'IN-5',
    'IN-14',
    'IN-21',
    'IN-22',
    'IN-42',
    'IN-44',
    'IN-52',
    'IN-61',
    'IN-62',
    'IN-63',
    'IN-64',
    'IN-65',
    'IN-66',
    'IN-68',
    'IN-75'
  ]

  constructor(
    _formbuilder: FormBuilder,
    public dialogRef: MatDialogRef<DnsQueryExecutionComponent>, @Inject(MAT_DIALOG_DATA)
    public commendData: any,
    private dialog: MatDialog,
    private ar: ActivatedRoute,
    private router: Router,
    private common: CommonService,
    private anchor: AnchorService,
    private accessibilityservice: AccessibilityService,
  ) {
    this.executionSetup = _formbuilder.group({
        // commendName: 'ping',
        // commendType: 'regular',
        commendService: this.commendService,
        commendZone: this.commendZone,
        dnsQueryType: this.dnsQueryType,
    });
   }

  ngOnInit() {
    this.getHostList();
    this.allServiceList();
    this.allZoneList();
    this.allUserAnchorList();
  }
  
  getHostList() {
    this.anchor.getHost().subscribe(
      res => {
        console.log('Host List', res);
        if (res["status"] == 1) {
          this.hostList = res['domain'];
        }
        else {
          this.hostList = res['domain'];
        }
      },
      err => {
        console.log("ERROR");
        console.log(err);
      },
    );
  }

  allServiceList() {
    this.serviceList = [];
    this.accessibilityservice.getAllHostGroupList().subscribe(
      res => {
        if (res['status'] == 1) {
          this.serviceList = res['hostgroup'];
          console.log(this.serviceList);
        }
        if (res['status'] == 0) {
          this.serviceList = [];
        }
      },
      err => {
        console.log("ERROR");
        console.log(err);
        this.common.openSnackBar('Please try later', '');
      }
    );
  }

  allZoneList() {
    this.zoneList = [];
    this.accessibilityservice.getAllZoneList().subscribe(
      res => {
        if (res['status'] == 1) {
          this.zoneList = res['zone'];
          console.log(this.zoneList);
        }
        if (res['status'] == 0) {
          this.zoneList = [];
        }
      },
      err => {
        console.log("ERROR");
        console.log(err);
        this.common.openSnackBar('Please try later', '');
      }
    );
  }

  allUserAnchorList() {
    this.anchorList = [];
    let that = this;
    this.anchor.getUserAnchors().subscribe(
      res => {
        if (res['status'] == 1) {
          this.anchorList = res['anchor'];
          // exclude_anchar_array;
          // if(res['anchor'].length > 0){
          //   res['anchor'].forEach(element => {
          //     if((that.exclude_anchar_array.includes(element.anchor_details[0].anchor_name))){
          //       console.log('TRUE', element.anchor_details[0].anchor_name);
          //     }else{
          //       console.log('FALSE', element.anchor_details[0].anchor_name);
          //       this.anchorList.push(element);
          //     }
          //   });
          // }
          console.log('anchorList', this.anchorList);
        }
        if (res['status'] == 0) {
          this.anchorList = [];
        }
      },
      err => {
        console.log("ERROR");
        console.log(err);
        this.common.openSnackBar('Please try later', '');
      }
    );
  }

  onSelectionAnchor(event) {
    console.log(event)
    let that = this;
    let anchorID = event.value;
    if (this.anchorList.length > 0) {
      this.anchorList.forEach(element => {
        if (element.id == anchorID) {
          console.log(element)
          that.region = element.location;
          that.aioriAnchorId = element.aiori_anchor_id;
          that.lease_id = element.lease_id;
          that.anchorUserId = element.user;
          console.log('that.region', that.region);
          console.log('that.aioriAnchorId', that.aioriAnchorId);
          console.log('that.lease_id', that.lease_id);
          console.log('that.anchorUserId', that.anchorUserId);
        }
      });
    }
  }

  dialogRefTags: MatDialogRef<AddEditDomainComponent> | null;
  goToDomainList(): void {
    // this.closePopup();
    this.dialogRefTags = this.dialog.open(AddEditDomainComponent, {
      data: {
        id: 0
      },
      disableClose: true
    });
    this.dialogRefTags.afterClosed().subscribe(result => {
      console.log('afterClosed', result);
      this.getHostList();
    });
    // this.router.navigate(['/domain-list']);
  }

  submitQueryExecution() {
    this.selectedAnchorOrZoneValue;
    this.selectedServiceValue;
    let anchorids = [];
    let zoneids = [];
    console.log('Form Submit', this.executionSetup.valid);
    if (this.executionSetup.valid) {
      // For DNS Query Domain Anchor
      if (this.selectedServiceValue == "domain" && this.selectedAnchorOrZoneValue == "anchor") {
        anchorids.push(this.executionSetup.value.commendZone);
        const reqData = {
          cmd_value: 'dnsquery',
          cmd_type: 'regular',
          query_type: this.executionSetup.value.dnsQueryType,
          type_fo_query: "domain_anchor_dns_query",
          host_ip: this.executionSetup.value.commendService,
          zone_id: zoneids,
          anchor_id: anchorids,
          run_time: 1,
          run_count: 1,
          time_unit: "minute",
          query_status: "enqueue"
        }
        console.log('Payload set', reqData);

        /// Post Data to our database through API
        this.anchor.postDnsQueryDomainAnchorOrZoneWiseCommendHistory(reqData).subscribe(
          res => {
            if (res['status'] == 1) {
              this.showLoder = false;
              this.historyMasterId = + res['serialize_obj'][0]['parent_id'];
              this.domainWiseFormSetArray = res['serialize_obj'];
              this.PeriodicCheckPointUtilization();
              console.log(this.historyMasterId, this.domainWiseFormSetArray);
            }
            if (res['status'] == 0) {
              this.showLoder = false;
              this.domainWiseFormSetArray = [];
            }
          },
          err => {
            this.showLoder = false;
            console.log("ERROR");
            console.log(err);
            this.common.openSnackBar('Please try later', '');
          }
        );
      }
    }
  }

  PeriodicCheckPointUtilization() {
    console.log('check points')
    let massageTitle = 'No Points';
    // Generate update json data for insufficient point
    this.showLoder = true;
    const putData = {
      id: this.historyMasterId,
      use_point_status: "insufficient point",
      status: "failure"
    }
    // calculate howmany points will be deduct
    let deduct_points = 0
    if (this.domainWiseFormSetArray.length > 0) {
      if(this.displayServiceList){
        deduct_points = (parseInt(this.domainWiseFormSetArray.length) * 5);
      }else{
        deduct_points = 5;
      }
    }
    // Status Are
    // user have no subscription points earn entry = 0
    // successfully deduct point status = 1
    // remaining_points is less then deduct point status = 2
    if (this.domainWiseFormSetArray.length > 0) {
      let anchor_user_id_number: number = 0;
      let anchor_user_id_arr: any = [];
      if(this.displayZoneArea){
        this.domainWiseFormSetArray.forEach(element => {
          anchor_user_id_arr.push(element.user_id)
        });
      }
      if(this.displatAnchorArea){
        anchor_user_id_number = this.anchorUserId;
      }
      const formData: any = {
        command_name: 'dnsquery',
        run_time: 1,
        utilize_point: deduct_points,
        anchor_user_id: anchor_user_id_number,
        anchor_user_ids: anchor_user_id_arr
      }
      this.anchor.utilizePoint(formData).subscribe(
        res => {
          if (res['status'] == 1) {
            this.showLoder = false;
            this.postCommendRequestToAiroWithLeaseId();
            // successfully deduct point
            // if (this.displayZoneArea) {
            //   this.postPeriodicCommendRequestToAiroWithLeaseId();
            // }
            // if (this.displatAnchorArea) {
            //   this.postPeriodicCommendRequestToAiroWithLeaseId();
            // }
            // if(this.selectedQueryTypeValue == "regular"){
            //   this.postPeriodicCommendRequestToAiroWithLeaseId();
            // }
            // if(this.selectedQueryTypeValue == "periodic"){
            //   let obj = {
            //     'execution': 'success',
            //     'type': this.selectedQueryTypeValue
            //   }
            //   this.dialogRef.close(obj);
            // }
          }
          else {
            this.showLoder = false;
            if (res['status'] == 2) {
              massageTitle = 'Insufficient Points';
            }
            // user have no subscription points earn
            // this.dialogRef.close();
            // // open massage popup
            // const message = 'You have no points to execute this command. Please contact to administrater.'; // generate massage
            // const dialogData = new MassageDialogModel("No Points", message); // Call Global massage box with parameters, 
            // const dialogRefMsg = this.dialog.open(MassageBoxComponent, { // 2 parameters are givin
            //   maxWidth: "400px",
            //   data: dialogData
            // });
            // dialogRefMsg.afterClosed().subscribe(dialogResult => { // After close the massage box return true or false base on button click
            // });
            //execution history update status
            this.anchor.commendHistoryUpdateById(putData).subscribe(
              res => {
                this.dialogRef.close();
                // open massage popup
                const message = 'You have no sufficient points to execute this command. Please contact to administrater.'; // generate massage
                const dialogData = new MassageDialogModel(massageTitle, message); // Call Global massage box with parameters, 
                const dialogRefMsg = this.dialog.open(MassageBoxComponent, { // 2 parameters are givin
                  maxWidth: "400px",
                  data: dialogData
                });
                dialogRefMsg.afterClosed().subscribe(dialogResult => { // After close the massage box return true or false base on button click
                });
              },
              err => {
                this.showLoder = false;
                console.log("ERROR");
                console.log(err);
                this.common.openSnackBar('Please try later', '');
              }
            );
          }
        },
        err => {
          console.log("ERROR");
          console.log(err);
          this.common.openSnackBar('Please try later', '');
        },
      );
    } else {
      this.showLoder = false;
    }
  }

  postCommendRequestToAiroWithLeaseId() {
    this.showLoder = true;
    console.log('Lease');
    // this.leaseId = 0;
    let that = this;
    console.log('this.domainWiseFormSetArray.length', this.domainWiseFormSetArray.length);
    if (this.domainWiseFormSetArray.length > 0) {
      this.domainWiseFormSetArray.forEach(element => {
        let lease_id = '';
        console.log('domainWiseFormSetArray', element);
        let reqCmdData: any = {
          name: element.hosts,
          anchors: element.anchors
        }
        if(this.executionSetup.value.dnsQueryType != 'SOA' || this.executionSetup.value.dnsQueryType != 'CHAOS'){
          reqCmdData['qtype'] = this.executionSetup.value.dnsQueryType;
        }

        console.log('POST RD3 MN DATA', reqCmdData)
        const post_param = JSON.stringify(reqCmdData);
        lease_id = element.lease_id;
        // console.log('postCommendRequestToAiro', post_param);
        /// Send data to AIRO Server through API
        that.anchor.postDnsQueryToAIRO(post_param, lease_id, this.executionSetup.value.dnsQueryType).subscribe(
          res => {
            that.dnsUpdateArray.push(
              {
                parent_id: element.parent_id,
                chield_id: element.chield_id,
                query_id: res['id'],
                status: "running"
              }
            );
            // check total query post is complete or not
            if (that.domainWiseFormSetArray.length === that.dnsUpdateArray.length) {
              this.showLoder = false;
              that.updatePeriodicCommendHistory();
            }
          },
          err => {
            that.dnsUpdateArray.push(
              {
                parent_id: element.parent_id,
                chield_id: element.chield_id,
                query_id: '',
                status: "failure"
              }
            );
            // check total query post is complete or not
            if (that.domainWiseFormSetArray.length === that.dnsUpdateArray.length) {
              this.showLoder = false;
              that.updatePeriodicCommendHistory();
            }
            // this.showLoder = false;
            console.log("ERROR");
            console.log(err);
            this.common.openSnackBar('Connection error from AIRO Server', '');
          }
        );
      });
    }
    else {
      this.showLoder = false;
      this.common.openSnackBar('connection error please try later', '');
    }
  }

  updatePeriodicCommendHistory() {
    if (this.dnsUpdateArray.length > 0) {
      this.showLoder = true;
      const reqData: any = {
        update_set: this.dnsUpdateArray
      }
      // After return AIRO Server update history table
      this.anchor.putPeriodicCommendHistory(reqData).subscribe(
        res => {
          // console.log('Execution Details', res);
          if (res['status'] == 1) {
            this.showLoder = false;
            let obj = {
              'execution': 'success',
              'type': this.selectedQueryTypeValue
            }
            this.dialogRef.close(obj);
          }
          else {
            this.showLoder = false;
            this.common.openSnackBar('Please try later', '');
          }
        },
        err => {
          this.showLoder = false;
          console.log("ERROR");
          console.log(err);
          this.common.openSnackBar('No connection error from AIRO Server', '');
        }
      );
    }
    else {
      this.showLoder = false;
      this.common.openSnackBar('Update payload is blank.', '');
    }
  }

  closePopup() {
    let obj = {
      'execution': 'falure',
      'type': 'none'
    }
    this.dialogRef.close(obj);
  }

}
