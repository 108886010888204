import { Component, OnInit, ViewChild } from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
// import { MatTableModule } from '@angular/material';
import {MatPaginator} from '@angular/material/paginator';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AccessibilityService } from '../services/accessibility/accessibility.service';
import { CommonService } from '../services/common/common.service';
import { AddEditHostGroupComponent } from './add-edit-host-group.component';
// import { AddEditDomainComponent } from '../add-edit-domain/add-edit-domain.component';
import { ConfirmDialogModel, ConfirmDialogComponent } from '../global/confirm-dialog/confirm-dialog.component';
export interface PeriodicElement {
  position: number;
  service_name: string;
  host_list: string;
  action: string;
}

@Component({
  selector: 'app-host-group-management',
  templateUrl: './host-group-management.component.html',
  styleUrls: ['./host-group-management.component.css']
})
export class HostGroupManagementComponent implements OnInit {
  userdetails: any;
  serviceList: any;
  displayedColumns: string[] = ['position', 'service_name', 'host_list', 'action'];
  dataSource: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private common: CommonService,
    private accessibilityservice: AccessibilityService,
    private router: Router,
    private dialog: MatDialog
  ) { 
    let userData = JSON.parse(localStorage.getItem('userLoginData'));
    if(userData != '' || userData !=undefined){
      this.userdetails = userData;
    }
  }

  ngOnInit() {
    this.serviceList = [];
    // this.dataSource = new MatTableDataSource(this.serviceList);
    this.accessibilityservice.getAllHostGroupList().subscribe(
      res => {
        if(res['status'] == 1){
          let that = this;
          if(res['hostgroup'].length > 0){
            let i = 1;
            res['hostgroup'].forEach(element => {
              let domain_list = "";
              if(element.domain_details.length > 0){
                let k =1;
                element.domain_details.forEach(domain => {
                  domain_list += (k==1 ? domain.domain_name : ', ' + domain.domain_name);
                  k++
                });
              }
              that.serviceList.push(
                {
                  id:element.id,
                  position: i,
                  service_name: element.host_group_name,
                  host_list: domain_list,
                  action:'NA'
                }
              );
              i++;
            });
            this.dataSource = new MatTableDataSource(that.serviceList);
            this.dataSource.paginator = this.paginator;
            // this.dataSource = new MatTableModule();
            // this.dataSource = that.serviceList;
            // this.dataSource.paginator = this.paginator;
          }
        }
        if(res['status'] == 0){
          this.serviceList = [];
          this.dataSource = new MatTableDataSource(this.serviceList);
        }
      },
      err => {
        console.log("ERROR");
        console.log(err);
        this.common.openSnackBar('Please try later', '');
      }
    );
  }

  dialogRefTags : MatDialogRef<AddEditHostGroupComponent> | null;
  openPopUp(service_id){
    console.log('Service', service_id);
    this.dialogRefTags = this.dialog.open(AddEditHostGroupComponent, {
      data: {
        id: service_id
      },
        disableClose: true,
      });
      this.dialogRefTags.afterClosed().subscribe(result => {
        console.log('afterClosed', result);
        this.ngOnInit();
      });
  }

  deletHostGroup(service_id){
    console.log('Service', service_id);
    const formData: any = {
      id: service_id,
      is_delete: true
    }
    const message = 'Are you sure you want to delete this service?'; // generate massage
    const dialogData = new ConfirmDialogModel("Confirm Action", message); // Call Global dilog box with parameters, 
    const dialogRef = this.dialog.open(ConfirmDialogComponent, { // 2 parameters are givin
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => { // After close the dilog box return true or false base on button click
      if(dialogResult === true){ // If return true process will execute, if false brake
        //Delete service details through API
        this.accessibilityservice.updateHostGroup(formData).subscribe(
          res => {
            if(res['status'] == 1){
              this.ngOnInit();
            }
            else{
              this.common.openSnackBar('Service is not deleted please try later', '');
            }
          },
          err => {
            console.log("ERROR");
            console.log(err);
            this.common.openSnackBar('please try later', '');
          }
        );
      }
    });
  }

}
