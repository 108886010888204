import { NgModule } from '@angular/core';
import { ModuleWithProviders} from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddEditZoneComponent } from './add-edit-zone.component';
import { MapviewanchorbyzoneComponent } from './mapviewanchorbyzone/mapviewanchorbyzone.component';
import { MapvieweditanchorbyzoneComponent } from './mapvieweditanchorbyzone/mapvieweditanchorbyzone.component';
import { ZoneManagementComponent } from './zone-management.component';

const ZONE_ROUTER: Routes = [
  {
    path: '', component: ZoneManagementComponent,
    children: [
      { path:'zone-list', component: ZoneManagementComponent }
    ]
  },
  { path: 'zone/add-zone', component: AddEditZoneComponent, pathMatch: 'full',
    children: [
      { path:'add-zone', component: AddEditZoneComponent }
    ]
  },
  { path: 'zone/map-view', component: MapviewanchorbyzoneComponent, pathMatch: 'full',
    children: [
      { path:'map-view', component: MapviewanchorbyzoneComponent }
    ]
  },
  { path: 'zone/map-view-edit/:id', component: MapviewanchorbyzoneComponent, pathMatch: 'full',
    children: [
      { path:'map-view-edit/:id', component: MapvieweditanchorbyzoneComponent }
    ]
  },
];

export const ZoneRouting: ModuleWithProviders = RouterModule.forChild(ZONE_ROUTER);