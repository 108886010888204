import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpClientModule, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { ConfigurationService } from '../config/configuration.service';
import { CommonService } from '../common/common.service'

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private actionUrl: string;
  constructor(
    private http: HttpClient, 
    private _configuration: ConfigurationService, 
    private common: CommonService) {
		this.actionUrl = _configuration.ServerWithApiUrl;
		
  }

  	/**
	 * User Profile
	 *
	 * @param - user_id
	 *
	 * @return Observable
	 */
	getUserProfile() {
		let authHeader = this.common.getHttpOptionWithLogin();
		return this.http.get(this.actionUrl + "user/profile-details/", authHeader);
	  }

	getHttpOptionForFileUpload() {
		let userData = JSON.parse(localStorage.getItem('userLoginData'));
		let headers = new HttpHeaders();
		// headers = headers.append('Content-Type', 'application/json');
		headers = headers.append('Authorization', 'Token ' + userData.token);
		let options = { headers: headers };
		return options;
	}

  	/**
	 * User Profile Image Upload
	 *
	 * @param - Form data "file, file type, upload folder"
	 *
	 * @return Observable
	 */
	uploadProfileImage(form_data) {
		console.log(form_data);
		let userData = JSON.parse(localStorage.getItem('userLoginData'));
		let headers = new HttpHeaders();
		headers = headers.append('Authorization', 'Bearer ' + userData.token);
		let options = { headers: headers };
		return this.http.post(this.actionUrl + "user/upload/",form_data, options);
	  }
	  
	/**
	 * User Profile Image Upload
	 *
	 * @param - Form data "file, file type, upload folder"
	 *
	 * @return Observable
	 */
	profileEdit(form_data) {
		console.log(form_data);
		let authHeader = this.common.getHttpOptionWithLogin();
		return this.http.put(this.actionUrl + "user/profile-details/", form_data,  authHeader);
  	}

	/**
	 * User Password Change
	 *
	 * @param - Form data "old password, new password"
	 *
	 * @return Observable
	 */
	 changeUserPassword(form_data) {
		console.log(form_data);
		let authHeader = this.common.getHttpOptionWithLogin();
		return this.http.post(this.actionUrl + "user/change-password/", form_data,  authHeader);
  	}

  	/**
	 * User Domain List
	 *
	 * @param - None
	 *
	 * @return Observable
	 */
	domainList() {
		let authHeader = this.common.getHttpOptionWithLogin();
		return this.http.get(this.actionUrl + "user/user-domain/",  authHeader);
  	}

  	/**
	 * Add User Domain
	 *
	 * @param - Form Data in json format
	 *
	 * @return Observable
	 */
	saveDomain(reqData) {
		let authHeader = this.common.getHttpOptionWithLogin();
		return this.http.post(this.actionUrl + "user/user-domain/", reqData,  authHeader);
	  }

	/**
	 * Update User Domain
	 *
	 * @param - Form Data in json format
	 *
	 * @return Observable
	 */
	updateDomain(reqData) {
		let authHeader = this.common.getHttpOptionWithLogin();
		return this.http.put(this.actionUrl + "user/user-domain/", reqData,  authHeader);
	  }
	  
	/**
	 * User Domain Details
	 *
	 * @param - domain id
	 *
	 * @return Observable
	 */
	getDomainDetailsById(reqData) {
		let authHeader = this.common.getHttpOptionWithLogin();
		return this.http.post(this.actionUrl + "user/domain-details/", reqData,  authHeader);
	  }
	  
	/**
	 * Admin Can login into user 
	 * 
	 * @param reqData 
	 * 
	 * @return Observable
	 */

	AdminUserLogin(reqData){
		let authHeader = this.common.getadminHttpOptionWithLogin();
		return this.http.post(this.actionUrl + "user/admin-user-login/", reqData,  authHeader);
	}

	/**
	   * Admin Can get Any user Report Details
	   * 
	   * @param - user_id
	   * 
	   * @return Observable
	   * 
	*/

	getAdminUserProfileById(reqData){
		let authHeader = this.common.getadminHttpOptionWithLogin();
		return this.http.post(this.actionUrl + "user/user-report-profile-det/", reqData,  authHeader);
	}

	getAdminDomainList(reqData){
		let authHeader = this.common.getadminHttpOptionWithLogin();
		return this.http.post(this.actionUrl + "user/user-report-domain-det/", reqData,  authHeader);
	}
	getAdminZoneList(reqData){
		let authHeader = this.common.getadminHttpOptionWithLogin();
		return this.http.post(this.actionUrl + "user/user-report-zone-det/", reqData,  authHeader);
	}
	getAdminHosGroupList(reqData){
		let authHeader = this.common.getadminHttpOptionWithLogin();
		return this.http.post(this.actionUrl + "user/user-report-host-group-det/", reqData,  authHeader);
	}

	getAdminPlanDetails(reqData){
		let authHeader = this.common.getadminHttpOptionWithLogin();
		return this.http.post(this.actionUrl + "subscription/user-report-plans/", reqData,  authHeader);
	}
	getAdminPointUses(reqData){
		let authHeader = this.common.getadminHttpOptionWithLogin();
		return this.http.post(this.actionUrl + "subscription/user-report-points_spend/", reqData,  authHeader);
	}

	/**
	 * User Discussion Topic List
	 * 
	 * @param None
	 * 
	 * @return Observable
	 */

	 DiscussionTopicList(){
		let authHeader = this.common.getHttpOptionWithLogin();
		return this.http.get(this.actionUrl + "user/discussion-category/", authHeader);
	}

	/**
	 * User Discussion Contributers
	 * 
	 * @param None
	 * 
	 * @return Observable
	 */

	 getContributersList(){
		let authHeader = this.common.getHttpOptionWithLogin();
		return this.http.get(this.actionUrl + "user/contributers/", authHeader);
	}

	/**
	 * User Discussion Add Topic In List
	 * 
	 * @param None
	 * 
	 * @return Observable
	 */

	 DiscussionTopicAdd(reqData){
		let authHeader = this.common.getHttpOptionWithLogin();
		return this.http.post(this.actionUrl + "user/discussion-category/", reqData, authHeader);
	}

	/**
	 * Get User Discussion By Topic ID
	 * 
	 * @param None
	 * 
	 * @return Observable
	 */

	 GetDiscussionByTopic(cid: number){
		let authHeader = this.common.getHttpOptionWithLogin();
		return this.http.get(this.actionUrl + "user/discussions/"+cid, authHeader);
	}

	/**
	 * Post User Discussion By Topic ID
	 * 
	 * @param None
	 * 
	 * @return Observable
	 */

	 saveDiscussionByTopic(reqData){
		let authHeader = this.common.getHttpOptionWithLogin();
		return this.http.post(this.actionUrl + "user/discussions/", reqData, authHeader);
	}

}
