import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';
import { CommonService } from '../app/services/common/common.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'app';
  // path: string = '';
  // public notifications: any = {};
  // public notificationsAll: any = [];
  // public user_details: any = {};
  // public messageCount: Number = 0;
  // items: Observable<any[]>;

  constructor(
    // private cs: CommonService, 
    // location: Location, 
    // private router: Router, 
    // private ar: ActivatedRoute
    ) {
    // this.path = location.path(); 
    // if(localStorage.getItem('userLoginData')){
    //   setInterval(() => {
    //     // this.items = db.collection('notification').valueChanges();
    //   }, 5000);
    // }
  }

  ngOnInit() {
  }
}
