import { Component, OnInit, Inject, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Router, ActivatedRoute } from '@angular/router';
import { AnchorService } from '../../services/anchor/anchor.service';
import { CommonService } from '../../services/common/common.service';
import { UserService } from '../../services/user/user.service';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { BaseChartDirective, Color, Label } from 'ng2-charts';
import { MatMenuTrigger } from '@angular/material'
import { Observable, BehaviorSubject, Subject, interval, Subscription } from 'rxjs';
declare var echarts: any;
@Component({
  selector: 'app-periodic-domain-zone-analytics',
  templateUrl: './periodic-domain-zone-analytics.component.html',
  styleUrls: ['./periodic-domain-zone-analytics.component.css']
})
export class PeriodicDomainZoneAnalyticsComponent implements OnInit, AfterViewInit, OnDestroy {

  public graphGenerateCompleteObservable = new Subject<any>();
  public graphDataCallBackResult$ = this.graphGenerateCompleteObservable.asObservable();
  private intervalSubject = new BehaviorSubject<number>(1000); // default 1000ms interval
  @ViewChild(BaseChartDirective) chart: BaseChartDirective;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  lineChartData: any = [
    { data: [], label: '' }
  ];
  ChartDataSets: any = [];
  lineChartLabels: any = [];
  Label: any = [];
  lineChartOptions = {
    responsive: true,
  };
  lineChartColors: Color[] = [
    {
      borderColor: 'red',
      // backgroundColor: 'rgba(255, 255, 255, 0.8)',
      backgroundColor: 'rgba(192,192,192,0.2)',
    },
  ];

  lineChartLegend = true;
  lineChartPlugins = [];
  lineChartType = 'line';

  rtt_max: any = [];
  rtt_med: any = [];
  rtt_min: any = [];

  historyId: number = 0;
  historyList: any = [];
  domainList: any;
  periodicSubscription: Subscription;
  queryStartDate: string;
  queryEndDate: string;
  queryRunTime: number;
  filterByArray: any = [];
  formModel: any = {};
  chartNewValue: any = [];
  chartNewLabeles: any = [];
  downloadProgressBer: boolean = false;
  graphTemplateComplete: boolean = true;
  liveGraphCanvusShow: boolean = false;
  getDataIntervalTime: number = 15000;
  // Date picker Variable
  minDate: Date;
  maxDate: Date;
  queryMinDate: Date;
  queryMaxDate: Date;
  queryDataRangeTime

  /// Start: Object create for Echart

  dom = document.getElementById("echart-container");
  myChart: any;
  app = {};
  colors = ['#187bcd', '#0099CC', '#006699', '#0099FF', '#003366', '#666699', '#993399', '#660033', '#00FFFF', '#66CCCC', '#336666', '#336666', '#00CC66', '#006633', '#00CC33', '#CCFFCC', '#336633', '#66CC33', '#CCCC00', '#669900', '#CCCC33', '#CCCC99', '#666633', '#CC9900', '#996600', '#FF9900', '#CC6600', '#993300', '#CC3333', '#CCCCCC', '#666666'];
  option;
  data = [];
  seriesis: any = [];
  HostName = '';
  echartMeterRange: number = 1;
  /// End: Object create for Echart

  constructor(
    private datePipe: DatePipe,
    private router: Router,
    private activatedroute: ActivatedRoute,
    private common: CommonService,
    private anchor: AnchorService,
    private userservice: UserService,
    // private download: XlsdownlodeService,
  ) {
    this.historyId = + atob(this.activatedroute.snapshot.params['tokenid']);
    console.log('History ID', this.historyId);
    this.getDomainList();
    this.formModel.filter_by = '10s';

    // Set the minimum to January 1st 20 years in the past and December 31st a year in the future.
    const currentYear = new Date().getFullYear();
    var ONE_HOUR = 3.5 * 60 * 1000; /* ms */
    this.queryMaxDate = new Date();
    this.queryMinDate = new Date();
    this.formModel.formDateTime = new Date(this.queryMaxDate.getTime() - ONE_HOUR).toISOString();
    this.formModel.toDateTime = new Date().toISOString();
    this.formModel.downloadFormDateTime = new Date().toISOString();
    this.formModel.downloadToDateTime = new Date().toISOString();
  }

  ngOnInit() {
    this.generateEchartView();
    setTimeout(() => {
      this.common.setLoader(true);
    });
    const fromData = {
      history_id: this.historyId
    }
    this.anchor.commendHistoryById(fromData).subscribe(
      res => {
        if (res['status'] == 1) {
          let that = this;
          console.log('Periodic History', res['history']);
          this.getDataIntervalTime = res['history']['query_execution_interval_time'];
          if(res['history']['query_execution_interval_unit'] == 'second'){
            this.getDataIntervalTime = (parseInt(res['history']['query_execution_interval_time']) + 5) * 1000
            this.formModel.filter_by = res['history']['query_execution_interval_time'] +'s'
            // var Time_Range = 30 * 60 * 1000; /* ms */
            var Time_Range = (parseInt(res['history']['query_execution_interval_time']) * 21) * 1000; /* ms */
            this.queryDataRangeTime = Time_Range;
            this.formModel.formDateTime = new Date(this.queryMaxDate.getTime() - Time_Range).toISOString();
          }
          if(res['history']['query_execution_interval_unit'] == 'minute'){
            this.getDataIntervalTime = ((parseInt(res['history']['query_execution_interval_time']) * 60) + 5) * 1000
            this.formModel.filter_by = res['history']['query_execution_interval_time'] +'m'
            // var Time_Range = 300 * 60 * 1000; /* ms */
            var Time_Range = (parseInt(res['history']['query_execution_interval_time']) * 20) * 60 * 1000; /* ms */
            this.queryDataRangeTime = Time_Range;
            this.formModel.formDateTime = new Date(this.queryMaxDate.getTime() - Time_Range).toISOString();
          }
          if(res['history']['query_execution_interval_unit'] == 'hour'){
            this.getDataIntervalTime = ((parseInt(res['history']['query_execution_interval_time']) * 3600) + 5) * 1000
            this.formModel.filter_by = res['history']['query_execution_interval_time'] +'h'
            var Time_Range = (parseInt(res['history']['query_execution_interval_time']) * 20) * 3600 * 1000; /* ms */
            this.queryDataRangeTime = Time_Range;
            this.formModel.formDateTime = new Date(this.queryMaxDate.getTime() - Time_Range).toISOString();
          }
          this.queryStartDate = res['history']['created_date'];
          this.minDate = new Date(this.queryStartDate);
          this.queryEndDate = res['history']['query_execution_end_date'];
          this.maxDate = new Date(this.queryEndDate);
          this.queryRunTime = res['history']['commend_request_payload']['run_time'];
          // console.log('queryRunTime', this.queryRunTime);
          if(res['history']['query_status'] == "running"){
            this.liveGraphCanvusShow = true;
          }
          console.log('this.liveGraphCanvusShow', this.liveGraphCanvusShow);
          this.historyList = res['history']['query_details'];
          console.log(this.historyList);
          if (this.historyList.length > 0) {
            this.historyList.forEach(element => {
              element['todate'] = that.queryEndDate;
              let hostName = that.filterDomainName(element.commend_request_payload.hosts.toString());
              element.commend_request_payload.hosts = hostName;
            });
          }

          let differance = this.diff_minutes(new Date(this.queryEndDate), this.queryMaxDate);
          // console.log('Differ', differance);
          if (differance > 0) {
            // this.formModel.filter_by = '10s';
            this.filterByArray.push(
              { 'name': 'Live', 'value': '10s' },
              { 'name': 'Hourly', 'value': '1440s' },
              { 'name': 'Daily', 'value': '1d' },
              { 'name': 'Weekly', 'value': '7d' },
              { 'name': 'Monthly', 'value': '30d' },
              { 'name': 'Yearly', 'value': '365d' }
            );
            // this.generateAnalyticalGraph();
            this.generateLiveGraph();
          }
          else {
            this.filterByArray.push(
              { 'name': 'Minutes', 'value': '60s' },
              { 'name': 'Hourly', 'value': '1440s' },
              { 'name': 'Daily', 'value': '1d' },
              { 'name': 'Weekly', 'value': '7d' },
              { 'name': 'Monthly', 'value': '30d' },
              { 'name': 'Yearly', 'value': '365d' }
            );
            // Reset form and to Date and intervel variable
            // this.formModel.filter_by = '60s';
            // var ONE_HOUR = 30 * 60 * 1000; /* ms */
            this.queryMaxDate = new Date(this.queryEndDate);
            this.formModel.formDateTime = new Date(this.queryMaxDate.getTime() - this.queryDataRangeTime).toISOString();
            this.formModel.toDateTime = this.queryMaxDate.toISOString();
            // this.generateAnalyticalGraph();
            this.generateLiveGraph();
          }
          setTimeout(() => {
            this.common.setLoader(false);
          });
        }
        if (res['status'] == 0) {
          this.historyList = [];
          setTimeout(() => {
            this.common.setLoader(false);
          });
        }
        // setTimeout(() => {
        //   this.common.setLoader(false);
        // }, 300);
      },
      err => {
        // this.common.setLoader(false);
        setTimeout(() => {
          this.common.setLoader(false);
        }, 300);
        console.log("ERROR");
        console.log(err);
        this.common.openSnackBar('Please try later', '');
      }
    );
  }

  diff_minutes(dt2, dt1) {
    var d2 = Date.parse(dt2) / 1000;
    // console.log('Daten2', d2);
    var d1 = Date.parse(dt1) / 1000;
    // console.log('Daten1', d1);
    var diff = (d2 - d1);
    // console.log('Diffrence', diff);
    // var diff =(dt2.getTime() - dt1.getTime()) / 1000;
    if (diff > 60) {
      // console.log('Not In Minus');
      diff /= 60;
      return Math.abs(Math.round(diff));
    }
    else {
      // console.log('In Minus');
      return 0;
    }
  }

  ngAfterViewInit() {
    // console.log('New Graph Call')
    this.graphDataCallBackResult$.subscribe(value => {
      if (value) {
        console.log('success');
        // const source = interval(300000);
        const source = interval(this.getDataIntervalTime);
        this.periodicSubscription = source.subscribe(val => this.generateNewDataSets())
      }
    });
  }

  generateNewDataSets() {
    // console.log('generateNewDataSets');
    // console.log('Chart Data Set', this.chart.chart.data.datasets[0].data);
    // Runtime calculateion
    var current_date = new Date();
    var current_timestamp = current_date.getTime();
    var execution_end_date = new Date(this.queryEndDate);
    var execution_end_timestamp = execution_end_date.getTime();
    // if (this.formModel.filter_by == '10s') {
      if (current_timestamp < execution_end_timestamp) {
        this.graphDataAdd();
      }
    // }
  }

  getDomainList() {
    this.domainList = [];
    this.userservice.domainList().subscribe(
      res => {
        if (res['status'] == 1) {
          this.domainList = res['domain'];
        }
        if (res['status'] == 0) {
          this.domainList = [];
        }
      },
      err => {
        console.log("ERROR");
        console.log(err);
        this.common.openSnackBar('Please try later', '');
      }
    );
  }

  filterDomainName(domain_ip) {
    let domain_name = '';
    if (this.domainList.length > 0) {
      this.domainList.forEach(element => {
        if (domain_ip == element.domain_ip) {
          domain_name = element.domain_name;
          // console.log('Domain Name', domain_name);
        }
      });
    }
    return domain_name;
  }

  generateLiveGraph() {
    setTimeout(() => {
      this.graphGenerateCompleteObservable.next(false);
      this.common.setLoader(true);
    });
    const formData: any = {
      id: this.historyId,
      duration: this.formModel.filter_by,
      from_date: this.formModel.formDateTime,
      to_date: this.formModel.toDateTime
    }
    console.log('Live Graph', formData);
    this.rtt_max = [];
    this.lineChartData.push(
      { "data": this.rtt_max, "label": 'RTT-AVG' }
    );
    let that = this;
    // Previous Service Call
    // this.anchor.getPeriodicAnalyticalData(formData)
    this.anchor.getPeriodicZoneData(formData).subscribe(
      res => {

        if (res['status'] == 1) {
          this.ChartDataSets = [];
          this.Label = [];
          if (Object.keys(res['data']).length > 0) {
            let count = 0;
            Object.keys(res['data']).forEach(key => {
              let keyname = key;
              let value = res['data'][key];
              if (value.length > 0) {
                if (value.length < 20) {
                  that.preGenerateGraphData(value);
                }
                that.rtt_max = [];
                value.forEach(element => {
                  // that.rtt_max.push(element.mean ? element.mean : 0)
                  // that.rtt_max.push(element.rtt_avg ? element.rtt_avg : 0)
                  that.rtt_max.push(element.rtt_avg)
                  // that.rtt_max.push(element.mean)

                  // Create Labels
                  let unix_timestamp = element.time
                  // Create a new JavaScript Date object based on the timestamp
                  // multiplied by 1000 so that the argument is in milliseconds, not seconds.
                  var date = new Date(unix_timestamp);
                  // Date part from the timestamp
                  var day = date.getDate();
                  // Month part from the timestamp
                  var month = (date.getMonth() + 1);
                  // Year part from the timestamp
                  var fullyear = date.getFullYear();
                  // Hours part from the timestamp
                  var hours = date.getHours();
                  // Minutes part from the timestamp
                  var minutes = "0" + date.getMinutes();
                  // Seconds part from the timestamp
                  var seconds = "0" + date.getSeconds();
                  // Miliseconds part from the timestamp
                  var miliseconds = "0" + date.getMilliseconds();

                  // Will display date in 10-06:2020 format
                  var formattedDate = day + '-' + month + '-' + fullyear;
                  // Will display time in 10:30:23 format
                  // var formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2) + ':' + miliseconds.substr(-2);
                  var formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
                  if ((Object.keys(res['data']).length - 1) == count) {
                    // that.Label.push(formattedDate + '-' + formattedTime);
                    that.Label.push(formattedTime);
                  }
                });
              }
              // let hostName = that.filterDomainName(keyname);
              that.ChartDataSets.push({ "data": that.rtt_max, "label": keyname });
              count++
            });
            if (Object.keys(res['data']).length == count) {
              setTimeout(() => {
                this.graphGenerateCompleteObservable.next(true);
                this.common.setLoader(false);
              });
            }
          }
          this.lineChartData = [];
          this.lineChartLabels = [];
          this.lineChartData = this.ChartDataSets;
          this.lineChartLabels = this.Label;
          // console.log('Final Grapd Data', this.ChartDataSets);
          // console.log('Final Grapd Label', this.Label);

        }
        if (res['status'] == 0) {
          this.lineChartData = [];
          this.graphTemplateComplete = false;
          setTimeout(() => {
            this.graphGenerateCompleteObservable.next(true);
            this.common.setLoader(false);
          });
        }
      },
      err => {
        console.log("ERROR");
        console.log(err);
        this.common.openSnackBar('Please try later', '');
        this.graphTemplateComplete = false;
        setTimeout(() => {
          this.graphGenerateCompleteObservable.next(true);
          this.common.setLoader(false);
        });
      }
    );
  }

  preGenerateGraphData(reselt) {
    const counter: number = +(20 - reselt.length);
    for (let i = 0; i < counter; i++) {
      var oldDateObj = new Date(reselt[0].time);
      var newDateObj = new Date();
      newDateObj.setTime(oldDateObj.getTime() - (10 * 1000));
      reselt.unshift({
        "destination": "",
        "rtt_avg": null,
        "time": newDateObj
      });
    }
  }

  graphDataAdd() {
    console.log('Data Call');
    this.queryMaxDate = new Date();
    this.formModel.formDateTime = this.formModel.toDateTime;
    this.formModel.toDateTime = new Date().toISOString();

    const formData: any = {
      id: this.historyId,
      duration: this.formModel.filter_by,
      from_date: this.formModel.formDateTime,
      to_date: this.formModel.toDateTime
    }
    let that = this;
    // Previous Service Call
    this.anchor.getPeriodicZoneData(formData).subscribe(
      res => {
        if (res['status'] == 1) {
          // this.generateNewEchartSet(res['data']);
          if (Object.keys(res['data']).length > 0) {
            let cnt = 0;
            Object.keys(res['data']).forEach(key => {
              let keyname = key;
              // console.log('keyname', keyname);
              let value = res['data'][key];
              if (value.length > 0) {
                that.chartNewValue = [];
                that.chartNewLabeles = []
                value.forEach(element => {
                  // that.chartNewValue.push(element.mean? element.mean : 0)
                  that.chartNewValue.push(element.rtt_avg ? element.rtt_avg : 0)
                  ///Data pop & push in graph canvus
                  console.log('Data Length', this.chart.chart.data)
                  if (this.chart.chart.data.labels.length > 20) {
                    console.log('Data Length', this.chart.chart.data)
                    this.chart.chart.data.datasets[cnt].data.shift();
                  } else {
                    console.log('Data Length Labels', this.chart.chart.data.labels)
                  }
                  // this.chart.chart.data.datasets[cnt].data.shift();
                  // this.chart.chart.data.datasets[cnt].data.push(element.mean? element.mean : 0);
                  // (this.chart.chart.data.datasets[cnt].data as (number | Chart.ChartPoint)[]).push(element.mean? element.mean : 0);
                  (this.chart.chart.data.datasets[cnt].data as (number | Chart.ChartPoint)[]).push(element.rtt_avg ? element.rtt_avg : 0);

                  // Create Labels
                  let unix_timestamp = element.time
                  // Create a new JavaScript Date object based on the timestamp
                  // multiplied by 1000 so that the argument is in milliseconds, not seconds.
                  var date = new Date(unix_timestamp);
                  // Date part from the timestamp
                  var day = date.getDate();
                  // Month part from the timestamp
                  var month = (date.getMonth() + 1);
                  // Year part from the timestamp
                  var fullyear = date.getFullYear();
                  // Hours part from the timestamp
                  var hours = date.getHours();
                  // Minutes part from the timestamp
                  var minutes = "0" + date.getMinutes();
                  // Seconds part from the timestamp
                  var seconds = "0" + date.getSeconds();
                  // Miliseconds part from the timestamp
                  var miliseconds = "0" + date.getMilliseconds();

                  // Will display date in 10-06:2020 format
                  var formattedDate = day + '-' + month + '-' + fullyear;
                  // Will display time in 10:30:23 format
                  // var formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2) + ':' + miliseconds.substr(-2);
                  var formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
                  if ((Object.keys(res['data']).length - 1) == cnt) {
                    // if (this.formModel.filter_by === '1440s' || this.formModel.filter_by === '10s') {
                    //   that.chartNewLabeles.push(formattedDate + '-' + formattedTime);
                    //   // console.log('Data Label Length', this.chart.chart.data.labels)
                    //   if (this.chart.chart.data.labels.length > 20) {
                    //     this.chart.chart.data.labels.shift();
                    //   }
                    //   // this.chart.chart.data.labels.shift();
                    //   // this.chart.chart.data.labels.push(formattedDate + '-' +formattedTime);
                    //   this.chart.chart.data.labels.push(formattedTime);
                    // } else {
                    //   that.chartNewLabeles.push(formattedDate);
                    //   this.chart.chart.data.labels.shift();
                    //   this.chart.chart.data.labels.push(formattedDate);
                    // }
                    that.chartNewLabeles.push(formattedDate + '-' + formattedTime);
                    // console.log('Data Label Length', this.chart.chart.data.labels)
                    if (this.chart.chart.data.labels.length > 20) {
                      this.chart.chart.data.labels.shift();
                    }
                    // this.chart.chart.data.labels.shift();
                    // this.chart.chart.data.labels.push(formattedDate + '-' +formattedTime);
                    this.chart.chart.data.labels.push(formattedTime);
                  }
                });
              }
              ///Data push in graph canvus
              // let newChartArray: any = [];
              // let afterShiftChartArray: any = [];
              // this.chart.chart.data.datasets[cnt].data = that.chartNewValue;
              // this.chart.chart.data.datasets[cnt].data.shift();
              // this.chart.chart.data.datasets[cnt].data.push(that.chartNewValue);
              // this.chart.chart.data.datasets[cnt].data = afterShiftChartArray;
              // this.chart.chart.update();
              cnt++;
            });
            // this.chart.chart.data.labels = that.chartNewLabeles;
            // this.chart.chart.data.labels.shift();
            // this.chart.chart.data.labels.push(that.chartNewLabeles);
            // this.chart.chart.data.labels = afterPushChatyLabels;
            this.chart.chart.update();
            // setTimeout(() => {
            //   this.graphGenerateCompleteObservable.next(true);
            // });
            // if(Object.keys(res['data']).length == cnt){
            //   setTimeout(() => {
            //     this.graphGenerateCompleteObservable.next(true);
            //     this.common.setLoader(false);
            //   });
            // }
          }
        }

      },
      err => {
        console.log("ERROR");
        console.log(err);
        this.common.openSnackBar('Please try later', '');
      }
    );
  }

  generateAnalyticalGraph() {
    this.graphTemplateComplete = true;
    // console.log('analyticalDataFilterBy', this.lineChartData);
    setTimeout(() => {
      this.common.setLoader(true);
    });
    const formData: any = {
      id: this.historyId,
      duration: this.formModel.filter_by,
      from_date: this.formModel.formDateTime,
      to_date: this.formModel.toDateTime
    }
    this.rtt_max = [];
    this.lineChartData.push(
      { "data": this.rtt_max, "label": 'RTT-AVG' }
    );
    let that = this;
    this.anchor.getPeriodicZoneAnalyticalDataGroupBy(formData).subscribe(
      res => {
        console.log('Call Data', res);
        if (res['status'] == 1) {
          this.ChartDataSets = [];
          this.Label = [];
          if (Object.keys(res['data']).length > 0) {
            let count = 0;
            Object.keys(res['data']).forEach(key => {
              let keyname = key;
              let value = res['data'][key];
              if (value.length > 0) {
                that.rtt_max = [];
                value.forEach(element => {
                  that.rtt_max.push(element.mean ? element.mean : 0)
                  // that.rtt_max.push(element.rtt_avg ? element.rtt_avg : 0)
                  // that.rtt_max.push(element.mean)

                  // Create Labels
                  let unix_timestamp = element.time
                  // Create a new JavaScript Date object based on the timestamp
                  // multiplied by 1000 so that the argument is in milliseconds, not seconds.
                  var date = new Date(unix_timestamp);
                  // Date part from the timestamp
                  var day = date.getDate();
                  // Month part from the timestamp
                  var month = (date.getMonth() + 1);
                  // Year part from the timestamp
                  var fullyear = date.getFullYear();
                  // Hours part from the timestamp
                  var hours = date.getHours();
                  // Minutes part from the timestamp
                  var minutes = "0" + date.getMinutes();
                  // Seconds part from the timestamp
                  var seconds = "0" + date.getSeconds();

                  // Will display date in 10-06:2020 format
                  var formattedDate = day + '-' + month + '-' + fullyear;
                  // Will display time in 10:30:23 format
                  var formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
                  if ((Object.keys(res['data']).length - 1) == count) {
                    that.Label.push(formattedDate + '-' + formattedTime);
                    // if(this.formModel.filter_by === '1h' || this.formModel.filter_by === '10s'){
                    //   that.Label.push(formattedDate + '-' +formattedTime);
                    // }else{
                    //   that.Label.push(formattedDate);
                    // }

                  }
                });
              }
              // let hostName = that.filterDomainName(keyname);
              that.ChartDataSets.push({ "data": that.rtt_max, "label": keyname });
              count++
            });
            setTimeout(() => {
              // this.graphGenerateCompleteObservable.next(true);
              this.common.setLoader(false);
            });
          }
          this.lineChartData = [];
          this.lineChartLabels = [];
          this.lineChartData = this.ChartDataSets;
          this.lineChartLabels = this.Label;
          // console.log('Final Grapd Data', this.ChartDataSets);
          // console.log('Final Grapd Label', this.Label);

        }
        if (res['status'] == 0) {
          this.lineChartData = [];
          this.graphTemplateComplete = false;
          setTimeout(() => {
            // this.graphGenerateCompleteObservable.next(true);
            this.common.setLoader(false);
          });
        }
      },
      err => {
        console.log("ERROR");
        console.log(err);
        this.common.openSnackBar('Please try later', '');
        this.graphTemplateComplete = false;
        setTimeout(() => {
          // this.graphGenerateCompleteObservable.next(true);
          this.common.setLoader(false);
        });
      }
    );
  }

  graphFilter(filter_by) {
    this.formModel.filter_by === filter_by;
    // Reset form and to Date and intervel variable
    let differance = this.diff_minutes(new Date(this.queryEndDate), this.queryMaxDate);
    // console.log('Differ', differance);
    if (differance > 0) {
      this.formModel.filter_by = filter_by;
      if (filter_by == '10s') {
        var limitTime = 3.5 * 60 * 1000; /* ms */
        this.queryMaxDate = new Date();
        this.queryMinDate = new Date();
        // var minusTine = 10 * 1000; /* ms */
        this.formModel.formDateTime = new Date(this.queryMaxDate.getTime() - limitTime).toISOString();
        this.formModel.toDateTime = new Date().toISOString();
        // this.formModel.toDateTime = new Date(this.queryMaxDate.getTime() - minusTine).toJSON();
        this.generateLiveGraph();
      }
      else if (filter_by == '1440s') {
        var limitTime = 1440 * 60 * 1000; /* ms */
        this.queryMaxDate = new Date();
        this.queryMinDate = new Date();
        this.formModel.formDateTime = new Date(this.queryMaxDate.getTime() - limitTime).toISOString();
        this.formModel.toDateTime = new Date().toISOString();
        this.generateAnalyticalGraph();
      }
      else {
        this.formModel.formDateTime = new Date(this.queryStartDate).toISOString();
        this.formModel.toDateTime = new Date(this.queryEndDate).toISOString();
        this.generateAnalyticalGraph();
      }

      // this.generateAnalyticalGraph();
    }
    else {
      if (filter_by == '60s') {
        var limitTime = 30 * 60 * 1000; /* ms */
        this.queryMaxDate = new Date(this.queryEndDate);
        this.queryMinDate = new Date();
        this.formModel.formDateTime = new Date(this.queryMaxDate.getTime() - limitTime).toISOString();
        this.formModel.toDateTime = new Date(this.queryEndDate).toISOString();
        this.generateAnalyticalGraph();
      }
      else if (filter_by == '1440s') {
        var limitTime = 1440 * 60 * 1000; /* ms */
        this.queryMaxDate = new Date();
        this.queryMinDate = new Date();
        // this.formModel.formDateTime = new Date(this.queryMaxDate.getTime() - limitTime).toISOString();
        // this.formModel.toDateTime = new Date(this.queryEndDate).toISOString();
        this.formModel.formDateTime = new Date(this.queryStartDate).toISOString();
        this.formModel.toDateTime = new Date(this.queryEndDate).toISOString();
        this.generateAnalyticalGraph();
      }
      else {
        this.formModel.formDateTime = new Date(this.queryStartDate).toISOString();
        this.formModel.toDateTime = new Date(this.queryEndDate).toISOString();
        this.generateAnalyticalGraph();
      }
      // this.generateAnalyticalGraph();
    }
  }


  csv() {
    // console.log('uyu');
    this.downloadProgressBer = true;
    const formData: any = {
      history_id: this.historyId,
      filename: 'Periodic'
    }
    var current_date = new Date();
    var current_timestamp = current_date.getTime();
    this.anchor.getDownload('Periodic-' + current_timestamp + '.csv', this.historyId);
    this.anchor.downloadCsvCallBackResult$.subscribe(value => {
      // console.log('downloadCsvCallBackResult', value);
      if (value) {
        // console.log('success');
        this.downloadProgressBer = false;
      }
    });
  }

  showDetails(history_id, history_details_id) {
    console.log('Details View');
    // let encript_history_id = btoa('10')
    // let  encript_history_details_id = btoa(history_details_id);
    // this.router.navigate(['/periodic-analytics-details/' + encript_history_details_id]);
  }
  printPage() {
    window.print();
  }

  backToHistoryList() {
    this.periodicSubscription && this.periodicSubscription.unsubscribe();
    this.router.navigate(['/query/executed-history/periodic/']);
  }

  generateEchartView() {
    this.dom = document.getElementById("echart-container");
    this.myChart = echarts.init(this.dom);
    this.option = {
      // color: this.colors,
      legend: {
        // Try 'horizontal' Or 'vertical'
        orient: 'horizontal',
        // right: 10,
        top: 0,
        // top: 'center'
      },
      tooltip: {
        trigger: 'axis',
        position: function (pt) {
          return [pt[0], '1%'];
        }
      },
      toolbox: {
        feature: {
          // dataZoom: {
          //   yAxisIndex: 'none'
          // },
          restore: {},
          saveAsImage: {}
        }
      },
      xAxis: {
        type: 'time',
        boundaryGap: false,
        data: []
      },
      yAxis: {
        type: 'value',
        boundaryGap: [0, '100%']
      },
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: this.echartMeterRange
        },
        {
          start: 0,
          end: this.echartMeterRange
        }
      ],
      series: [
        {
          name: 'RTT-AVG',
          type: 'line',
          smooth: true,
          symbol: 'none',
          areaStyle: {},
          data: this.data
        }
      ]
    };
    const formData = {
      id: this.historyId
    }
    this.anchor.getAnalyticalDataById(formData).subscribe(
      res => {
        if (res['status'] == 1) {
          console.log(res);
          if (Object.keys(res['data']).length > 0) {
            let legend_data = [];
            this.seriesis = [];
            let lagend_name = '';
            // let xAxis_data = [];
            let c = 0;
            Object.keys(res['data']).forEach(key => {
              this.HostName = '';
              let keyname = key;
              let value = res['data'][key];
              legend_data.push(keyname);
              this.HostName = keyname;
              console.log('keyname', keyname);
              console.log('value', value);
              this.data = [];
              let seriesi_data = [];
              let xAxis_data = [];
              value.forEach(element => {
                console.log('Time', element['time'], 'Data', element['rtt_avg']);
                // let now = new Date((base += oneDay));
                // console.log('Now Time', now);
                if ('time' in element) {
                  var date = new Date(element['time']).toISOString();
                  console.log('Now Set Time', date);
                  this.data.push([date, element['rtt_avg']]);
                  xAxis_data.push(date);
                  seriesi_data.push([date, element['rtt_avg']]);
                  this.echartMeterRange++;
                }
              });
              console.log('Seriesis', seriesi_data);
              console.log('xAxis_data', xAxis_data);
              this.seriesis.push({
                name: this.HostName,
                type: 'line',
                areaStyle: {color: new echarts.graphic.LinearGradient([
                  {
                    offset: 0,
                    color: this.colors[c]
                  }
                ])},
                data: seriesi_data
              });
              c++;
            });
            console.log('legend_data', legend_data);
            console.log('seriesis', this.seriesis);
            // Set options
            this.myChart.setOption({
              xAxis: {
                type: 'time',
                splitLine: { show: false },
                // boundaryGap: false,
                // data: xAxis_data
              },
              dataZoom: [
                {
                  type: 'inside',
                  start: 0,
                  end: this.echartMeterRange
                },
                {
                  start: 0,
                  end: this.echartMeterRange
                }
              ],
              series: this.seriesis
            });
          }
        }

      },
      err => {
        console.log("ERROR");
        console.log(err);
        this.common.openSnackBar('Please try later', '');
      }
    );

    if (this.option && typeof this.option === 'object') {
      console.log(this.option);
      console.log('option Data', this.data);
      this.myChart.setOption(this.option);
    }
  }

  generateNewEchartSet(data_arr){
    console.log(data_arr);
    if (Object.keys(data_arr).length > 0) {
      let legend_data = [];
      let lagend_name = '';
      Object.keys(data_arr).forEach(key => {
        this.HostName = '';
        let keyname = key;
        let value = data_arr[key];
        legend_data.push(keyname);
        this.HostName = keyname;
        console.log('keyname', keyname);
        console.log('value', value);
        this.data = [];
        let seriesi_data = [];
        let xAxis_data = [];
        value.forEach(element => {
          console.log('Time', element['time'], 'Data', element['rtt_avg']);
          // let now = new Date((base += oneDay));
          // console.log('Now Time', now);
          if ('time' in element) {
            var date = new Date(element['time']).toISOString();
            console.log('Now Set Time', date);
            this.data.push([date, element['rtt_avg']]);
            xAxis_data.push(date);
            seriesi_data.push([date, element['rtt_avg']]);
            this.echartMeterRange++;
          }
        });
        this.seriesis.push({
          data: seriesi_data
        });
      });
      // Set options
      this.myChart.setOption({
        // dataZoom: [
        //   {
        //     type: 'inside',
        //     start: 0,
        //     end: this.echartMeterRange
        //   },
        //   {
        //     start: 0,
        //     end: this.echartMeterRange
        //   }
        // ],
        series: this.seriesis
      });
      // this.data.shift();
    }
  }

  ngOnDestroy() {
    this.periodicSubscription && this.periodicSubscription.unsubscribe();
  }

}
