import { NgModule } from '@angular/core';
import { ModuleWithProviders} from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PointUtilizationDetailsComponent } from './point-utilization-details.component';

const UTILIZATION_ROUTER: Routes = [
  {
    path: '', 
    component: PointUtilizationDetailsComponent,
    children: [
      { path:'utilization-details', component: PointUtilizationDetailsComponent }
    ]
  },
];

export const UtilizationRouting: ModuleWithProviders = RouterModule.forChild(UTILIZATION_ROUTER);