import { Component, OnInit, AfterViewInit, Inject } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { AnchorService } from '../../services/anchor/anchor.service';
import { CommonService } from '../../services/common/common.service';
import { AccessibilityService } from '../../services/accessibility/accessibility.service';
import { MassageDialogModel, MassageBoxComponent } from '../../global/massage-box/massage-box.component';

@Component({
  selector: 'app-periodic-query',
  templateUrl: './periodic-query.component.html',
  styleUrls: ['./periodic-query.component.css']
})
export class PeriodicQueryComponent implements OnInit {
  commendSetup: FormGroup;
  commendName = new FormControl('', [Validators.required]);
  commendType = new FormControl('', [Validators.required]);
  commendService = new FormControl('', [Validators.required]);
  commendZone = new FormControl('', [Validators.required]);
  runCount = new FormControl('', [Validators.required]);
  runTime = new FormControl('', [Validators.required]);
  timeUnit = new FormControl('', [Validators.required]);

  historyMasterId = 0;
  runTimeUnit: any = [];
  serviceList: any = [];
  zoneList: any = [];
  anchorList: any = [];
  modalData: any;
  region: string = 'kolkata';
  aioriAnchorId: string = '';
  anchorUserId: number;
  lease_id: string = '';
  domainWiseFormSetArray: any = [];
  periodicFormSetArray: any = [];
  periodicUpdateArray: any = [];
  displayZoneArea: boolean = false;
  displatAnchorArea: boolean = true;
  showLoder: boolean = false;
  selectedRadio = 'anchor'; //default value
  leaseId: number = 0;

  constructor(
    _formbuilder: FormBuilder,
    public dialogRef: MatDialogRef<PeriodicQueryComponent>, @Inject(MAT_DIALOG_DATA) 
    public commendData:any,
		private dialog: MatDialog,
		private ar: ActivatedRoute, 
    private router: Router,
    private common: CommonService,
    private anchor: AnchorService,
    private accessibilityservice: AccessibilityService,
  ) { 
    this.commendSetup = _formbuilder.group({
      commendName: this.commendName,
      commendType: this.commendType,
      commendService: this.commendService,
      commendZone: this.commendZone,
      runTime: this.runTime,
      runCount: this.runCount,
      timeUnit: this.timeUnit
    });
    // Get Data From Parent Component
    this.modalData = commendData;
    console.log(this.modalData);
  }

  ngOnInit() {
    this.runTimeUnit = [
      {name:'Minute', value:'minute'},
      {name:'Hour', value:'hour'},
      {name:'Day', value:'day'}
    ]
    this.allServiceList();
    this.allZoneList();
    this.allUserAnchorList();
    // Set Value modal field
    this.commendSetup.setValue({
      commendName: this.modalData.commend,
      commendType: 'periodic',
      commendService: [],
      commendZone: [],
      runTime: '10',
      runCount: '3',
      timeUnit: 'minute'
    })
  }

  radioChange(e){
    // console.log(e);
    if(e.value === 'zone'){
      this.displayZoneArea = true;
      this.displatAnchorArea = false;
    }else{
      this.displayZoneArea = false;
      this.displatAnchorArea = true;
    }
  }

  allServiceList(){
    this.serviceList = [];
    this.accessibilityservice.getAllHostGroupList().subscribe(
      res => {
        if(res['status'] == 1){
          this.serviceList = res['hostgroup'];
          console.log(this.serviceList);
        }
        if(res['status'] == 0){
          this.serviceList = [];
        }
      },
      err => {
        console.log("ERROR");
        console.log(err);
        this.common.openSnackBar('Please try later', '');
      }
    );
  }

  allZoneList(){
    this.zoneList = [];
    this.accessibilityservice.getAllZoneList().subscribe(
      res => {
        if(res['status'] == 1){
          this.zoneList = res['zone'];
          console.log(this.zoneList);
        }
        if(res['status'] == 0){
          this.zoneList = [];
        }
      },
      err => {
        console.log("ERROR");
        console.log(err);
        this.common.openSnackBar('Please try later', '');
      }
    );
  }

  allUserAnchorList(){
    this.anchorList = [];
    this.anchor.getUserAnchors().subscribe(
      res => {
        if(res['status'] == 1){
          this.anchorList = res['anchor'];
          console.log('anchorList', this.anchorList);
        }
        if(res['status'] == 0){
          this.anchorList = [];
        }
      },
      err => {
        console.log("ERROR");
        console.log(err);
        this.common.openSnackBar('Please try later', '');
      }
    );
  }

  onSelectionAnchor(event){
    console.log(event)
    let that = this;
    let anchorID = event.value;
    if(this.anchorList.length > 0){
      this.anchorList.forEach(element => {
        if(element.id == anchorID){
          console.log(element)
          that.region = element.location;
          that.aioriAnchorId = element.aiori_anchor_id;
          that.lease_id = element.lease_id;
          that.anchorUserId = element.user;
          console.log('that.region', that.region);
          console.log('that.aioriAnchorId', that.aioriAnchorId);
          console.log('that.lease_id', that.lease_id);
          console.log('that.anchorUserId', that.anchorUserId);
        }
      });
    }
  }

  checkPointUtilization(){
    console.log('check points')
    let massageTitle = 'No Points';
    // Generate update json data for insufficient point
    this.showLoder = true;
    const putData = {
      id: this.historyMasterId,
      use_point_status: "insufficient point",
      status: "failure"
    }
    // calculate howmany points will be deduct
    let deduct_points = 0
    if(this.domainWiseFormSetArray.length > 0){
      deduct_points = (parseInt(this.domainWiseFormSetArray.length)*5);
    }
    // Status Are
    // user have no subscription points earn entry = 0
    // successfully deduct point status = 1
    // remaining_points is less then deduct point status = 2
    if(this.domainWiseFormSetArray.length > 0){
      const formData: any = {
        command_name: this.commendSetup.value.commendName,
        run_time: this.commendSetup.value.runTime,
        utilize_point: deduct_points,
        anchor_user_id: this.anchorUserId
      }
      this.anchor.utilizePoint(formData).subscribe(
        res => {
          if(res['status']==1) {
            this.showLoder = false;
            // successfully deduct point
            if(this.displayZoneArea){
              this.postCommendRequestToAiro();
            }
            if(this.displatAnchorArea){
              this.postCommendRequestToAiroWithLeaseId();
            }
          }
          // else if(res['status']==2){
          //   // remaining_points is less then deduct point
          //   //execution history update status
          //   this.anchor.commendHistoryUpdateById(putData).subscribe(
          //     res => {
          //       this.dialogRef.close();
          //       // open massage popup
          //       const message = 'You have no sufficient points to execute this command. Please contact to administrater.'; // generate massage
          //       const dialogData = new MassageDialogModel("Insufficient Points", message); // Call Global massage box with parameters, 
          //       const dialogRefMsg = this.dialog.open(MassageBoxComponent, { // 2 parameters are givin
          //         maxWidth: "400px",
          //         data: dialogData
          //       });
          //       dialogRefMsg.afterClosed().subscribe(dialogResult => { // After close the massage box return true or false base on button click
          //       });
          //     },
          //     err => {
          //       console.log("ERROR");
          //       console.log(err);
          //       this.common.openSnackBar('Please try later', '');
          //     }
          //   );
          // }
          else{
            this.showLoder = false;
            if(res['status']==2){
              massageTitle = 'Insufficient Points';
            }
            // user have no subscription points earn
            // this.dialogRef.close();
            // // open massage popup
            // const message = 'You have no points to execute this command. Please contact to administrater.'; // generate massage
            // const dialogData = new MassageDialogModel("No Points", message); // Call Global massage box with parameters, 
            // const dialogRefMsg = this.dialog.open(MassageBoxComponent, { // 2 parameters are givin
            //   maxWidth: "400px",
            //   data: dialogData
            // });
            // dialogRefMsg.afterClosed().subscribe(dialogResult => { // After close the massage box return true or false base on button click
            // });
              //execution history update status
            this.anchor.commendHistoryUpdateById(putData).subscribe(
              res => {
                this.dialogRef.close();
                // open massage popup
                const message = 'You have no sufficient points to execute this command. Please contact to administrater.'; // generate massage
                const dialogData = new MassageDialogModel(massageTitle, message); // Call Global massage box with parameters, 
                const dialogRefMsg = this.dialog.open(MassageBoxComponent, { // 2 parameters are givin
                  maxWidth: "400px",
                  data: dialogData
                });
                dialogRefMsg.afterClosed().subscribe(dialogResult => { // After close the massage box return true or false base on button click
                });
              },
              err => {
                this.showLoder = false;
                console.log("ERROR");
                console.log(err);
                this.common.openSnackBar('Please try later', '');
              }
            );
          }
        },
        err => {
          console.log("ERROR");
          console.log(err);
          this.common.openSnackBar('Please try later', '');
        },
      );
    }else{
      this.showLoder = false;
    }
  }

  submitExecution(){
    this.domainWiseFormSetArray = [];
    console.log(this.commendSetup.valid);
    if(this.commendSetup.valid){
      this.showLoder = true;
      /// Post time convert to minits
      let runTimeInMinits = this.commendSetup.value.runTime;
      if(this.commendSetup.value.timeUnit == 'day'){
        runTimeInMinits = ((this.commendSetup.value.runTime * 24) * 60);
      }
      if(this.commendSetup.value.timeUnit == 'hour'){
        runTimeInMinits = (this.commendSetup.value.runTime * 60);
      }

      let commend_value: string = this.commendSetup.value.commendName;
      let serviceids = [];
      serviceids.push(this.commendSetup.value.commendService);
      let zoneids = [];
      let anchorids = [];
      if(this.displayZoneArea){
        console.log('Display Zone', this.commendSetup.value.commendZone);
        zoneids.push(this.commendSetup.value.commendZone);
      }
      if(this.displatAnchorArea){
        console.log('Display Area', this.commendSetup.value.commendZone);
        anchorids.push(this.commendSetup.value.commendZone);
      }

      const reqData = {
        cmd_value: commend_value.toLowerCase(),
        cmd_type: this.commendSetup.value.commendType,
        service_ids: serviceids,
        zone_ids: zoneids,
        anchor_ids: anchorids,
        run_time: runTimeInMinits,
        run_count: this.commendSetup.value.runCount,
        time_unit: this.commendSetup.value.timeUnit
      }
      // console.log('Payload set', reqData);
      // return false
      /// Post Data to our database through API
      this.anchor.postPeriodicCommendHistory(reqData).subscribe(
        res => {
          if(res['status'] == 1){
            this.showLoder = false;
            this.historyMasterId = + res['serialize_obj'][0]['parent_id'];
            this.domainWiseFormSetArray = res['serialize_obj'];
            this.checkPointUtilization();
            // this.postCommendRequestToAiro();
            // console.log(this.zoneList);
          }
          if(res['status'] == 0){
            this.showLoder = false;
            this.domainWiseFormSetArray = [];
          }
        },
        err => {
          this.showLoder = false;
          console.log("ERROR");
          console.log(err);
          this.common.openSnackBar('Please try later', '');
        }
      );
    }
  }

  postCommendRequestToAiro(){
    this.showLoder = true;
    let that = this;
    console.log('this.domainWiseFormSetArray.length', this.domainWiseFormSetArray.length);
    if(this.domainWiseFormSetArray.length > 0){
      this.domainWiseFormSetArray.forEach(element => {
        // console.log(element);
        const reqCmdData: any = {
          cmd_value: element.cmd_value,
          cmd_type: element.cmd_type,
          run_time: element.run_time,
          region: element.region,
          run_count: element.run_count,
          hosts: element.hosts
        } 
        const post_param = JSON.stringify(reqCmdData);
        // console.log('postCommendRequestToAiro', post_param);
        /// Send data to AIRO Server through API
        that.anchor.postExecuteCommendToAIRO(post_param).subscribe(
          res => {
            that.periodicUpdateArray.push(
              {
                parent_id: element.parent_id,
                chield_id: element.chield_id,
                query_id: res['id'],
                status: "running"
              }
            );
              // check total query post is complete or not
              if(that.domainWiseFormSetArray.length === that.periodicUpdateArray.length){
                that.showLoder = false;
                that.updateCommendHistory();
              }
          },
          err => {
            this.showLoder = false;
            console.log("ERROR");
            console.log(err);
            this.common.openSnackBar('Connection error from AIRO Server', '');
          }
        );
      });
    }
    else{
      this.common.openSnackBar('connection error please try later', '');
    }
  }

  postCommendRequestToAiroWithLeaseId(){
    this.showLoder = true;
    console.log('Lease');
    this.leaseId = 0;
    let that = this;
    if(this.anchorList.length > 0){
      this.anchorList.forEach(item => {
        if(item.id === this.commendSetup.value.commendZone){
          that.leaseId = item.lease_id;
        }
      });
    }
    console.log('this.domainWiseFormSetArray.length', this.domainWiseFormSetArray.length);
    if(this.domainWiseFormSetArray.length > 0){
      this.domainWiseFormSetArray.forEach(element => {
        // console.log(element);
        const reqCmdData: any = {
          cmd_value: element.cmd_value,
          cmd_type: element.cmd_type,
          run_time: element.run_time,
          region: element.region,
          run_count: element.run_count,
          hosts: element.hosts,
          anchors: element.anchors
        }
        // if(this.displayZoneArea){
        //   // Payload For Zone Wise Form Data
        //   reqCmdData['region'] = element.region
        // }
        // if(this.displatAnchorArea){
        //   // Payload For Anchor Wise Form Data
        //   reqCmdData['anchors'] = element.anchors
        // }
        
        const post_param = JSON.stringify(reqCmdData);
        // console.log('postCommendRequestToAiro', post_param);
        /// Send data to AIRO Server through API
        that.anchor.postExecuteCommendWithLeaseToAIRO(post_param, this.leaseId).subscribe(
          res => {
            that.periodicUpdateArray.push(
              {
                parent_id: element.parent_id,
                chield_id: element.chield_id,
                query_id: res['id'],
                status: "running"
              }
            );
              // check total query post is complete or not
              if(that.domainWiseFormSetArray.length === that.periodicUpdateArray.length){
                this.showLoder = false;
                that.updateCommendHistory();
              }
          },
          err => {
            this.showLoder = false;
            console.log("ERROR");
            console.log(err);
            this.common.openSnackBar('Connection error from AIRO Server', '');
          }
        );
      });
    }
    else{
      this.showLoder = false;
      this.common.openSnackBar('connection error please try later', '');
    }
  }

  updateCommendHistory(){
    if(this.periodicUpdateArray.length > 0){
      this.showLoder = true;
      const reqData: any = {
        update_set: this.periodicUpdateArray
      }
      // After return AIRO Server update history table
      this.anchor.putPeriodicCommendHistory(reqData).subscribe(
        res => {
          // console.log('Execution Details', res);
          if(res['status'] == 1){
            this.showLoder = false;
            this.dialogRef.close(true);
          }
          else{
            this.showLoder = false;
            this.common.openSnackBar('Please try later', '');
          }
        },
        err => {
          this.showLoder = false;
          console.log("ERROR");
          console.log(err);
          this.common.openSnackBar('No connection error from AIRO Server', '');
        }
      );
    }
    else{
      this.showLoder = false;
      this.common.openSnackBar('Update payload is blank.', '');
    }
  }

  closePopup() {
    this.dialogRef.close();
  }

}
