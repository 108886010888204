import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { ConfigurationService } from '../services/config/configuration.service';
import { ZoneRouting } from './zone-routing.module';
import { HttpModule } from '@angular/http';
import { HttpClient, HttpHeaders, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { AgmCoreModule } from '@agm/core';
import { ZoneManagementComponent } from './zone-management.component';
import { AddEditZoneComponent } from './add-edit-zone.component';
import { AnchorlocationMapviewComponent } from '../anchor/anchorlocation-mapview/anchorlocation-mapview.component';
import { MapviewanchorbyzoneComponent } from './mapviewanchorbyzone/mapviewanchorbyzone.component';
import { MapvieweditanchorbyzoneComponent } from './mapvieweditanchorbyzone/mapvieweditanchorbyzone.component';

@NgModule({
  declarations: [ZoneManagementComponent, AddEditZoneComponent, AnchorlocationMapviewComponent, MapviewanchorbyzoneComponent, MapvieweditanchorbyzoneComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
	SharedModule,
	HttpModule,
	HttpClientModule,
	ZoneRouting,
	AgmCoreModule.forRoot({
		apiKey: 'AIzaSyAXxByh8R5Ouwe7ouvoqcgdlMXSytHdX6I',
		libraries: ['places', 'drawing', 'geometry']
		})
  ],
  entryComponents: [
		// AddEditZoneComponent,
		AnchorlocationMapviewComponent
  	],
	providers: [
		ConfigurationService,
		// DatePipe,
		CookieService,
	]
  
})
export class ZoneModule { }
