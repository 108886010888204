import { Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AccessibilityService } from '../services/accessibility/accessibility.service';
import { CommonService } from '../services/common/common.service';
// import { AddEditZoneComponent } from './add-edit-zone.component';
import { ConfirmDialogModel, ConfirmDialogComponent } from '../global/confirm-dialog/confirm-dialog.component';
export interface PeriodicElement {
  position: number;
  zone_name: string;
  zone_anchor_name: string;
  zone_area_list: string;
  action: string;
}

@Component({
  selector: 'app-zone-management',
  templateUrl: './zone-management.component.html',
  styleUrls: ['./zone-management.component.css']
})
export class ZoneManagementComponent implements OnInit {
  userdetails: any;
  zoneList: any;
  displayedColumns: string[] = ['position', 'zone_name', 'zone_anchor_name', 'zone_area_list', 'action'];
  dataSource: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(
    private common: CommonService,
    private accessibilityservice: AccessibilityService,
    private router: Router,
    private dialog: MatDialog
  ) {
    let userData = JSON.parse(localStorage.getItem('userLoginData'));
    if(userData != '' || userData !=undefined){
      this.userdetails = userData;
    }
   }

  ngOnInit() {
    this.GetZoneList();
  }

  GetZoneList(){
    this.zoneList = [];
    this.accessibilityservice.getAllZoneList().subscribe(
      res => {
        if(res['status'] == 1){
          console.log('Zone List', res);
          let that = this;
          if(res['zone'].length > 0){
            let i = 1;
            res['zone'].forEach(element => {
              let area_list = "";
              that.zoneList.push(
                {
                  id:element.id,
                  position: i,
                  zone_name: element.user_zone_name,
                  zone_anchor_name: element.user_anchor_names.length > 0 ? element.user_anchor_names.toString() : null,
                  zone_area_list: element.user_anchor_locations.length > 0 ? element.user_anchor_locations.toString() : null,
                  action:'NA'
                }
              );
              i++;
            });
            this.dataSource = new MatTableDataSource(that.zoneList);
            this.dataSource.paginator = this.paginator;
          }
        }
        if(res['status'] == 0){
          this.zoneList = [];
          this.dataSource = new MatTableDataSource(this.zoneList);
        }
      },
      err => {
        console.log("ERROR");
        console.log(err);
        this.common.openSnackBar('Please try later', '');
      }
    );
  }

  // dialogRefTags : MatDialogRef<AddEditZoneComponent> | null;
  openPopUp(zone_id){
    console.log('Zone Id', zone_id);
    this.router.navigate(['/zone/map-view/']);
  }

  deletZone(zone_id){
    console.log('zone_id', zone_id);
    const formData: any = {
      zone_id: zone_id,
      is_delete: true
    }
    const message = 'Are you sure you want to delete this zone?'; // generate massage
    const dialogData = new ConfirmDialogModel("Confirm Action", message); // Call Global dilog box with parameters, 
    const dialogRef = this.dialog.open(ConfirmDialogComponent, { // 2 parameters are givin
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => { // After close the dilog box return true or false base on button click
      if(dialogResult === true){ // If return true process will execute, if false brake
        //Delete service details through API
        this.accessibilityservice.updateZone(formData).subscribe(
          res => {
            if(res['status'] == 1){
              this.GetZoneList();
            }
            else{
              this.common.openSnackBar('Zone is not deleted please try later', '');
            }
          },
          err => {
            console.log("ERROR");
            console.log(err);
            this.common.openSnackBar('please try later', '');
          }
        );
      }
    });
  }

}
