import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { SubscriptionService } from '../services/subscription/subscription.service';
export interface PeriodicElement {
  position: number;
  command_name: string;
  total_points: number;
  used_points: number;
  earn_points: number;
  type: string;
  remaining_points: number;
  created_date: string;
}

@Component({
  selector: 'app-point-utilization-details',
  templateUrl: './point-utilization-details.component.html',
  styleUrls: ['./point-utilization-details.component.css']
})
export class PointUtilizationDetailsComponent implements OnInit {
  userdetails: any;
  utilizationList: any = [];
  displayedColumns: string[] = ['position', 'command_name', 'total_points', 'used_points', 'earn_points', 'type', 'remaining_points', 'created_date'];
  dataSource: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private subscription_service: SubscriptionService,
    private _router: Router
  ) { 
    let userData = JSON.parse(localStorage.getItem('userLoginData'));
    if(userData != '' || userData !=undefined){
      this.userdetails = userData;
    }

    console.log('This is points Utilasion Page');
  }

  ngOnInit() {
    this.subscription_service.userUtilizationList().subscribe(
			res => {
				// console.log(res);
				if (res['status'] == 1) {
          this.utilizationList = [];
          let that = this;
          if(res['utilization'].length > 0){
            let i = 1;
            res['utilization'].forEach(element => {
              that.utilizationList.push(
                {
                  id: element.id,
                  position: i,
                  command_name: element.command_name,
                  total_points: parseInt(element.total_points),
                  used_points: parseInt(element.used_points),
                  earn_points: parseInt(element.earn_points),
                  type: element.insert_status,
                  remaining_points: parseInt(element.remaining_points),
                  created_date: element.created_date
                }
              );
              i++;
            });
            this.dataSource = new MatTableDataSource(this.utilizationList);
            this.dataSource.paginator = this.paginator;
          }
        }
        else{
          this.dataSource = new MatTableDataSource(this.utilizationList);
          this.dataSource.paginator = this.paginator;
        }
			},
			err => {
				console.log("ERROR");
				console.log(err);
			}
		);
  }

}
