import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { ConfigurationService } from '../services/config/configuration.service';
import { HostGroupRouting } from './hostgroup-routing.module';
import { HttpModule } from '@angular/http';
import { HttpClient, HttpHeaders, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { HostGroupManagementComponent } from './host-group-management.component';
// import { AddEditHostGroupComponent } from './add-edit-host-group.component';

@NgModule({
  declarations: [HostGroupManagementComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
	SharedModule,
	HttpModule,
	HttpClientModule,
	HostGroupRouting
  ],
  entryComponents: [
		// AddEditHostGroupComponent
  	],
	providers: [
		ConfigurationService,
		// DatePipe,
		CookieService,
	]
})
export class HostgroupModule { }
