import { Component, OnInit, Inject, ViewChild, ElementRef, NgZone } from '@angular/core';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { Validators, FormBuilder, FormGroup, FormControl, FormArray } from '@angular/forms';
import { DISABLED } from '@angular/forms/src/model';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { AnchorService } from '../../services/anchor/anchor.service';
import { CommonService } from '../../services/common/common.service';

@Component({
  selector: 'app-register-anchor',
  templateUrl: './register-anchor.component.html',
  styleUrls: ['./register-anchor.component.css']
})
export class RegisterAnchorComponent implements OnInit {
	formModel: any = {};
	leaseFromDate: Date;
	anchorRegisterSetup: FormGroup;
	Qrcode = new FormControl('', []);
	fromDate = new FormControl('', [Validators.required]);
  	toDate = new FormControl('', [Validators.required]);
	latitude: any;
	longitude: any;
	address: string;
	location: string;
	country: string = '';
	postal_code: any;
	sublocality_level_1: string = '';
	sublocality_level_2: string = '';
	sublocality_level_3: string = '';
	administrative_area_level_1: string = '';
	administrative_area_level_2: string = '';
	address_error: boolean = false;
	showRegisterLoder: boolean = false;
	imageFileName: string = '';
	private geoCoder;
	@ViewChild('search')
	public searchElementRef: ElementRef;

	constructor(
		_formbuilder: FormBuilder,
		private mapsAPILoader: MapsAPILoader,
    	private ngZone: NgZone,
		public dialogRef: MatDialogRef<RegisterAnchorComponent>, @Inject(MAT_DIALOG_DATA) 
		public anchorData:any,
		private dialog: MatDialog,
		private ar: ActivatedRoute, 
		private router: Router,
		private anchor: AnchorService,
		private common: CommonService,
	) { 
		this.latitude = parseFloat('22.5726');
    	this.longitude = parseFloat('88.3639');
		this.anchorRegisterSetup = _formbuilder.group({
			Qrcode: this.Qrcode,
			fromDate: this.fromDate,
      		toDate:  this.toDate
		});
		// Set Default Date In Datepicker
		this.leaseFromDate = new Date();
		this.fromDate.setValue(this.leaseFromDate);
		var now = new Date();
		var duedate = new Date(now);
		duedate.setDate(now.getDate() + 365);
		this.toDate.setValue(duedate);
		console.log("Now:", now);
		console.log("Due Date:", duedate);
	}

	ngOnInit() {
		this.mapsAPILoader.load().then(() => {
			// this.setCurrentLocation();
			// this.setRegisterLocation();
			this.geoCoder = new google.maps.Geocoder;
	  
			let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
			autocomplete.addListener("place_changed", () => {
			  this.ngZone.run(() => {
				//get the place result
				let place: google.maps.places.PlaceResult = autocomplete.getPlace();
				console.log('google.maps.places.PlaceResult', place);
				//verify result
				if (place.geometry === undefined || place.geometry === null) {
				  return;
				}
	  
				//set latitude, longitude and zoom
				this.latitude = place.geometry.location.lat();
				this.longitude = place.geometry.location.lng();
				this.getAddress(this.latitude, this.longitude);
			  });
			});
		  });
	}

	getAddress(latitude, longitude) {
		this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
		  console.log(results);
		  console.log(status);
		  if (status === 'OK') {
			if (results[0]) {
			  this.address = results[0].formatted_address;
			  console.log('GEO LOCATION', results[0]);
			  // this.location = results[0].address_components[5].long_name;
			  results[0].address_components.forEach(element => {
				Object.keys(element).forEach(key => {
				  let keyname = key;
				  let value = element[key];
				  if(keyname == 'types'){
					if(value.length > 0){
					  if(value[0] == 'locality'){
						console.log('address_components', keyname);
						console.log('address_components value', value[0]);
						console.log('address_components Area name', element.long_name);
						this.location = element.long_name;
					  }
					  if(value[0] == "administrative_area_level_1"){
						this.administrative_area_level_1 = element.long_name;
						console.log('this.administrative_area_level_1', this.administrative_area_level_1);
					  }
					  if(value[0] == "administrative_area_level_2"){
						this.administrative_area_level_2 = element.long_name;
						console.log('this.administrative_area_level_2', this.administrative_area_level_2);
					  }
					  if(value[0] == "country"){
						this.country = element.long_name;
						console.log('country', this.country);
					  }
					  if(value[0] == "postal_code"){
						this.postal_code = element.long_name;
						console.log('postal_code', this.postal_code);
					  }
					  if(value[2] == "sublocality_level_1"){
						this.sublocality_level_1 = element.long_name;
						console.log('this.sublocality_level_1', this.sublocality_level_1);
					  }
					  if(value[2] == "sublocality_level_2"){
						this.sublocality_level_2 = element.long_name;
						console.log('this.sublocality_level_2', this.sublocality_level_2);
					  }
					  if(value[2] == "sublocality_level_3"){
						this.sublocality_level_3 = element.long_name;
						console.log('this.sublocality_level_3', this.sublocality_level_3);
					  }
					}
				  }
				});
				// if(element.)
			  });
			  console.log('this.location', this.location);
			} else {
			  window.alert('No results found');
			}
		  } else {
			// window.alert('Geocoder failed due to: ' + status);
		  }
	
		});
	  }

	addImage(event) {
		var files: any = {};
		var target: HTMLInputElement = event.target as HTMLInputElement;
		let fileType = target.files[0].type;
		files = target.files[0];
		// console.log('FILE NAME', files[0].name);
		this.imageFileName = target.files[0].name;
		var reader = new FileReader();
		reader.readAsDataURL(target.files[0]);
		console.log('files type', target.files[0].type);
		if (target.files[0].type === "image/jpeg" || 
		target.files[0].type === "image/jpg" || 
		target.files[0].type === "image/png" || 
		target.files[0].type === "image/svg" || 
		target.files[0].type === "image/svg+xml") {
			console.log('files', files);
			this.formModel.qrcode_file = files
			// this.saveImage(files, fileType);
		} else {
			alert('Please upload jpeg/jpg/png/svg files only');
			// this.common.openSnackBar('Please upload jpeg/jpg/png files only','');
		}
	}

	saveImage(file, file_type) {
			
			var form_data = new FormData();
			form_data.append("file", this.formModel.qrcode_file);
			// form_data.append('file_type', file_type);

			this.anchor.anchorRegistration(form_data).subscribe(
				res => {
					if(res['status']==1) {
						alert('Image is not upload')
						setTimeout(() => {
						// this.imageLoader = false;
						});
					} else {
						alert('Image is not upload')
					// this.common.openSnackBar(res['message'],'');
					setTimeout(() => {
						// this.imageLoader = false;
						});
					}
			
					
				},
				err => {
					// this.common.openSnackBar('ERROR IN FILE UPLOAD, PLEASE TRY AGAIN LATER.','');
					console.log(err)
				}
				
			);
		}
	
	anchorRegister(){
		this.address_error = false;
		let form_date = new Date(this.anchorRegisterSetup.value.fromDate).toISOString().substring(0, 10);
    	let to_date = new Date(this.anchorRegisterSetup.value.toDate).toISOString().substring(0, 10);
		var form_data = new FormData();
		form_data.append("file", this.formModel.qrcode_file);
		form_data.append("qr_code", this.anchorRegisterSetup.value.Qrcode);
		form_data.append("date_from", form_date);
		form_data.append("date_to", to_date);
		form_data.append("address", this.address);
		form_data.append("location", this.location);
		form_data.append("lat", this.latitude);
		form_data.append("long", this.longitude);
		form_data.append("administrative_area_level_1", this.administrative_area_level_1);
		form_data.append("administrative_area_level_2", this.administrative_area_level_2);
		form_data.append("sublocality_level_1", this.sublocality_level_1);
		form_data.append("sublocality_level_2", this.sublocality_level_2);
		form_data.append("sublocality_level_3", this.sublocality_level_3);
		form_data.append("country", this.country);
		form_data.append("postal_code", this.postal_code);
		console.log('Validation', form_data);
		console.log('this.anchorRegisterSetup.value', this.anchorRegisterSetup.value);
		if((this.address != undefined && this.location != undefined) ||(this.address != null && this.location != null)){
			this.showRegisterLoder = true;
			this.anchor.anchorRegistration(form_data).subscribe(
				res => {
					if(res['status']==1) {
						// this.leaseRequest(res);
						// alert('Image is not upload')
						// setTimeout(() => {
						// // this.imageLoader = false;
						// });
						this.showRegisterLoder = false;
						this.closePopup();
					} else {
						alert('Image is not upload')
						this.showRegisterLoder = false;
						this.closePopup();
					// // this.common.openSnackBar(res['message'],'');
					// setTimeout(() => {
					// 	// this.imageLoader = false;
					// 	});
					}
				},
				err => {
					this.showRegisterLoder = false;
					this.common.openSnackBar('ERROR IN ANCHOR REGISTRATION, PLEASE TRY AGAIN LATER.','');
					this.closePopup();
					console.log(err)
				}
			);
		}else{
			this.address_error = true;
			this.showRegisterLoder = false;
			console.log('this.address', this.address);
			console.log('this.location', this.location);
			console.log('this.latitude', this.latitude);
			console.log('this.longitude', this.longitude);
			console.log('Anchor Lease address_error', this.address_error);
			return false;
		}
	}

	leaseRequest(data){
		let form_date = new Date(this.anchorRegisterSetup.value.fromDate).toISOString().substring(0, 10);
		let to_date = new Date(this.anchorRegisterSetup.value.toDate).toISOString().substring(0, 10);
	
		const formData = {
		  from_time: form_date,
		  to_time: to_date,
		  lease_id: data['lease_id'],
		  anchor_digest: data['qr_code']
		}

		// const responcedata = {
		// 	request_anchor_id: data['id'],
		// 	anchors: ["954d8e26b9cc602f60c7da216778e995d37b28a660c978c736368095016a0270"]
		// }
		console.log(formData);
		// this.saveUserAncherLeaseDetails(responcedata);
		this.anchor.registerAnchorToAIROI(formData).subscribe(
		  res => {
			console.log('AIORI SERVER RETURN', res);
			res['request_anchor_id'] = data['id'];
			this.saveUserAncherLeaseDetails(res);
		  },
		  err => {
			console.log("ERROR");
			console.log(err);
			this.common.openSnackBar('Anchor is not register in AIROI server please try later', '');
		  }
		);
	}

	saveUserAncherLeaseDetails(data){
		const formData = {
			user_anchor_id: data.request_anchor_id,
		  	anchor_id: data.anchors.toString(),
		}
		this.anchor.updateAnchorRegistration(formData).subscribe(
		  res => {
			if(res['status'] == 1){
			  this.closePopup();
			}
		  },
		  err => {
			console.log("ERROR");
			console.log(err);
			this.common.openSnackBar('Anchor is not register in AIROI server please try later', '');
		  }
		);
	  }


	closePopup() {
		this.dialogRef.close();
	}

}
