import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';

// import { ColorPickerModule } from 'ngx-color-picker';

// import { AuthService,
//          CartService,
//          DialogsService, UserService,
//          RoleService, AuthGuardAdmin, AuthLoginAdmin,
//          GenericService, InterceptorService } from '../_services/index';
// import { FloorPipe } from '../_pipes/floor.pipe';
// import { MonthPipe } from '../_pipes/month.pipe';
// import { ToastComponent } from './toast/toast.component';
// import { LoadingComponent } from './loading/loading.component';
// import { CdkTableModule } from '@angular/cdk/table';
// import { OverlayModule } from '@angular/cdk/overlay';
// import { Angular2CsvModule } from 'angular2-csv';
// import {MatPaginator} from '@angular/material/paginator';
import {
  MatPaginatorModule,
  MatTableModule,
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
} from '@angular/material';
import { ChartsModule } from 'ng2-charts';

// import { Ng2DatetimePickerModule } from 'ng2-datetime-picker';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    // MatPaginator,
    HttpClientModule,
    // ColorPickerModule,
    MatSlideToggleModule,
    // Angular2CsvModule
  ],
  exports: [
    // Shared Modules
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    // CDk
    // CdkTableModule,
    // OverlayModule,
    // Material
    // MatPaginator,
    MatPaginatorModule,
    MatTableModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatSnackBarModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatNativeDateModule,
    ChartsModule,
    // Ng2DatetimePickerModule
    // Shared Components
    // ToastComponent,
    // LoadingComponent,
    // FloorPipe,
    // MonthPipe,
    // ColorPickerModule
  ],
  declarations: [
    // ToastComponent,
    // LoadingComponent,
    // FloorPipe,
    // MonthPipe
    ],
  providers: [
    // AuthGuardAdmin,
    // AuthLoginAdmin,
    // AuthService,
    // CartService,
    // DialogsService,
    // GenericService,
    // RoleService,
    // UserService,
    // ToastComponent,
    // { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true }
  ]
})
export class SharedModule { }
