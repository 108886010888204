import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { DatePipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { AgmCoreModule } from '@agm/core';
// import {MatPaginator} from '@angular/material/paginator';
// import {MatTableDataSource} from '@angular/material/table';
// import { HttpModule } from '@angular/http';
// import { HttpClient, HttpHeaders, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// import { MatMenuModule } from '@angular/material/menu';
// import { MatDatepickerModule } from '@angular/material/datepicker';
// import { MatSlideToggleModule } from '@angular/material/slide-toggle';
// import { CookieService } from 'ngx-cookie-service';
// import { NpnSliderModule } from "npn-slider";
// import { MatFormFieldModule, MatInputModule } from '@angular/material'
// import {
// 	MatButtonModule,
// 	MatFormFieldModule,
// 	MatInputModule,
// 	MatRippleModule,
// 	MatPaginatorModule,
// 	MatProgressBarModule,
// 	MatProgressSpinnerModule,
// 	MatSortModule,
// 	MatTableModule,
// 	MatSliderModule,
// 	MatTabsModule,
// 	MatNativeDateModule,
// 	MatSnackBarModule,
// 	MatTooltipModule,
// 	MatIconModule,
// 	MatChipsModule,
// 	MatAutocompleteModule,
// 	MatExpansionModule,
// 	MatDialogModule,
// } from '@angular/material';
// import { ChartsModule } from 'ng2-charts';
// import { MatCheckboxModule } from '@angular/material/checkbox';
// import { MatSelectModule } from '@angular/material/select';
// import { MatRadioModule } from '@angular/material/radio';

// import { SignuplayoutComponent } from './layouts/signuplayout/signuplayout.component';
import { CommonService } from './services/common/common.service';
import { ConfigurationService } from './services/config/configuration.service';
import { HostgroupModule } from './hostgroup/hostgroup.module';
import { ZoneModule } from './zone/zone.module';
// import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
// import { LoginComponent } from './signups_logins/login/login.component';
// import { RegistrationComponent, EmailVarificationComponent } from './signups_logins/registration/registration.component';
// import { ProfileComponent } from './user/profile/profile.component';
// import { DashboardComponent } from './user/dashboard/dashboard.component';
// import { SubscriptionPlansComponent } from './subscription/subscription-plans/subscription-plans.component';
// import { SubscriptionDetailsComponent } from './subscription/subscription-details/subscription-details.component';
// import { CommendComponent, ExecutionCommendMassageComponent, QuerySelectionComponent } from './anchor/commend/commend.component';
// import { AnalyticsComponent } from './anchor/analytics/analytics.component';
// import { ExecutionSetupComponent } from './anchor/execution-setup/execution-setup.component';
// import { ExecutedCommendHistoryComponent } from './anchor/executed-commend-history/executed-commend-history.component';
import { MassageBoxComponent } from './global/massage-box/massage-box.component';
// import { DomainListComponent } from './user/domain-list/domain-list.component';
// import { AddEditDomainComponent } from './user/add-edit-domain/add-edit-domain.component';
// import { PointUtilizationDetailsComponent } from './subscription/point-utilization-details/point-utilization-details.component';
import { ConfirmDialogComponent } from './global/confirm-dialog/confirm-dialog.component';
import { PointsModule } from './points/points.module';
import { UtilizationModule } from './utilization/utilization.module';
import { CommandModule } from './command/command.module';
import { CommandHistoryModule } from './command-history/command-history.module';
import { PeriodicModule } from './periodic/periodic.module';
// import { HostGroupManagementComponent } from './accessibility/host-group-management/host-group-management.component';
// import { AddEditHostGroupComponent } from './accessibility/add-edit-host-group/add-edit-host-group.component';
// import { AddEditZoneComponent } from './accessibility/add-edit-zone/add-edit-zone.component';
// import { ZoneManagementComponent } from './accessibility/zone-management/zone-management.component';
// import { PeriodicQueryComponent } from './anchor/periodic-query/periodic-query.component';
// import { PeriodicAnalyticsComponent } from './anchor/periodic-analytics/periodic-analytics.component';
import { AdminLoginComponent } from './admin/login/login.component';
import { AddEditDomainComponent } from './domain/add-edit-domain.component';
import { AddEditHostGroupComponent } from './hostgroup/add-edit-host-group.component';
import { LeaseanchorComponent } from './user/leaseanchor/leaseanchor.component';
import { RegisterAnchorComponent } from './user-anchor/register-anchor/register-anchor.component';
// import { SettingsComponent } from './admin/settings/settings.component';
// import { NgxEchartsModule } from 'ngx-echarts';
// import { DnsqueryvisualyzerComponent } from './analyzer/dnsqueryvisualyzer/dnsqueryvisualyzer.component';
// import { DiscussionForumComponent } from './discussion-forum/discussion-forum.component';
// import { TraceroutevisualiserComponent } from './anchor/traceroutevisualiser/traceroutevisualiser.component'

@NgModule({
	declarations: [
		AppComponent,
		// SignuplayoutComponent,
		// MainLayoutComponent,
		// LoginComponent, 
		// RegistrationComponent, 
		// EmailVarificationComponent, 
		// ProfileComponent, 
		// DashboardComponent, 
		// SubscriptionPlansComponent, 
		// SubscriptionDetailsComponent, 
		// CommendComponent, 
		// ExecutionCommendMassageComponent, 
		// QuerySelectionComponent, 
		// AnalyticsComponent, 
		// ExecutionSetupComponent, 
		// ExecutedCommendHistoryComponent, 
		MassageBoxComponent, 
		AddEditDomainComponent,
		AddEditHostGroupComponent,
		LeaseanchorComponent,
		RegisterAnchorComponent,
		// DomainListComponent, 
		// AddEditDomainComponent, 
		// PointUtilizationDetailsComponent, 
		ConfirmDialogComponent, 
		// HostGroupManagementComponent, 
		// AddEditHostGroupComponent, 
		// AddEditZoneComponent, 
		// ZoneManagementComponent, 
		// PeriodicQueryComponent, 
		// PeriodicAnalyticsComponent,
		AdminLoginComponent,
		// SettingsComponent, 
		// DnsqueryvisualyzerComponent, 
		// DiscussionForumComponent, 
		// TraceroutevisualiserComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		SharedModule,
		BrowserAnimationsModule,
		// RouterModule,
		FormsModule,
		ReactiveFormsModule,
		// MatPaginator,
		// MatTableDataSource,
		HostgroupModule,
		ZoneModule,
		PointsModule,
		UtilizationModule,
		CommandModule,
		CommandHistoryModule,
		PeriodicModule,
		// MatButtonModule,
		// MatFormFieldModule,
		// MatInputModule,
		// MatRippleModule,
		
		// HttpModule,
		// HttpClientModule,
		// MatPaginatorModule,
		// MatProgressBarModule,
		// MatProgressSpinnerModule,
		// MatSortModule,
		// MatTableModule,
		// MatCheckboxModule,
		// MatIconModule,
		// MatSelectModule,
		// MatRadioModule,
		// MatMenuModule,
		// MatDatepickerModule,
		// MatSlideToggleModule,
		// MatSliderModule,
		// MatTabsModule,
		// MatNativeDateModule,
		// MatSnackBarModule,
		// MatTooltipModule,
		// MatChipsModule,
		// NpnSliderModule,
		// MatAutocompleteModule,
		// MatExpansionModule,
		// MatDialogModule,
		// ChartsModule,
		// AgmCoreModule.forRoot({
		// 	apiKey: 'AIzaSyAXxByh8R5Ouwe7ouvoqcgdlMXSytHdX6I',
		// 	libraries: ['places']
		//   })
		// NgxEchartsModule.forRoot({
		// 	echarts: () => import('echarts'),
		//   }),
	],
	entryComponents: [
		// EmailVarificationComponent,
		// ExecutionCommendMassageComponent,
		// QuerySelectionComponent,
		// PeriodicQueryComponent,
		// ExecutionSetupComponent,
		// AddEditDomainComponent,
		ConfirmDialogComponent,
		MassageBoxComponent,
		AddEditDomainComponent,
		AddEditHostGroupComponent,
		LeaseanchorComponent,
		RegisterAnchorComponent,
		// AddEditHostGroupComponent,
		// AddEditZoneComponent
	],
	providers: [
		CommonService,
		ConfigurationService,
		DatePipe,
		// CookieService,
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
