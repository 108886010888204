import { Component, OnInit, AfterViewInit, Inject } from '@angular/core';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../services/common/common.service';
import { UserService } from '../services/user/user.service';
import { ConditionalExpr } from '@angular/compiler';

@Component({
  selector: 'app-add-edit-domain',
  templateUrl: './add-edit-domain.component.html',
  styleUrls: ['./add-edit-domain.component.css']
})
export class AddEditDomainComponent implements OnInit {
  addDomainSetup: FormGroup;
  domainName = new FormControl('', [Validators.required]);
  ipAddress = new FormControl('', [Validators.required]);
  domain_id: number = 0;
  pageTitle: string = 'Add Domain'
  buttonText: string = 'Save'
  ipError: boolean = false;
  //change this expression with the one you want to test
  //right the expression between / /
  expression = /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))|(^\s*((?=.{1,255}$)(?=.*[A-Za-z].*)[0-9A-Za-z](?:(?:[0-9A-Za-z]|\b-){0,61}[0-9A-Za-z])?(?:\.[0-9A-Za-z](?:(?:[0-9A-Za-z]|\b-){0,61}[0-9A-Za-z])?)*)\s*$)/;
  constructor(
    _formbuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddEditDomainComponent>, @Inject(MAT_DIALOG_DATA) 
    public domainData:any,
		private dialog: MatDialog,
		private ar: ActivatedRoute, 
    private router: Router,
    private common: CommonService,
    private userservice: UserService
  ) { 
    this.addDomainSetup = _formbuilder.group({
      domainName: this.domainName,
      ipAddress: this.ipAddress
    });
    this.domain_id = + this.domainData.id;
    if(this.domain_id !=0){
      this.pageTitle = 'Edit Domain'
      this.buttonText = 'Update'
    }
  }

  ngOnInit() {
    if(this.domain_id !=0){
      const reqData ={
        domain_id: this.domain_id
      }
      /// Get domain details by domain id
      this.userservice.getDomainDetailsById(reqData).subscribe(
        res => {
          // console.log('History Details', res);
          if(res['status'] == 1){
            // Set Value modal field
            this.addDomainSetup.setValue({
              domainName: res['domain'][0]['domain_name'],
              ipAddress: res['domain'][0]['domain_ip']
            })
            console.log(res);
          }
          else{
            this.common.openSnackBar('Domain details faled to lode', '');
          }
        },
        err => {
          console.log("ERROR");
          console.log(err);
          this.common.openSnackBar('please try later', '');
        }
      );
    }
  }

  saveDomain(){
    if(this.addDomainSetup.valid){
      this.ipError = false;
      if (/((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/
            .test(this.addDomainSetup.value.ipAddress) ||
            /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/.test(this.addDomainSetup.value.ipAddress) ||
            /^[a-z0-9]+(([-]*.)[a-z0-9]+)*\.[a-z]{2,}$/.test(this.addDomainSetup.value.ipAddress)) {
        const formData: any = {
          domain_name: this.addDomainSetup.value.domainName,
          ip: this.addDomainSetup.value.ipAddress
        }
        //Submit domain details through API for data save
        if(this.domain_id ==0){
          this.userservice.saveDomain(formData).subscribe(
            res => {
              // console.log('History Details', res);
              if(res['status'] == 1){
                this.closePopup();
              }
              else{
                this.common.openSnackBar('Domain is not save please try later', '');
              }
            },
            err => {
              console.log("ERROR");
              console.log(err);
              this.common.openSnackBar('please try later', '');
            }
          );
        }
        //Submit domain details through API for data edit
        if(this.domain_id !=0){
          formData['domain_id'] = this.domain_id;
          console.log('Set', formData);
          this.userservice.updateDomain(formData).subscribe(
            res => {
              if(res['status'] == 1){
                this.closePopup();
              }
              else{
                this.common.openSnackBar('Domain is not update please try later', '');
              }
            },
            err => {
              console.log("ERROR");
              console.log(err);
              this.common.openSnackBar('please try later', '');
            }
          );
        }
      }else{
        this.ipError = true;
      }
    }
  }

  closePopup() {
    this.dialogRef.close();
  }

}
